%box-search{
  box-sizing: border-box;
  position: relative;
  width: 100%;
  border: 1px solid transparent;
  .at-containershadow-secondary{
    display: none;
    padding: 12px $size_1x * 2;
    box-sizing: border-box;
    max-width: none;
    .at-font-h4{
      font-size: $font_2x;
      color: transparentize($neutral_2, .4);
      font-weight: normal;
      text-transform: uppercase;
      margin: 12px 0;
    }
    .at-link{
      display: block;
      font-size: $font_3x;
      padding: 6px 12px;
      color: $neutral_2;
    }
  }
}
@include for-mobile{
  %box-search{
    .at-input-label{
      .at-input-search{
        font-size: $font_3x;
        &::placeholder{
          font-size: $font_3x;
        }
      }
    }
  }
}

%search{
  width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  outline: none;
  box-sizing: border-box;
  margin: 0;
  float: right;
  padding: 18px 0;
  @include for-mobile{
    padding: 12px 0;
  }
  &:hover{
    .at-input-search{
      border: 0;
    }
  }
  .at-input-search{
    height: auto;
    outline: none;
    border-left: 0;
    font-size: $font_12x;
    overflow: hidden;
    padding: 0;
    float: right;
    display: inline-block;
    vertical-align: middle;
    min-width: 0;
    //border-left: 1px solid transparent;
    @include for-mobile{
      font-size: $font_3x;
    }
    ::placeholder {
      color: transparentize($neutral_1, .3);
    }
  }
  .at-input-search::placeholder{
    color: transparentize($neutral_1, .3);
  }
  .ic-buscar{
    background: none;
    border: 0;
    padding: 0 24px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    outline: none;
    &:before{
      color: $neutral_1;
      font-size: 36px;
      @include for-mobile{
        font-size: 24px;
      }
    }
  }
  .ic-cerrar{
    display: none;
    padding: 0 24px;
    cursor: pointer;
    @include for-mobile{
      padding: 0 12px 0 24px;
    }
    &:before{
      font-size: 36px;
      color: $neutral_1;
      @include for-mobile{
        font-size: 24px;
      }
    }
  }
}
