.ml-notification-attention{
  background: $utility_3;
  @extend %notification;
  .content-buttons{
    text-align: right;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include for-mobile{
      padding-right: 12px;
      margin: 12px 0;
    }
    button:nth-child( 2 ){
      margin-left: 12px;
    }
    .btn-principal{
      background: $neutral_1;
      color: $utility_3;
      border: 1px solid $utility_3;
    }
    .btn-secondary{
      background: transparent;
      color: $neutral_1;
      border: 1px solid $neutral_1;
    }
  }
}
