@keyframes giraSwitch{
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
}
.at-input-switch {
  @extend %switch;
  & input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .at-slider {
      background-color: transparentize( $neutral_2, .82 );
    }
    &:focus + .at-slider {
      box-shadow: 0 0 1px transparentize($neutral_2, .82);
    }
    &:checked + .at-slider:before {
      transform: translateX(16px);
      background-color: $secundary_1;
      border-radius: 50%;
    }
    &.at-loader-switch{
      left: 0;
      transition: left 1s;
    }
    &:checked + .at-loader-switch:before {
      background: url("#{$imagesPath}icons/loader.svg");
      background-size: 28px 28px;
      background-position: center center;
      background-color: $neutral_1;
      animation: giraSwitch 1s linear infinite;
      left: 16px;
    }
  }
  .at-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize($neutral_2, .82);
    transition: $transition_1;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0;
      bottom: -3px;
      background-color: $neutral_1;
      transition: $transition_1;
      box-shadow: $shadow_4;
      border-radius: 50%;
    }
    &.at-loader-switch:before {
      background-size: 28px 28px;
      background-position: center center;
    }
  }
}
