.ml-form-we-call {
  .at-input-label{
    width: 100%;
    input{
      width: 100%;
    }
  }
  button{
    margin: 12px 0;
  }
  .at-card-low-emphasis {
    @extend %content;
    .at-button-icon {
      max-width: 50%;
      margin: 0 auto;
    }
    .at-font-p{
      &:first-child {
        font-size: $font_18x;
        margin-bottom: .5rem;
      }
      &:last-of-type {
        margin-bottom: 2rem;
      }
    }
  }
}
