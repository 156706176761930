.ml-list-button{
  padding: 0;
  .header-list{
    padding-top: 36px;
    padding-bottom: 12px;
    border-bottom: 1px solid transparentize($neutral_2, .8);
    @include for-mobile{
      padding-top: 24px;
    }
    h4{
      padding: 0 15px;
      // @include for-mobile{
      //   padding: 0 24px;
      // }
    }
  }
  ul{
    padding: 0;
    list-style: none;
    margin: 0;
    @include for-mobile{
      //padding: 0 24px;
    }
    a{
      text-decoration: none;
      display: block;
      padding: 24px 15px;
      border-bottom: 1px solid transparentize($neutral_2, .8);
      @include for-mobile{
        padding: 16px;
      }
      &:last-child{
        border: none;
      }
      &:hover{
        background-color: #d8d8d8;
      }
      li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        i{
          &:before{
            font-size: 24px;
            color: $secundary_1;
          }
        }
        p{
          font-size: $font_18x;
          width: 90%;
          span{
            color: $primary_1;
          }
        }
      }
    }
  }
  .more{
    padding: 36px;
    text-align: right;
    @include for-mobile{
      padding: 24px;
    }
  }
}
