.ml-popup-list {
  @extend %fallball-popup;
  .popup-content {
    @extend %content-popup;
    text-align: left;
    .at-font-h4{
      font-weight: normal;
      font-size: $font_12x;
    }
    .at-button-secondary{
      margin: 0 $size_1x;
      &:last-child{
        margin-right: 0;
      }
    }
    .radio-list{
      margin: $size_1x 0;
      ul {
        padding: 0;
        margin: 0;
        li{
          list-style: none;
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 8px;
        }
      }
      label{
        font-family: $r-regular;
        font-size: $font_3x;
        color: $neutral_2;
        vertical-align: top;
      }
    }
    .at-button-primary{
      float: right;
    }
  }
  .ic-cerrar {
    @extend %ic-iconos-popup;
  }
  .content-popup-header{
    padding-top:20px;
  }
}
