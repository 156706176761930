.ml-list-group{
  padding: 36px;
  @include for-mobile{
    padding: 24px;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      display: flex;
      align-items: baseline;
      padding: 24px 0;
      border-bottom: 1px solid transparentize($neutral_2, .8);
      @include for-mobile{
        padding: 12px 0;
      }
      &:first-child{
        padding-top: 0;
      }
      &:last-child{
        padding-bottom: 0;
        border: none;
      }
      p{
        font-size: $font_18x;
        span{
          color: $primary_1;
        }
      }
    }
  }
}
