%navbar{
  box-sizing: border-box;
  height: 74px;
  padding: 12px 100px;
  background: $primary_1;
  width: 100%;
  display: flex;
  align-items: center;
}
%navbar-brand{
  display: inline-block;
  margin-right: $size_1x * 2;
  .dropdown-menu-button{
    display: none;
    width: $size_1x * 4;
  }
  .at-link{
    display: inline-block;
    color: $neutral_1;
    font-size: $font_2x;
  }
}
%navbar-navigation{
  li{
    display: inline-block;
    padding: 0 24px;
    .at-link{
      font-size: 1rem;
      color: $neutral_1;
      &:hover{
        background: $primary_1;
        color: $secundary_1;
      }
      &:focus,
      &:active {
        background: $primary_1;
        color: $secundary_1;
      }
    }
    i{
      display: none;
      &:before{
        font-size: $font_4x;
        color: $primary_1;
      }
      &.ic-expandir-mas{
        display: inline-block;
        float: none;
        margin-right: 0;
        margin-left: 0;
        vertical-align: middle;
        &:before{
          color: $secundary_1;
        }
      }
    }
  } 
  &.pqr{
    display: none;
  }
  &.is-active{
    .at-link{
      &:before{
        opacity: 1;
      }
    }
  }
}
%navbar-collapse{
  display: inline-block;
  .dropdown{
    float: left;
    position: relative;
    > .at-link{
      color: $neutral_1;
      font-size: $font_2x;
    }
    .at-containershadow-secondary {
      display: none;
      padding: $size_1x;
      position: absolute;
      right: -10px;
      top: calc(100% + 15px);
      width: 300px;
      z-index: 1;
      &:before{
        content: "";
        position: absolute;
        right: 15px;
        top: -8px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid $neutral_1;
      }
      .at-link{
        padding: $size_1x;
        font-size: $font_3x;
        color: $neutral_2;
        display: block;
      }
    }
    &.username{
      .my-line{
        display: none;
      }
    }
    &.is-active{
      .at-containershadow-secondary{
        display: block;
      }
    }
  }
  .ml-basic-search {
    .at-input-label{
      background: transparentize($primary_3, .8);
      .at-input-search{
        color: $neutral_1;
        &::placeholder{
          color: $neutral_1;
        }
      }
      button, i{
        &:before{
          color: $neutral_1;
        }
      }
    }
  }
}
%full-icon{
  position: absolute;
  left: 50%;
  background: $utility_2;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  text-align: center;
  padding: 1px;
  font-family: $r-regular;
  font-size: $font_16x;
}
%menu-fixed{
  position: fixed;
  top: 0;
  z-index: 9;
}
@include for-tablet{
  %navbar-brand{
    .dropdown-menu-button{
      display: block;
      float: left;
      margin-right: 12px;
      i{
        &:before{
          font-size: $font_17x;
          color: transparentize($neutral_1, .5);
        }
        &.ic-menu{
        display: block;
        margin-left: -4px;
        &:before{
          color: $neutral_1;
          float: left;
          font-size: $font_22x;
          }
        }
        &.ic-cerrar{
          display: none;
        }
      }
      &.is-active{
        .ic-menu{
          display: none;
        }
        .ic-cerrar{
          display: block;
        }
      }
    }
  }
}
@include for-mobile{
  %navbar-brand{
    margin: 0;
  }
  %navigation{
    .dropdown{
      &.pqr{
        display: block;
      }
    }
  }
  %navbar-collapse{
    .dropdown{
      &.pqr{
        display: none;
      }
      &.username{
        .my-line{
          display: block;
          background: transparentize($product_2, .9);
          &:before{
            float: right;
            color: $secundary_1;
            font-size: $font_11x;
            font-weight: bold;
            margin: 18px $size_1x;
          }
          .at-link{
            box-sizing: border-box;
            display: block;
            padding: $size_1x;
            width: 100%;
            .at-font-p{
              color: $primary_1;
              font-size: $font_19x;
              line-height: 1.4rem;
              &:nth-child( 3n ) {
                font-weight: bold;
              }
            }
            i{
              float: left;
              padding: 0;
              margin-right: $size_1x;
              &:before{
                color: $primary_1;
                font-size: $font_15x;
              }
            }
          }
          .my-contracts{
            display: none;
            background: transparentize($product_2, .95);
          }
        }
      }
    }
  }
}
@include for-mobile-s{
  %navbar-collapse{
    .dropdown{
      .at-containershadow-secondary{
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        &:before{
          right: 60px;
        }
        .at-element-list{
          li{
            width: 100%;
          }
        }
      }
    }  
  }
}
