//fuente iconos nueva
@font-face {
  font-family: 'icoTigoUne';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/TigoUne-icons/fonts/icoTigoUne.eot');
  src: url('https://atomic.tigocloud.net/source/assets/fonts/TigoUne-icons/fonts/icoTigoUne.eot') format('embedded-opentype'),
    url('https://atomic.tigocloud.net/source/assets/fonts/TigoUne-icons/fonts/icoTigoUne.ttf') format('truetype'),
    url('https://atomic.tigocloud.net/source/assets/fonts/TigoUne-icons/fonts/icoTigoUne.woff') format('woff'),
    url('https://atomic.tigocloud.net/source/assets/fonts/TigoUne-icons/fonts/icoTigoUne.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'robotothin';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-thin-webfont.woff2') format('woff2'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-thin-webfont.woff') format('woff'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-thin-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-light-webfont.woff') format('woff'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-regular-webfont.woff') format('woff'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-regular-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-medium-webfont.woff2') format('woff2'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-medium-webfont.woff') format('woff'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-medium-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-bold-webfont.woff') format('woff'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-bold-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'robotoblack';
  src: url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-black-webfont.woff2') format('woff2'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-black-webfont.woff') format('woff'),
       url('https://atomic.tigocloud.net/source/assets/fonts/roboto/roboto-black-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'tigo-icons-pack1';
  src:  url('https://atomic.tigocloud.net/source/assets/fonts/iconos-tigo-pack1/tigo-icons-pack1.eot');
  src:  url('https://atomic.tigocloud.net/source/assets/fonts/iconos-tigo-pack1/tigo-icons-pack1.eot') format('embedded-opentype'),
    url('https://atomic.tigocloud.net/source/assets/fonts/iconos-tigo-pack1/tigo-icons-pack1.ttf') format('truetype'),
    url('https://atomic.tigocloud.net/source/assets/fonts/iconos-tigo-pack1/tigo-icons-pack1.woff') format('woff'),
    url('https://atomic.tigocloud.net/source/assets/fonts/iconos-tigo-pack1/tigo-icons-pack1.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
