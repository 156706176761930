.ml-media-block {
  .content {
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    align-items: center;
    display: flex;
    .content-img {
      display: inline-block;
      text-align: center;
      padding: 0 32px 0 18px;
      @include for-mobile-s {
        padding: 0 12px;
      }
      img {
        max-width: 80px;
        max-height: 80px;
        width: 100%;
        height: 100%;
        @include for-mobile-s {
          max-width: 40px;
          max-height: 40px;
        }
      }
    }
    .content-card {
      display: inline-block;
    }
    &:hover {
      .contenido {
        .content-card {
          .content-info {
            .at-font-h3 {
              color: $primary_1;
            }
            .at-font-h4 {
              color: $primary_1;
            }
            .at-font-p {
              color: $neutral_2;
            }
          }
        }
      }
    }
  }
}