%input{
  appearance: none;
  -ms-appearance:none;
  background: transparent;
  border: $border_1 $neutral_2;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 $size_1x;
  color: $neutral_2;
  display: block;
  font-size: $font_18x;
  font-family: $r-regular, sans-serif;
  line-height: 1.8rem;
  outline: none;
  min-width: 200px; //Width for examples
  height: 45px;
  width: auto;
  margin: 0;
  &::placeholder{
    color:$neutral_2;
  }
  &:hover{
    border: $border_2 transparentize($primary_1, .5);
  }
  &:focus{
    //border: $border_1 $secundary_2;
    color: $neutral_2;
  }
}
.at-input-date-long-time{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  .at-selectlabel {
    display: block;
  }
  li{
    list-style: none;
    margin-right: 6px;
    width: 75px;
    input, select{
      width: 100%;
      min-width: inherit;
    }
    &:nth-child(2){
      width: 150px;
    }
  }
}
.readonly {
  input, select{
    border: none!important;
  }
}
%label-animate {
  font-family: $r-regular, sans-serif;
  color: $neutral_2;
  display: table;
  position: relative;
  margin: 20px 0;
  width: auto;
  .label {
    transform: $transform_2;
    font-size: $font_3x;
    position: absolute;
    top: -3px;
    left: 0;
    transition: .5s all;
    color: $neutral_2;
    //line-height: 1.5rem;
    &:focus{
      color: $secundary_2;
    }
  }
  small {
    font-size: $font_2x;
    padding: 0 12px;
    color: $neutral_2;
    &:focus{
      color: $secundary_2;
    }
  }
  em{
    position: absolute;
    right: $size_1x;
    top: 50%;
    margin-top: -20px;
    font-size: $font_2x;
    color: transparentize($primary_1, .6);
    span{
      position: initial!important;
      color: transparentize($primary_1, .6);
      font-size: $font_2x;
    }
  }
  &:hover {
    input, select{
      border: $border_2 transparentize($primary_1, .5);
    }
    em{
      span{
        color: transparentize($primary_1, .5);
      }
    }
  }
  &:focus{
    outline: none;
    span, small {
      color: $secundary_2;
    }
    input, select{
      border: $border_1 $secundary_2;
    }
    em{
      span{
        color: transparentize($primary_1, .5);
      }
    }
    i{
      &:before{
        color: transparentize($primary_1, .5);
      }
    }
  }
  &.active.focus{
    .label, small{
      color: $secundary_2;
    }
    .label{
      top:0;
    }
  }
  &.active.error.focus{
    .label-animate, small{
      color: $utility_2;
    }
  }
  &.active {
    .label {
      font-size: $font_2x;
      transform: $transform_3;
      background-color: $neutral_1;
      padding: 0 3px;
      color: transparentize($neutral_2, .2);
      line-height: 1.14rem;
      &:focus{
        color: $secundary_2;
      }
    }
    input, select {
      border: $border_1 $neutral_2;
      color: #00000A;
      &:focus{
        border: $border_1 $secundary_2;
        color: transparentize(#00000A, .2);
      }
    }
    small {
      color: transparentize($neutral_2, .2);
      &:focus{
        color: $secundary_2;
      }
    }
    em{
      span{
        color: $secundary_2;
        background-color: transparent;
      }
    }
    // &:hover {
    //   span {
    //     color: $secundary_2;
    //   }
    // }
    i{
      &:before{
        color: $primary_1;
      }
    }
    &:before{
      color: $secundary_1;
    }
  }
  &.disabled, &.disabled.active {
    .label {
      color: transparentize($neutral_2, .5);
    }
    input, select{
      border: $border_1 $neutral_5;
      color: transparentize($neutral_2, .5);
    }
    small{
      color: transparentize($neutral_2, .5);
    }
    &:before{
      color: transparentize($neutral_2, .5);
    }
    i{
      &:before{
        color: transparentize($neutral_2, .5);
      }
    }
  }
  &.error{
    .label {
      color: $utility_2;
    }
    input, select{
      border: $border_1 $utility_2;
      color: $neutral_2;
      &:focus{
        border: $border_1 $utility_2;
        color: $neutral_2;
      }
    }
    em{
      span{
        color: $utility_2;
        background-color: transparent;
      }
    }
    small {
      color: $utility_2;
    }
    &:hover{
      span{
        color: $utility_2;
      }
    }
    i{
      &:before{
        color: $utility_2;
      }
    }
    // &:before{
    //   color: $utility_2;
    // }
  }
  &.icon-left, &.icon-right{
    i{
      position: absolute;
      font-size: 25px;
      top: 12px;
      font-size: 20px;
    }
    .icon-left{
      position: absolute;
      left: 11px;
    }
  }
  &.icon-right{
    i {
      right: 11px;
    }
  }
  &.payment{
    .label{
      left:30px;
    }
    input{
      padding-left: 35px;
    }
  }
  &.payment.focus{
    .label{
      left:0;
    }
  }
  &:focus{
    small {
      color: $secundary_2;
    }
  }
}
%checkbox {
  position: relative;
  display: inline-block;
  label{
    width: 14px;
    height: 14px;
    border: 2px solid rgba(120,120,120,0.54);
    border-radius: 2px;
    background: $neutral_1;
    display: inline-block;
    position: relative;
    box-sizing: content-box;
  }
  input{
    position: absolute;
    z-index: 2;
    opacity: 0;
    height: 100%;
    margin: 0;
    appearance: radio;
  }
  input:checked + label{
    background: $secundary_1;
    border: 2px solid $secundary_1;
  }
}
%radio_button {
  position: relative;
  display: inline-block;
  label{
    width: 16px;
    height: 16px;
    background: #fff;
    border: $border_2 transparentize($neutral_2, 0.46);
    border-radius: 50%;
    display: inline-block;
    pointer-events: none;
    position: relative;
    box-sizing: content-box;
  }
  input{
    opacity: 0;
    position: absolute;
  }
  input + label{
    padding-left: 0;
    line-height: 0;
    display: inline-block;
    font-size: initial;
    bottom: 0;
    margin: 0;
    &:after{
      display: none;
    }
    &:before{
      display: none;
    }
  }
  input:checked + label{
    border:2px solid $secundary_1;
  }
  input:checked + label:after{
    content: " ";
    width: 100%;
    height: 100%;
    background: $secundary_1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    display: inline-block;
    transform: scale(.7);
    opacity: 1;
  }
}
%switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 14px;
}
// @include for-tablet{
//   %label-animate{
//     &.active{
//       &:hover{
//         span{
//           color: transparentize($primary_1, .1);
//         }
//       }
//     }
//   }
// }
.at-input-label input {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  background: none;
  z-index: 1;
  font-size: 1rem;
  height: 45px;
  border: 1px solid $neutral_2;
  border-radius: 4px;
  padding: 0 12px;
  width: 100%;
}

.at-input-label{
  .labelOut{
    display: block;
    position: absolute;
    padding: 1px 2px;
    color: $neutral_2;
    background: #fff;
    font-size: .875rem;
    z-index: 2;
    -webkit-animation-name: labelIn;
    animation-name: labelIn;
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}
.at-input-label input:focus{
  border: 1px solid $secundary_2;
  outline: none;
}
.at-input-label input:focus + .labelOut{
  color: $secundary_2;
}
.at-input-label input:focus + .labelOut, .at-input-label input:valid + .labelOut {
  -webkit-animation-name: labelOut;
  animation-name: labelOut;
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}


@-webkit-keyframes
labelIn {  0% {
 left: 20px;
 top: 21px;
 font-size: .875rem;
}
 100% {
 font-size: 0.65em;
 left: 20px;
 top: -1px;
}
}
@keyframes
labelIn {  0% {
 left: 20px;
 top: 21px;
 font-size: .875rem;
}
 100% {
 font-size: .75rem;
 left: 20px;
 top: -1px;
}
}
@-webkit-keyframes
labelOut {  0% {
 left: 20px;
 top: 21px;
 font-size: .875rem;
}
 100% {
 font-size: .75rem;
 left: 20px;
 top: -1px;
}
}
@keyframes
labelOut {  0% {
 left: 20px;
 top: 21px;
 font-size: .875rem;
}
 100% {
 font-size: .75rem;
 left: 20px;
 top: -1px;
}
}
