.ml-card-informative-block{
  .at-containershadow-primary{
    max-width: 770px;
    width: auto;
    box-shadow: none;
    background-color: #1b8ae2;
    padding: 25px 10px;
    border-radius: 10px;
    @include for-mobile{
      width: 100%;
    }
    .at-font-h5{
      color: $neutral_1;
      margin-bottom: 30px;
      font-size: 1rem;
    }
    .col-12{
      display: flex;
      align-items: center;
      @include for-mobile{
        margin-bottom: 25px;
      }
      .content-img{
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
        box-sizing: content-box;
        @include for-mobile{
          padding: 0;
          padding-right: 12px;
        }
        i{
          background: $neutral_1;
          border-radius: 50%;
          padding: 5px;
          font-size: 25px;
          &:before{
            color: $primary_1;
          }
        }
      }
      .content-card {
        display: inline-block;
        vertical-align: middle;
        padding-right: 18px;
        @include for-mobile{
          padding-right: 0;
        }
        .content-info{
          display: inline-block;
          vertical-align: middle;
          @include for-mobile{
            float: none;
            margin: 0;
          }
          p{
            color: $neutral_1;
            font-size: .9rem;
          }
        }
      }
    }
  }
}
