.or-navbar-discovery{
  @extend %navbar;
  height: 74px;
  &.menu-fixed{
    @extend %menu-fixed;
  }
  .background-navbar{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    left: 0;
    top: 0;
    z-index: 99;
    display: none;
  }
  .navbar-brand{
    @extend %navbar-brand;
    margin: 0 24px 0 10px;
    display: flex;
    align-items: center;
    img {
      width: 51px;
    }
    .dropdown-menu-button{
      .ic-burguer{
        margin-top: -3px;
        &:before{
          padding: 0;
        }
      }
      .ic-cerrar{
        padding-top: 5px;
      }
    }
  }
  .ml-primary-navigation{
    @extend %navbar-navigation;
    width: auto;
    .header-navigation-mobile{
      display: none;
      background: $primary_1;
      box-sizing: border-box;
      //height: 60px;
      color: $neutral_1;
      padding: 12px 24px;
      .at-font-h4{
        color: $neutral_1;
      }
      .search-mobile{
        width: 100%;
        position: relative;
        input{
          color: transparentize($neutral_1, .3);
          width: 100%;
          border: none;
          padding: 10px 20px;
          background-color: #022552;
          border-radius: 20px;
          box-sizing: border-box;
          ::placeholder {
            color: transparentize($neutral_1, .3);
          }
        }
        input::placeholder {
          color: transparentize($neutral_1, .3);
        }
        svg{
          position: absolute;
          right: 15px;
          top: 5px;
        }
        i{
          position: absolute;
          right: 15px;
          top: 2px;
          display: none;
        }
        .sugerencias{
          padding: 12px 24px;
          box-sizing: border-box;
          max-width: none;
          position: absolute;
          text-align: left;
          background-color: $primary_1;
          z-index: 9;
          height: 100vh;
          width: 100%;
          display: none;
          .at-font-h5{
            color: $secundary_1;
            font-size: $font_3x;
            margin-bottom: 10px;
          }
          .at-link{
            display: block;
            font-size: .875rem;
            padding: 12px 0;
            color: $neutral_1;
            border-bottom: 1px solid $neutral_1;
            margin-top: 0;
          }
        }
      }
      .at-link{
        color: transparentize($neutral_1, .3);
        font-size: $font_2x;
        margin-top: 30px;
        display: inline-block;
        i{
          margin-right: 12px;
          &:before{
            color: transparentize($neutral_1, .3);
            font-size: $font_4x;
          }
        }
      }
    }
    .at-element-list{
      li{
        position: relative;
        .at-containershadow-secondary{
          position: fixed;
          width: 100%;
          left: 0;
          //top: 132px;
          display: flex;
          //justify-content: space-around;
          max-width: 100%;
          padding: 16px 100px;
          display: none;
          z-index: 1;
          top: 146px;
          align-items: center;
          justify-content: space-between;
          z-index: 9999;
          .at-element-list{
            margin: 0;
            h5{
              margin-bottom: 12px;
            }
            svg{
              margin-right: 32px;
              vertical-align: middle;
              display: none;
            }
            li{
              display: inline-block;
              padding-left: 0;
              padding-right: 32px;
              .at-link{
                color: $neutral_2;
                &:hover{
                  color: $secundary_1;
                  background: none;
                }
              }
            }
            &.clearfix{
              margin: 0;
            }
          }
          &.menu-fixed{
            top: 40px;
            z-index: 9;
          }
        }
        &.dropdown{
          float: none;
          &.is-active{
            .at-containershadow-secondary{
              display: flex;
              .at-link{
                padding: 0;
                line-height: inherit;
                font-size: $font_3x;
              }
            }
            .at-link{
              i{
                transform: rotate(180deg);
              }
            }
          }
          .at-link{
            font-weight: initial;
            padding: 0;
            display: flex;
            align-items: center;
          }
        }
        &.is-active{
          &:after{
            float: none;
            content: '';
            background: $secundary_1;
            height: 4px;
            width: 100%;
            position: absolute;
            bottom: -14px;
            left: 0;
            border-radius: 5px 5px 0 0;
          }
          .at-link{
            color: $secundary_1;
          }
        }
      }
      &.footer-navigation-mobile{
        display: none;
        //position: absolute;
        bottom: 0;
        width: 100%;
        padding: 10px 0;
        li{
          border: none;
          padding: 12px 24px;
          .at-link{
            //display: flex;
            font-size: $font_2x;
            svg{
              margin-right: 12px;
              vertical-align: middle;
            }
            i{
              font-size: $font_4x;
              margin-right: 12px;
              color: $neutral_1;
            }
          }
          &.centro-ayuda{
            border-top: 1px solid transparentize($neutral_1, .5);
          }
          &.mi-tigo{
            border-bottom: 1px solid transparentize($neutral_1, .5);
          }
          &.empresas{
            padding: 24px;
            .at-link{
              i{
                &:before{
                  color: $neutral_1;
                }
              }
            }
          }
        }
        &.not-border{
          li{
            &.centro-ayuda, &.mi-tigo{
              border: none;
            }
          }
        }
      }
    }
  }
  .navbar-collapse{
    @extend %navbar-collapse;
    float: right;
    margin: 6px 0;
    flex-grow: 1;
    .at-element-list{
      float: right;
      display: flex;
      align-items: center;
      .dropdown{
        margin: 0 48px;
      }
      .ml-search-basic{
        position: absolute;
        max-width: 400px;
        right: 100px;
        .at-input-label{
          padding: 5px 0;
          &:after{
            display: none;
          }
          button{
            padding: 0;
            background: none;
            border: none;
            cursor: pointer;
            &:before{
              font-size: $font_5x;
            }
            &:hover{
              &:before{
                color: $secundary_1;
              }
              svg{
                #Group-2{
                  stroke: $secundary_1;
                }
              }
            }
            &:focus{
              outline: none;
            }
          }
        }
      }
    }
    .dropdown{
      .at-link{
        font-size: $font_3x;
        i{
          float: right;
          margin: 0;
          border-radius: 100%;
          //padding: 9.5px;
          &:before{
            color: $neutral_1;
            font-size: $font_5x;
            margin: 0 auto;
          }
          &:hover{
            //background: transparentize($primary_3, .7);
            &:before{
              color: $secundary_1;
            }
          }
          &.ic-expandir-mas{
            &:before{
              margin-left: $size_1x;
              font-size: $font_14x;
            }
          }
          .full{
            @extend %full-icon;
          }
        }
        svg{
          &:hover{
            #Group-3{
              stroke: $secundary_1;
            }
          }
        }
      }
      .at-card-medium-emphasis{
        margin-top: 6px;
      }
      &.mi-tigo{
        margin: 0 24px;
        .at-link{
          &:nth-child( 1 ){
            display: none;
          }
          &:nth-child( 2 ){
            font-size: $font_18x;
            border: 1px solid $neutral_1;
            border-radius: 20px;
            padding: 5px 30px;
          }
        }
      }
      &.user, &.random{
        display: none;
      }
    }
    .ml-basic-search{
      position: absolute;
      right: $size_1x * 10.5;
      left: auto;
      width: 60%;
      top: 50%;
      margin-top: -18px;
      display: none;
      transition: $transition_1;
      i{
        &:before{
          color: transparentize($neutral_1, .5);
          font-size: $font_15x;
        }
      }
    }
  }
  &.or-navbar-discovery-drupal{
    .ml-primary-navigation{
      .at-element-list{
        li{
          .at-containershadow-secondary{
            top: 106px;
          }
        }
      }
    }
  }
  @include for-tablet{
    padding: 0 $size_1x;
    .navbar-brand{
      .at-logo-image{
        margin: $size_1x;
      }
      svg{
        width: 55px;
      }
    }
    .ml-primary-navigation{
      //display: none;
      background: $primary_1;
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      box-shadow: $shadow_1;
      z-index: 999;
      transition: .5s all ease;
      white-space: nowrap;
      overflow: auto;
      .header-navigation-mobile{
        display: block;
        //align-items: center;
        i{
          &:before{
            color: $neutral_1;
            font-size: 2rem;
          }
        }
      }
      .at-element-list{
        white-space: nowrap;
        li{
          display: block;
          padding: 20px 24px;
          .at-link{
            color: $neutral_1;
            display: block;
            &:hover{
              background: transparent;
            }
            &:focus{
              background: transparent;
            }
          }
          i{
            display: inline-block;
          }
          &.dropdown{
            width: auto;
            .at-link{
              line-height: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              // &:hover, &:active, &:focus{
              //   background: transparentize($primary_1, .9);
              //   font-weight: bold;
              //   font-size: $font_19x;
              //   color: $primary_1;
              // }
            }
            .ic-expandir-mas{
              float: right;
              &:before{
                color: $secundary_1;
                font-size: $font_11x;
              }
            }
            &.is-active{
              .at-link{
                i{
                  transform: rotate(180deg);
                }
              }
              .at-containershadow-secondary{
                position: relative;
                padding: 0;
                box-shadow: none;
                display: block;
                background-color: transparent;
                margin-top: 10px;
                top: 0;
                .at-element-list{
                  margin: 0;
                  padding: 0;
                  padding-left: 12px;
                  svg{
                    display: none;
                  }
                  li{
                    border: none;
                    padding: 12px 0;
                    .at-link{
                      color: $neutral_1;
                    }
                    &.activated{
                      .at-link{
                        color: $secundary_1;
                      }
                    }
                  }
                }
                .at-button-secondary{
                  display: none;
                }
              }
            }
          }
          &.is-active{
            &:after{
              display: none;
            }
            .at-link{
              color: $neutral_1;
            }
          }
        }
        &.footer-navigation-mobile{
          display: block;
          padding-left: 0;
          li{
            border: none;
          }
        }
      }
      // .at-element-list{
      //   padding-top: 16px;
      //   white-space: nowrap;
      //   li{
      //     display: block;
      //     padding: 20px 24px;
      //     border-bottom: 1px solid transparentize($neutral_2, .8);
      //     .at-link{
      //       color: $neutral_2;
      //       display: block;
      //       &:hover{
      //         background: transparent;
      //       }
      //       &:focus{
      //         background: transparent;
      //       }
      //     }
      //     i{
      //       display: inline-block;
      //     }
      //   }
      // }
    }
    .navbar-collapse{
      .at-element-list{
        .dropdown{
          margin: 0 12px;
          &.mi-tigo{
            margin-right: 50px;
            .at-link{
              &:nth-child( 1 ){
                display: block;
              }
              &:nth-child( 2 ){
                display: none;
              }
            }
          }
          &.user, &.random{
            display: block;
          }
        }
        .ml-search-basic{
          display: none;
          right: 0;
          .at-input-label{
            padding: 15px 20px;
          }
        }
      }
    }
  }
  @include for-mobile{
    .ml-primary-navigation{
      .at-element-list{
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        flex-direction: column;
        .dropdown{
          &:nth-child( 3n ){
            border-right: none;
          }
        }
      }
    }
    .navbar-collapse{
      .ml-basic-search{
        button{
          padding: 0 $size_1x;
        }
      }
    }
  }
  @include for-mobile-s{
    .ml-basic-search{
      width: 56%;
    }
  }
  @include for-mobile-landscape{
    .ml-primary-navigation{
      .at-element-list{
        white-space: nowrap;
        overflow-x: scroll;
        display: block;
        .dropdown{
          display: inline-block;
          overflow: hidden;
          width: 28%;
          float: none;
          vertical-align: top;
          &:nth-child( 3n ){
            border-right: .5px solid transparentize($neutral_2, .7);
          }
        }
      }
    }
  }
}
