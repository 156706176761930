.ml-dropdown-up{
  position: relative;
  .action-dropdown{
    @extend %action-dropdown;
    @extend %flag-example;
  }
  .popup-select{
    @extend %flag-example;
    @extend %countries;
    &.open{
      display: block;
    }
  }
}
