.tm-map{
  @extend %map;
  section{
    &.header-tabs{
      padding: 60px 60px 80px;
      text-align: center;
      background-color: rgba(25,138,253,.2);
    }
    &.tabs{
      position: relative;
      top: -43px;
      .ml-general-tabs{
        .list-tabs{
          justify-content: center;
          .tab{
            background-color: $neutral_1;
          }
        }
        .contents-tabs{
          .body-tab{
            padding: 3rem 2rem;
            .at-font-h3{
              margin: 20px 0;
            }
            .ml-card-informative{
              margin: 50px 0;
            }
          }
        }
      }
    }
  }
}
