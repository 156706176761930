.ml-footer-navigation{
  @extend %block-navigation;
  background: #fff;
  box-shadow: 0 0 1px 1px #80808029;
  .ic-phone-footer{
    &:before{
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      color: #7f9bbd;
      content: url(#{$imagesPath}icons/ic-phone.svg);
    }
  }
  .container-navigation{
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
  }
  .block-navigation{
    padding: 0 24px;
    .title-navigation{
      @include for-mobile-s{
        &:after{
          font-family: "icoTigoUne";
          content: "\e93e";
          color: $secundary_1;
          font-size: 24px;
          position: absolute;
          right: 12px;
          //top: 12px;
          transition: .5s all ease;
        }
      }
      &.rotate-arrow{
        &:after{
          transform: rotate(180deg);
        }
      }
    }
    .elements-navigation{
      @include for-mobile-s{
        display: none;
      }
      &.navigation-line-atention{
        .at-element-list{
          li{
            display: flex;
            align-items: center;
            color: $neutral_2;
            i{
              margin-right: .5rem;
            }
            strong{
              color: #000;
            }
          }
        }
      }
    }
  }
  .at-divider{
    display: none;
    @include for-mobile-s{
      display: block;
    }
  }
}
