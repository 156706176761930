.ml-signin {
  .content {
    @extend %content;
    .at-button-icon {
      max-width: 50%;
      margin: 0 auto;
    }
    .at-font-p {
      text-align: center;
      margin: 30px 0;
      color: $neutral_2;
    }
  }
}
