%block-navigation{
  background: #eeeeee;
  padding: 24px;
  @include for-mobile-s{
    padding: 8px 0;
  }
  .block-navigation{
    padding: 0 1rem;
    .title-navigation{
      margin: 20px 0;
      position: relative;
      display: flex;
      align-items: center;
      h4{
        font-size: $font_18x;
      }
      .at-font-em{
        color: $primary_1;
      }
    }
    .elements-navigation{
      .at-element-list{
        li{
          margin: .3rem 0;
          color: $primary_1;
          line-height: 32px;
          .at-link{
            color: $neutral_2;
            font-size: $font_3x;
            line-height: 32px;
          }
          i{
            float: initial;
            margin-right: 12px;
            font-size: 1rem;
            &:before{
              display: inline-block;
              vertical-align: middle;
              font-size: 12px;
            }
          }
          a{
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
%navigation-footer{
  display: block;
  .container-navigation-footer{
    max-width: $grid;
    width: 100%;
    margin: 0 auto;
    .ml-accordion{
      .at-containershadow-primary{
        .accordion-item{
          .item-body{
            .elements-navigation{
              .at-element-list{
                li{
                  margin: 24px 0 0 0;
                  display: inline-block;
                  width: 24%;
                  vertical-align: top;
                  @include for-mobile{
                    display: block;
                    width: 100%;
                  }
                  a{
                    margin: 0;
                    display: flex;
                    vertical-align: middle;
                    color: $neutral_2;
                    font-size: $font_3x;
                    line-height: 32px;
                    align-items: center;
                    @include for-mobile{
                      display: block;
                      width: 100%;
                    }
                    i{
                      margin: -3px 12px 0 0;
                      display: inline-block;
                      vertical-align: middle;
                    }
                  }
                }
              }
              &.navigation-line-atention{
                .at-element-list{
                  li{
                    i{
                      margin: -3px 12px 0 0;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(5){
            .item-body{
              display: none;
            }
          }
        }
      }
    }
  }
}
%footer-firm{
  background: $primary_1;
  padding: 24px 0;
  @include for-mobile{
    padding: 24px 0 18px 0;
  }
  .container-columns-firm, .region-footer-firm{
    max-width: $grid;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @include for-mobile{
      display: block;
    }
    .column{
      &:nth-child(1){
        width: 50%;
        vertical-align: top;
        display: inline-block;
        text-align: left;
        @include for-mobile{
          display: block;
          width: 100%;
          text-align: center;
          margin-bottom: 24px;
        }
      }
      &:nth-child(2){
        width: 20%;
        vertical-align: top;
        display: inline-block;
        text-align: center;
        @include for-mobile{
          width: 49%;
          vertical-align: middle;
        }
        a{
          margin-right: 12px;
        }
      }
      &:nth-child(3){
        width: 29%;
        vertical-align: top;
        display: inline-block;
        text-align: right;
        @include for-mobile{
          width: 49%;
          vertical-align: middle;
        }
      }
      .ic-facebook-footer{
        &:before{
          display: inline-block;
          vertical-align: middle;
          font-size: 1rem;
          color: #7f9bbd;
          content: url(#{$imagesPath}icons/ic-facebook-footer.svg);
          width: 24px;
          height: 24px;
        }
      }
      .ic-twitter-footer{
        &:before{
          display: inline-block;
          vertical-align: middle;
          font-size: 1rem;
          color: #7f9bbd;
          content: url(#{$imagesPath}icons/ic-twitter-footer.svg);
          width: 24px;
          height: 24px;
        }
      }
      .at-font-small-legal{
        color: $neutral_1;
        display: block;
        padding-left: 12px;
        @include for-mobile{
          padding: 0 1rem;
        }
      }
      a{
        display: inline-block;
      }
      .ml-dropdown-up{
        .at-font-p{
          margin: 0;
          font-size: $font_3x;
        }
      }
      small{
        font-size: 11px;
      }
      &.social-networks{
        width: 50%;
        text-align: right;
        @include for-mobile{
          width: 100%;
          text-align: center;
        }
      }
    }
    #block-footerfirmcountriesblock{
      width: 29%;
      //text-align: center;
      @include for-mobile{
        width: 49%;
        vertical-align: middle;
        display: inline-block;
        float: none;
      }
      .clearfix{
        padding: 0;
        p{
          font-size: 1rem;
        }
      }
      .column{
        width: 100%;
        @include for-mobile{
          margin: 0;
        }
      }
    }
  }
}
%footer-services{
  //background: #eeeeee;
  padding: 2rem 0;
  .services-block{
    .content-icon{
      margin-bottom: 2rem;
      margin-top: 1rem;
      text-align: center;
      i{
        font-size: 3rem;
        background: $neutral_1;
        border-radius: 50%;
        padding: 1rem;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        display: inline-block;
        box-shadow: $shadow_2;
      }
    }
    .content-info{
      text-align: center;
    }
  }
}
