.or-hight-slider{
  @extend %banner;
  .controls{
    @extend %controls;
    span{
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      display: none;
    }
    .play{
      background-image: url("#{$imagesPath}icons/ic-play.svg");
      background-color: transparentize($neutral_2, .77);
      &.active{
        display: block;
      }
    }
    .stop{
      background-image: url("#{$imagesPath}icons/ic-pause.svg");
      background-color: transparentize($neutral_2, .36);
      &.active{
        display: block;
      }
    }
  }
  .owl-carousel{
    .owl-nav {
      width: 100%;
      position: absolute;
      top: 48%;
      left: 0;
      opacity: 0;
      button{
        background: transparentize($primary_1, .6);
        border-radius: 100%;
        height: 36px;
        width: 36px;
        margin: 0 24px;
        outline: none;
        &:before{
          font-family: $icons-tigoune;
          font-size: 36px;
          font-weight: bold;
          color: $neutral_1;
        }
        span{
          display: none;
        }
        &.owl-prev{
          float: left;
          background: transparentize($primary_1, .6);
          &:before{
            content: "\e95e";
          }
          &:hover{
            background: transparentize($neutral_2, .5);
          }
        }
        &.owl-next{
          float: right;
          background: transparentize($primary_1, .6);
          &:before{
            content: "\e92d";
          }
          &:hover{
            background: transparentize($neutral_2, .5);
          }
        }
      }
      @include for-mobile {
        display: none;
      }
    }
    .owl-dots{
      position: absolute;
      bottom: 1%;
      left: 0;
      width: 100%;
      text-align: center;
      button{
        &.owl-dot{
          padding: 8px;
          margin: 0 2px;
          outline: none;
          span{
            background: transparentize($primary_1, .6);
            width: 6px;
            height: 6px;
            float: left;
            border-radius: 100%;
          }
          &.active{
            span{
              background: $neutral_4;
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
    &:hover{
      .owl-nav {
        opacity: 1;
      }
    }
  }
}
@include for-tablet{
  .or-hight-slider{
    .controls{
      right: 12px;
      bottom: 12px;
      span{
        width: 18px;
        height: 18px;
      }
      .play, .stop{
        background-size: 7px 7px;
      }
    }
  }
}
