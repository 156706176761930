//variables declaradas para accesibilidad
$neutral_1: #ffffff; //Blanco
$neutral_2: #787878; // Text
$neutral_4: #fafafa; //Main BG
$primary_1: #00377D; //Azul TIGO
$primary_3: #001950; //NEW Top header or Menú selector
$secundary_1: #00C8FF; //Cyan
$secundary_2: #3264DC; //Press or Highlight
$secundary_3: #198AFD; //Hover
$secundary_5: #FFBE00;//New Yellow
$secundary_6: #00000A;//New black or blue dark
$utility_1: #37C846;//Product Families television
$utility_2: #F01E46;//Error
$utility_3: #FFAA32; //warning
$product_1: #F0781E;//Product Families Internet
$product_2: #2896FF;//Products mobile

body{
  &.accessible-contrast{
    //fondo
    background: #000;
    .container{
      background: #000;
    }
    //example code
    .example-code{
      background: #000;
      border-top: 1px solid $neutral_1;
      border-bottom: 1px solid $neutral_1;
      .description{
        h3{
          color: $neutral_1;
        }
        .at-font-p{
          color: $neutral_1;
        }
      }
      .snippet{
        h4{
          color: $neutral_1;
        }
        pre{
          border: 1px solid $neutral_1;
          background: #000;
          .code{
            color: $neutral_1;
          }
        }
      }
    }
    //imagenes
    img{
      filter: grayscale(100%);
    }
    // atomo colors
    #colors{
      .color{
        .color-description{
          h3{
            color: $neutral_1;
          }
        }
      }
    }
    // atomo titles
    .at-font-h1{
      color: $neutral_1;
    }
    .at-font-h2{
      color: $neutral_1;
    }
    .at-font-h3{
      color: $neutral_1;
    }
    .at-font-h4{
      color: $neutral_1;
    }
    .at-font-h5{
      color: $neutral_1;
    }
    // atomo paragraphs
    .at-font-em{
      color: $neutral_1;
    }
    .at-font-p{
      color: $neutral_1;
    }
    .at-font-small{
      color: $neutral_1;
    }
    // atomo links
    .at-link{
      color: $neutral_2;
      &:hover{
        color: $neutral_1;
      }
    }
    // atomo elements
    .at-element-span{
      color: $neutral_1;
    }
    .at-element-list{
      li{
        color: $neutral_1;
      }
    }
    // atomo progress indicator
    .at-linear-progress-indicator{
      background: $neutral_1;
      &:before{
        background: $neutral_2;
      }
    }
    .at-large-circular-progress-indicator, .at-medium-circular-progress-indicator, .at-small-circular-progress-indicator{
      background: none;
      border: 3px solid $neutral_1;
      border-top: 3px solid $neutral_2;
    }
    // molecula card
    .at-card-hight-emphasis, .at-card-medium-emphasis, .at-card-low-emphasis{
      background-color: transparent;
    }
    // molecula backdrop
    .ml-backdrop{
      .modal-content{
        .modal-header{
          background-color: #000;
          .ic-cerrar{
            &:before{
              color: #fff;
            }
          }
        }
        .modal-body{
          background: #000;
        }
      }
    }
    // atomo botones
    .at-button-hight-emphasis{
      color: #000;
      background: $neutral_1;
      &:hover{
        color: #000;
        border: 1px solid $neutral_1;
        background: transparentize($neutral_1, .4);
      }
      &:disabled{
        background: transparentize($neutral_1, .8);
        color: #000;
        border: 1px solid transparent;
      }
    }
    .at-button-medium-emphasis{
      color: $neutral_1;
      border: 1px solid $neutral_1;
      &:hover{
        background: transparentize($neutral_2, .6);
      }
      &:disabled{
        border: 1px solid transparentize($neutral_1, .7);
        color: transparentize($neutral_1, .7);
        background: transparent;
      }
    }
    .at-button{
      color: $neutral_1;
      &:hover{
        color: $neutral_2;
      }
      &:disabled{
        color: transparentize($neutral_1, .6);
      }
    }
    .at-button-shadow-emphasis{
      color: #000;
      background: $neutral_1;
      box-shadow: 0 12px 8px 0 rgba(255,255,255,.12);
      &:hover{
        color: #000;
        border: 1px solid $neutral_1;
        background: transparentize($neutral_1, .4);
      }
      &:disabled{
        background: transparentize($neutral_1, .8);
        color: #000;
        border: 1px solid transparent;
      }
    }
    .at-button-content{
      color: #000;
      background: $neutral_1;
      &:before{
        color: #000;
      }
      &:hover{
        color: #000;
        border: 1px solid $neutral_1;
        background: transparentize($neutral_1, .4);
      }
      &:disabled{
        background: transparentize($neutral_1, .8);
        color: #000;
        border: 1px solid transparent;
      }
    }
    .at-button-iconcolor{
      color: #000;
      background: $neutral_1;
      &:before{
        color: #000;
      }
      &:hover{
        color: #000;
        border: 1px solid $neutral_1;
        background: transparentize($neutral_1, .4);
      }
      &:disabled{
        background: transparentize($neutral_1, .8);
        color: #000;
        border: 1px solid transparent;
      }
    }
    .at-button-switch-to-slider{
      .status{
        background: $neutral_1;
        .progress-status{
          background: transparentize($neutral_2, .5);
        }
        .text-status{
          p{
            color: $neutral_2;
          }
        }
        .button-switch{
          border: 1px solid $neutral_2;
          span{
            &:before{
              color: $neutral_2;
            }
          }
        }
      }
    }
    //atomo dividers
    .at-divider{
      background: $neutral_1;
      border: 1px solid $neutral_1;
    }
    //molecula accordion
    .ml-accordion{
      .item-title{
        &:after{
          color: $neutral_1;
        }
        .at-font-h4{
          i{
            &:before{
              color: $neutral_1;
            }
          }
        }
      }
    }
    //atomo dividers width background SOLO TESTING
    #dividers-with-background{
      background: none;
    }
    //atomo blockquote
    .at-blockquote{
      border: 1px solid $neutral_4;
      color: $neutral_1;
    }
    //atomos backgrounds cards
    .at-card-hight-emphasis{
      border: 1px solid $neutral_4;
      box-shadow: 0 12px 8px 0 rgba(255,255,255,.12);
    }
    .at-card-medium-emphasis{
      box-shadow: 0 6px 8px 0 rgba(255,255,255,.06);
    }
    .at-card-low-emphasis{
      box-shadow: 0 2px 2px 0 rgba(255,255,255,.12);
    }
    //atomos inputs
    .at-input-label{
      &:hover{
        span{
          color: $neutral_1;
        }
      }
      span{
        background-color: #000;
        color: $neutral_1;
      }
      input{
        border: 1px solid $neutral_1;
        color: $neutral_1;
        &:focus{
          border: 1px solid $neutral_1;
        }
      }
      &.error{
        span{
          color: $utility_2;
        }
        input{
          border: 1px solid $utility_2;
        }
      }
      &.active{
        input{
          color: #fff;
        }
      }
    }
    .at-input-label.counter{
      em{
        background: none;
      }
    }
    .at-selectlabel{
      span{
        background: #000;
        color: $neutral_1;
      }
      .at-input-select{
        border: 1px solid $neutral_1;
        color: $neutral_1;
        &:focus{
          border: 1px solid $neutral_1;
          color: $neutral_1;
        }
      }
      &:hover{
        span{
          color: $neutral_1;
        }
      }
      &.error{
        &:hover{
          span{
            color: $utility_2;
          }
        }
      }
    }
    .at-input-label-date{
      span{
        background: #000;
        color: $secundary_1;
      }
      input{
        color: $neutral_1;
        &:placeholder{
          color: $neutral_1;
        }
      }
      .ic-calendario{
        &:before{
          color: $neutral_1;
        }
      }
      &.error{
        span{
          color: $utility_2;
        }
      }
    }
    .at-checkbox, .at-checkbox-minus{
      &:disabled{
        background: #000;
        border: 2px solid $neutral_1;
        &:after{
          display: none;
        }
      }
      label{
        background: none;
        &:after{
          display: none;
        }
      }
      input:checked + label{
        background: $secundary_1;
      }
      input:checked + label:after{
        display: block;
      }
    }
    .at-radio-button{
      background: transparent;
      &:after{
        background: #000;
      }
      &:checked{
        border: 2px solid $secundary_1;
        &:after{
          background: $secundary_1;
        }
      }
      label{
        background: none;
      }
    }
    .at-switch{
      .at-slider{
        border: 1px solid $neutral_1;
      }
    }
    //molecula popup
    .ml-popup, .ml-popup-form{
      .popup-content{
        background: $secundary_6;
        border: 1px solid $neutral_1;
        box-shadow: 0 0 24px 0 rgba(255,255,255,.22), 0 24px 24px 0 rgba(255,255,255,.30);
        .at-font-p{
          color: $neutral_1;
        }
        .at-font-h1, .at-font-h2, .at-font-h3, .at-font-h4, .at-font-h5{
          color: $neutral_1;
        }
        .ic-cerrar{
          &:before{
            color: $neutral_1;
          }
        }
      }
    }
    .ml-popup-advertence{
      .popup-content{
        background: $secundary_6;
        border: 1px solid $neutral_1;
        box-shadow: 0 0 24px 0 rgba(255,255,255,.22), 0 24px 24px 0 rgba(255,255,255,.30);
        &:before{
          color: $neutral_1;
        }
        .at-font-p{
          color: $neutral_1;
        }
        .at-font-h1, .at-font-h2, .at-font-h3, .at-font-h4, .at-font-h5{
          color: $neutral_1;
        }
        .ic-cerrar{
          &:before{
            color: $neutral_1;
          }
        }
      }
    }
    .ml-popup-list{
      .popup-content{
        background: $secundary_6;
        border: 1px solid $neutral_1;
        box-shadow: 0 0 24px 0 rgba(255,255,255,.22), 0 24px 24px 0 rgba(255,255,255,.30);
        .at-font-p{
          color: $neutral_1;
        }
        .at-font-h1, .at-font-h2, .at-font-h3, .at-font-h4, .at-font-h5{
          color: $neutral_1;
        }
        .ic-cerrar{
          &:before{
            color: $neutral_1;
          }
        }
      }
    }
    //molecula tabs
    .ml-specific-tabs{
      .list-tabs{
        .tab.actived{
          color: $neutral_1;
          border-bottom: 2px solid $neutral_1;
        }
        .tab:hover{
          color: $neutral_1;
        }
      }
    }
    .ml-general-tabs{
      .list-tabs{
        .tab{
          border-bottom: 1px solid $neutral_1;
        }
        .tab.actived{
          color: $neutral_1;
          border: 1px solid $neutral_1;
          border-bottom: 1px solid #000;
        }
      }
      .contents-tabs{
        border: 1px solid $neutral_1;
      }
    }
    .ml-notification-full-screen{
      background: #000;
      i{
        &:before{
          color: $neutral_1;
          border: 2px solid $neutral_1;
        }
      }
    }
    .ml-step-by-step{
      .stepper{
        .step{
          &:first-child{
            .status-step{
              &:after{
                background: #000;
              }
            }
          }
          &:last-child{
            .status-step{
              &:after{
                background: #000;
              }
            }
          }
          .status-step{
            &.active{
              background: #000;
              border: 2px solid $neutral_1;
              &:before{
                background: $neutral_1;
              }
            }
            &.complete{
              background: $neutral_1;
              border: 2px solid $neutral_1;
              i{
                &:before{
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
    #basic-search{
      .container-testing{
        background: transparent;
        border: 1px solid #fff;
        .ml-search-basic{
          &.open{
            .at-input-label{
              background: transparent;
              &:after{
                background: transparent;
              }
            }
          }
        }
      }
    }
    .ml-accordion{
      .at-containershadow-tertiary{
        background-color: transparent;
        .accordion-item{
          .item-body{
            .elements-navigation{
              .at-element-list{
                .at-link{
                  color: $neutral_1;
                  i{
                    &:before{
                      color: $neutral_1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .or-footer{
      .firm-footer{
        background: transparent;
        border: 1px solid $neutral_1;
      }
    }
    .ml-dropdown-up{
      .action-dropdown{
        background: transparent;
        border: 1px solid $neutral_1;
      }
      .popup-select{
        .at-containershadow-secondary{
          background-color: #000;
          border: 1px solid $neutral_1;
          .close-popup-select{
            i{
              &:before{
                color: $neutral_1;
              }
            }
          }
          .countries{
            .item-countrie{
              a{
                .at-font-small{
                  color: $neutral_1;
                }
              }
            }
          }
        }
      }
    }
    .ml-footer-navigation{
      background-color: transparent;
      .block-navigation{
        .elements-navigation{
          .at-element-list{
            li{
              color: $neutral_1;
              .at-link{
                color: $neutral_1;
                i{
                  &:before{
                    color: $neutral_1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ml-general-interest{
      .at-containershadow-tertiary{
        background: transparent;
        .content-info{
          border: 1px solid $neutral_1;
          .content-button{
            .at-button-tertiary{
              color: $neutral_1;
            }
          }
        }
      }
    }
    .ml-table{
      .at-containershadow-tertiary{
        background-color: transparent;
        table{
          thead{
            border-bottom: 2px solid $neutral_1;
            th{
              color: $neutral_1;
              i{
                &:before{
                  color: $neutral_1;
                }
              }
            }
          }
          tbody{
            tr{
              border-bottom: 1px solid $neutral_1;
              td{
                color: $neutral_1;
                i{
                  &:before{
                    color: $neutral_1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ml-boxe{
      .at-containershadow-tertiary{
        background: transparent;
        border: 1px solid $neutral_1;
      }
    }
    .ml-list-group, .ml-list-icon, .ml-list-button, .ml-list-option{
      background: transparent;
      border: 1px solid $neutral_1;
      .header-list{
        border-bottom: 1px solid $neutral_1;
      }
      ul{
        a, li{
          border-bottom: 1px solid $neutral_1;
          &:last-child{
            border: none;
          }
          i{
            background-color: transparent;
            border-color: $neutral_1;
            &:before{
              color: $neutral_1;
            }
          }
          p{
            span{
              color: $neutral_1;
            }
          }
        }
      }
      .more{
        a{
          color: $neutral_1;
        }
      }
    }
  }
  //fin contraste
  .accessible-images{
    border: 1px solid #00C8FF;
    position: relative;
    img, svg{
       opacity: 0;
    }
    span{
      color: #787878;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .at-dropdown-menu{
    li{
      a{
        text-transform: lowercase;
      }
    }
  }
}
//molecula card informative
.ml-card-informative{
  .accessible-images{
    width: 80px;
    height: 80px;
    margin-top: -60px;
    @media only screen and (max-width: 768px){
      margin: -40px auto 0 auto;
    }
    span{
      margin-top: 30px;
    }
  }
}
.toTop {
  position: fixed;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: block;
  border-radius: 50px;
  background:$secundary_1;
  color: #fff;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  display: none;
  opacity: 0.40;
}
.container-content{
  .at-button-iconcolor, .at-button-floating{
    i{
      &:before{
        //margin-left: -3px;
        // @media(max-width: 770px){
        //   margin: 0 6px;
        // }
      }
    }
  }
}
