.ml-banner-big{
  @extend %banner;
  img{
    width: 100%;
  }
  .content-center{
    @extend %content-align;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    max-width: 35%;
  }
  .content-left{
    @extend %content-align;
    left: 10%;
    max-width: 35%;
    height: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  .content-right{
    @extend %content-align;
    right: 10%;
    max-width: 35%;
    height: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  &.banner-white{
    .at-font-h1, .at-font-h3, .at-font-h5, .at-font-p{
      color: $neutral_1;
    }
  }
}
