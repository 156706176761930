.at-progressbar{
  @extend %progress;
  background: transparentize($primary_1, .9);
  position: relative;
  overflow: hidden;
  &.full{
    &:before{
      width: 100%;
      background: $utility_1;
    }
  }
  &.three-quarters{
    &:before{
      width: 75%;
      background: $secundary_1;
    }
  }
  &.half{
    &:before{
      width: 50%;
      background: #ff8455;
    }
  }
  &.quarter{
    &:before{
      width: 25%;
      background: $utility_2;
    }
  }
  &:before{
    content: " ";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 50%;
    height: 7px;
    //background: $secundary_1;
  }
}
