.ml-card-block{
  .container-card{
    padding: 1.5rem 0;
    border-bottom: 1px solid rgba(0,0,0,.12);
    .contenido{
      align-items: center;
      .content-img{
        text-align: center;
        @include for-mobile-s{
          padding: 1rem 0;
        }
        img{
          max-width: 120px;
          max-height: 120px;
          width: 100%;
          height: 100%;
          @include for-mobile-s{
            max-width: 80px;
            max-height: 80px;
          }
        }
      }
      .content-card{
        .content-info{
          .at-font-h4{
            color: #7b7b7b;
            transition: all .3s ease;
          }
          .at-font-em{
            color: #bcbcbc;
            transition: all .3s ease;
          }
        }
      }
    }
    &:hover{
      .contenido{
        .content-card{
          .content-info{
            .at-font-h4{
              color: $primary_1;
            }
            .at-font-em{
              color: $neutral_2;
            }
          }
        }
      }
    }
  }
}
