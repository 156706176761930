.tm-payment{
  @extend %payment;
  section{
    margin: 50px 0;
    &.payment-header{
      text-align: center;
    }
    &.payment-info{
      .at-font-h5{
        display: inline-block;
      }
      .at-font-p{
        display: inline-block;
      }
    }
    &.payment-methods{
      .at-font-h5{
        margin: 20px 0;
      }
      .ml-accordion{
        .at-containershadow-tertiary{
          max-width: none;
          .accordion-item{
            padding: 15px;
            .item-title{
              .at-font-h5{
                margin: 0;
              }
            }
            .item-body{
              *{
                margin-bottom: 0;
              }
              .row{
                .at-input-label, .at-selectlabel{
                  width: 100%;
                  .at-input-textfield, .at-input-select{
                    min-width: auto;
                    width: 100%;
                  }
                }
                .at-input-checkbox{
                  vertical-align: middle;
                  margin-right: 5px;
                }
                .at-font-p{
                  display: inline-block;
                  font-size: $font_18x;
                  i{
                    vertical-align: middle;
                    margin-left: 10px;
                    &:before{
                      color: $secundary_1;
                    }
                  }
                }
                &.check, &.call-to-action{
                  margin-top: 20px;
                  margin-bottom: 20px;
                }
                &.call-to-action{
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}