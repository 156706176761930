.pg-quote{
  @extend %quote;
  section{
    margin: 50px 0;
    &.quote-header{
      .at-font-p, .at-font-h5{
        margin: 15px 0;
        text-align: center;
      }
      .at-selectlabel{
        display: inline-block;
        margin-right: 20px;
      }
      .row{
        .col-md-4{
          align-items: center;
          .at-font-p{
            color: $secundary_1;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
    &.quotation{
      .ml-accordion{
        .at-containershadow-tertiary{
          max-width: none;
          .accordion-item{
            padding: 15px;
            .item-body{
              padding: 0 12px 20px;
              *{
                margin-bottom: 0;
              }
              .row{
                border-bottom: 1px solid #ababab;
                padding: 30px 0;
                .at-font-p{
                  font-size: $font_18x;
                }
                .at-input-checkbox{
                  margin-right: 20px;
                  label{
                    vertical-align: middle;
                  }
                  .at-font-p{
                    display: inline-block;
                    vertical-align: middle;
                  }
                }
                &.extra{
                  .col-md-5{
                    .at-input-checkbox{
                      width: 49%;
                      margin-bottom: 15px;
                      margin-right: 0;
                      label{
                        margin-right: 10px;
                      }
                    }
                  }
                }
                &.summary{
                  border-bottom: none;
                  .at-font-small-legal{
                    display: block;
                  }
                  .summary-pay{
                    border-bottom: 1px solid #ababab;
                    .at-font-p{
                      width: 49%;
                      display: inline-block;
                      margin-bottom: 20px;
                      &:nth-child( 2 ), &:nth-child( 4 ){
                        text-align: right;
                      }
                    }
                  }
                  .total-summary{
                    .at-font-h5{
                      width: 49%;
                      display: inline-block;
                      margin: 20px 0;
                      font-weight: 600;
                      &:last-child{
                        text-align: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .new-price{
        margin: 30px 0;
        padding: 0;
        .at-font-p{
          color: $secundary_1;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}