%action-dropdown{
  background: transparentize($secundary_6, .90);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 110px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  //margin: 0 auto;
  padding-right: 18px;
  .at-font-p{
    color: $neutral_1;
  }
  .ic-expandir-mas{
    //margin-right: 18px;
    &:before{
      font-size: 1.5rem;
      color: $neutral_1;
    }
  }
}
%countries{
  display: none;
  position: absolute;
  bottom: initial;
  box-sizing: border-box;
  max-width: 375px;
  //width: 100%;
  border-radius: 2px;
  .close-popup-select{
    .ic-cerrar{
      cursor: pointer;
      position: absolute;
      right: 18px;
      top: 15px;
      &:before{
        font-size: 24px;
      }
      @include for-mobile{
        right: 0;
      }
    }
  }
  .at-containershadow-secondary{
    padding: 0;
  }
  .title-select-countries{
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 60px 0 18px;
    // @include for-mobile{
    //   padding: 0 5px;
    // }
    h4{
      font-size: 18px;
    }
  }
  .countries{
    .item-countrie{
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 18px;
      text-align: left;
      &:hover{
        background: transparentize($neutral_2, .74);
      }
      a{
        text-decoration: none;
        width: 100%;
        i{
          margin-right: 18px;
        }
        .at-font-small{
          color: $neutral_2;
          display: inline-block;
          font-size: .6875rem;
          float: none;
        }
      }
    }
  }
}
%flag-example{
  .ic-flag-costa{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #7f9bbd;
    border-radius: 100%;
    margin-right: 12px;
    background: url(#{$imagesPath}muestras/Costa_Rica.png);
    background-size: cover;
  }
  .ic-flag-bolivia{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #7f9bbd;
    border-radius: 100%;
    margin-right: 12px;
    background: url(#{$imagesPath}muestras/Bolivia.png);
    background-size: cover;
  }
  .ic-flag-salvador{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #7f9bbd;
    border-radius: 100%;
    margin-right: 12px;
    background: url(#{$imagesPath}muestras/El_Salvador.png);
    background-size: cover;
  }
  .ic-flag-guatemala{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #7f9bbd;
    border-radius: 100%;
    margin-right: 12px;
    background: url(#{$imagesPath}muestras/Guatemala.png);
    background-size: cover;
  }
  .ic-flag-honduras{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #7f9bbd;
    border-radius: 100%;
    margin-right: 12px;
    background: url(#{$imagesPath}muestras/Honduras.png);
    background-size: cover;
  }
  .ic-flag-colombia{
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    color: #7f9bbd;
    border-radius: 100%;
    margin-right: 12px;
    background: url(#{$imagesPath}muestras/bandera-colombia.png);
    background-size: cover;
  }
}
