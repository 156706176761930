%button{
  background: transparent;
  cursor: pointer;
  font-family: $r-semibold;
  vertical-align: middle;
  line-height: 34px;
  border-radius: 50px;
  font-size: $font_3x;
  color: $secundary_1;
  display:inline-block;
  border:0;
  border: $border_1 transparent;
  transition: .3s;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  position: relative;
  width: auto;
  margin: 0;
  &:hover{
    color:$secundary_3;
  }
  &:focus,
  &:active {
    color: $secundary_2;
    outline: none;
  }
  &:disabled{
    cursor: initial;
    color: rgba(0, 200, 255, .3);
  }
  &[class*='ic-']:before{
    font-family: $icons-tigoune;
    margin: 0 6px;
    font-size: $font_14x;
  }
  [class*='ic-']:before{
    font-family: $icons-tigoune;
    margin: 0 6px;
    font-size: $font_14x;
  }
}
@include for-mobile{
  %button{
    font-size: $font_3x;
  }
  .form-wrapper.form-actions{
    %button{
      margin: 0;
      padding-bottom: 0;
      width: auto;
    }
  }
}
