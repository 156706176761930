%content {
  padding: 1rem;
  .at-font-h2 {
    text-align: center;
  }
  .at-font-h4 {
    text-align: center;
    margin: 20px 10px;
  }
  .at-input-label {
    width: 100%;
    .at-input-textfield {
      width: 100%;
    }
  }
}
%form-card {
  padding: 20px 5px 10px 30px;
}