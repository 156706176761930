.pg-portability{
  @extend %portability;
  section{
    margin: 60px 0;
    h4{
      margin: 20px 0;
    }
    &.circular-slider{
      .at-font-h3, .at-font-p{
        margin: 10px 0;
      }
      .in-mobile-slider{
        margin: 55px 0;
        h5{
          color: $neutral_2;
        }
      }
      .btn-terms{
        text-align: center;
      }
    }
    .row{
      .at-input-label, .at-selectlabel{
        width: 100%;
        margin-bottom: 0;
        .at-input-textfield, .at-input-select{
          min-width: auto;
          width: 100%;
        }
      }
      .at-input-checkbox{
        vertical-align: middle;
        margin-right: 5px;
      }
      .at-font-p{
        display: inline-block;
        font-size: $font_18x;
        i{
          vertical-align: middle;
          margin-left: 10px;
          &:before{
            color: $secundary_1;
          }
        }
      }
      &.check, &.call-to-action{
        margin-top: 20px;
        margin-bottom: 20px;
      }
      &.call-to-action{
        text-align: right;
      }
      .verification-code{
        .at-input-label{
          margin: 0;
        }
        .at-font-p{
          margin: 20px 0;
        }
        .at-button-tertiary, .at-button-primary{
          width: 49%;
        }
        &.inactive{
          opacity: .3;
          .at-link{
            pointer-events: none;
          }
        }
      }
    }
  }
  .ml-banner-center{
    img{
      max-width: none;
      max-height: none;
    }
  }
}
