.ml-card-big{
  img{
    max-width: 220px;
    // max-height: 176px;
    float: left;
    @include for-mobile {
      float: none;
    }
  }
  .content-card {
    @extend %content-card;
    .at-font-p {
      padding-bottom: 2.5rem;
      @include for-mobile {
        padding-bottom: 0;
      }
    }
    .buttons-left{
      @extend %buttons;
      text-align: left;
      @include for-mobile {
        position: initial;
        float: right;
      }
    }
    .buttons-center{
      @extend %buttons;
      text-align: center;
      @include for-mobile {
        position: initial;
        float: right;
      }
    }
    .buttons-right{
      @extend %buttons;
      right: 1rem;
      text-align: right;
      @include for-mobile {
        position: initial;
        float: right;
      }
    }
  }
}
