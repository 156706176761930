@keyframes animateBarOpacity{
  0%{
    width: 0;
  }
  100%{
    width: 100%;
  }
}
@keyframes animateSearchIcon{
  0%{
    opacity: 1;
    margin-left: 0;
  }
  10%{
    opacity: 0;
    margin-left: 0;
  }
  50%{
    opacity: 0;
    margin-left: 25px;
  }
  80%{
    opacity: 0;
    margin-left: 25px;
  }
  100%{
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes animateInputSearch{
  0%{
    opacity: 1;
  }
  10%{
    opacity: 0;
  }
  50%{
    opacity: 0;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes animateCloseIcon{
  0%{
    opacity: 0;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes closeCloseIcon{
  0%{
    opacity: 1;
  }
  90%{
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
@keyframes closeInputSearch{
  0%{
    opacity: 1;
  }
  10%{
    opacity: 0;
  }
  50%{
    opacity: 0;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
@keyframes closeBarOpacity{
  0%{
    width: 100%;
  }
  100%{
    width: 0;
  }
}
@keyframes closeSearchIcon{
  0%{
    opacity: 1;
  }
  10%{
    opacity: 0;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.ml-search-basic{
  max-width: 860px;
  float: none;
  position: relative;
  display: inline-block;
  &.open{
    width: 100%;
    animation: animateBarOpacity 1s linear;
    .at-input-label{
      width: 100%;
      background: $primary_1;
      animation: animateBarOpacity 1s linear;
      @include for-mobile{
        padding-left: 12px;
        box-sizing: border-box;
      }
      &.active{
        .at-input-search{
          color: transparentize($neutral_1, .3);
          padding: 0 10px;
          @include for-mobile{
            border: 1px solid $secundary_1;
          }
        }
      }
      &:after{
        content: "";
        width: 120px;
        height: 65px;
        background: linear-gradient(to right, transparent, $primary_1);
        position: absolute;
        top: 0;
        left: -120px;
        z-index: 9;
        @include for-mobile{
          height: 48px;
        }
      }
      &:hover{
        .at-input-search{
          border: 0;
          outline: none;
          border-left: 1px solid $neutral_1;
          color: transparentize($neutral_1, .3);
          @include for-mobile{
            border-left: 1px solid $secundary_1;
          }
        }
      }
      .at-input-search{
        width: 100%;
        animation: animateInputSearch 1.5s linear;
        @include for-mobile{
          padding-left: 12px;
          box-sizing: border-box;
        }
      }
      .ic-buscar{
        animation: animateSearchIcon 1.5s linear;
      }
      .ic-cerrar{
        animation: animateCloseIcon 1.5s linear;
      }
    }
    .ic-buscar{
      @include for-mobile{
        display: none;
        transition: .5s linear;
      }
    }
  }
  &.closing{
    animation: closeBarOpacity 1s linear;
    .at-input-label{
      .at-input-search{
        animation: closeBarOpacity 1s linear;
        animation: closeInputSearch 1.5s linear;
      }
      .ic-cerrar{
        animation: closeCloseIcon 1.5s linear;
        display: none;
      }
      .ic-buscar{
        animation: closeSearchIcon 1.5s linear;
      }
    }
  }
  .at-input-label{
    @extend %search;
  }
  .at-containershadow-secondary{
    display: none;
    opacity: 0;
    padding: 12px $size_1x * 2;
    box-sizing: border-box;
    max-width: none;
    //margin-top: 72px;
    position: absolute;
    top: calc(100% + 4px);
    text-align: left;
    &.open{
      transition: opacity .5s;
      display: block;
      opacity: 1;
    }
    .at-font-h4{
      font-size: $font_3x;
      color: transparentize($neutral_2, .4);
      font-weight: bold;
      text-transform: uppercase;
      margin: 12px 0;
    }
    .at-link{
      display: block;
      font-size: $font_2x;
      padding: 20px;
      color: $neutral_2;
      font-weight: bold;
      &:hover{
        color: $primary_1;
        background: #f3f3f3;
      }
    }
  }
}
