.at-button-floating{
  @extend %button;
  display: flex;
  align-items: center;
  background-color: $secundary_1;
  color:$neutral_1;
  padding:0 18px;
  height: 48px;
  box-shadow: $shadow_2;
  border-radius: 24px;
  font-size: $font_18x;
  letter-spacing: 0;
  @include for-mobile{
    border-radius: 100px;
    padding: 6px 5px;
    width: 48px;
  }
  span{
    color:$neutral_1;
    @include for-mobile{
      display: none;
    }
  }
  i{
    display: inline-block;
    vertical-align: top;
    margin-right: 0;
    @include for-mobile{
      margin-right: 0;
    }
    &:before{
      color: $neutral_1;
      font-size: 34px;
    }
  }
  &:hover{
    color: $neutral_1;
    background-color:$secundary_3;
    box-shadow: $shadow_6;
  }
  &:disabled{
    background-color: transparentize($secundary_1, .7);
    color: $neutral_1;
  }
  &:focus{
    background-color:$secundary_2;
    color: $neutral_1;
  }
}
