.or-navbar-top{
  @extend %navbar;
  background: $primary_3;
  padding: 0 100px;
  height: auto;
  height: 32px;
  justify-content: space-between;
  .navbar-brand{
    @extend %navbar-brand;
    .at-link{
      padding: 10px $size_1x;
      &.active{
        background: $primary_1;
      }
    }
  }
  .navbar-collapse{
    @extend %navbar-collapse;
    float: right;
    .at-element-list{
      display: flex;
      align-items: center;
      li{
        .at-link{
          display: flex;
          align-items: center;
          &:hover{
            color: $secundary_1;
            svg{
              g{
                stroke: $secundary_1;
                circle{
                  stroke: $secundary_1;
                }
              }
            }
          }
          svg{
            margin: 0 12px;
          }
        }
      }
    }
    .dropdown{
      padding: 6px $size_1x;
      padding-right: 0;
      i{
        //float: right;
        margin: 0 $size_1x;
        &:before{
          color: $neutral_1;
          font-size: $font_4x;
        }
      }
      .at-containershadow-secondary {
        right: 10px;
        top: 90%;
      }
      &.my-account{
        > i{
          display: none;
        }
      }
      .ml-dropdown-up{
        .action-dropdown{
          padding: 0;
          background: none;
        }
        .popup-select{
          .at-containershadow-secondary{
            .ic-cerrar{
              &:before{
                color: unset;
              }
            }
          }
        }
      }
      &.pqr{
        .at-link{
          display: flex;
          align-items: center;
          i{
            vertical-align: middle;
            &:before{
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
  @include for-tablet{
    padding: 0;
    display: none;
    .navbar-collapse{
      .dropdown{
        &.my-account{
          > .at-link{
            display: none;
          }
          > i{
            display: block;
          }
        }
        &.important-information{
          i{
            display: none;
          }
        }
      }
    }
  }
  @include for-mobile{
    .navbar-collapse{
      .dropdown{
        > .at-link{
          font-size: $font_1x;
        }
        padding: 6px 0;
        margin-top: 3px;
        .at-containershadow-secondary{
          right: 0;
          top: 100%;
        }
      }
    }
  }
  @include for-mobile-s{
    .navbar-brand{
      .at-link{
        padding: 10px;
        font-size: $font_1x;
      }
    }
  }
}
