.ml-tooltip{
  display: inline-block;
  .tooltip-icon{
    display: inline-block;
    position: relative;
    .ic-consulta{
      font-size: $font_2x;
      &:before{
        color: $secundary_1;
      }
    }
    .at-containershadow-tertiary{
      display: none;
      @extend %tooltip;
      background-color: $neutral_2;
      color: $neutral_1;
      position: absolute;
      width: 220px;
      bottom: calc(100% + 11px);
      left: -15px;
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 8%;
        display: inline-block;
        margin-left: -5px;
        border-width: 8px;
        border-style: solid;
        border-color: $neutral_2 transparent transparent transparent;
      }
      &:before{
        content: "";
        position: absolute;
        top: 100%;
        left: 8%;
        display: inline-block;
        margin-left: -7px;
        border-width: 10px;
        border-style: solid;
        border-color: rgba(0,0,0, .14) transparent transparent transparent;
      }
      .icon-text-content{
        display: flex;
        align-items: center;
        .at-font-p{
          font-size: $font_2x;
          color: $neutral_1;
          line-height: normal;
          a{
            font-family: $r-bold;
            text-decoration: underline;
            color: $neutral_1;
            display: inline-block;
          }
        }
        i{
          font-size: $font_12x;
          margin-right: 12px;
          &:before{
            color: $neutral_1;
          }
        }
      }
      img{
        margin-top: 6px;
        margin-bottom: 6px;
        width: 100%;
      }
    }
  }
  &.white-tooltip{
    .at-containershadow-tertiary{
      background-color: $neutral_1;
      color: $neutral_2;
      &:after{
        border-color: $neutral_1 transparent transparent transparent;
      }
      .icon-text-content{
        .at-font-p{
          color: $neutral_2;
          a{
            color: $secundary_1;
            text-decoration: none;
          }
        }
        i{
          &:before{
            color: $primary_1;
          }
        }
      }
    }
  }
}
.fieldset-tooltip{
  padding: 20px 40px;
}
