.pg-home{
  @extend %home;
  section{
    margin: 50px 0;
    h4{
      margin: 20px 0;
    }
    &.cards{
      padding: 0 20px;
      .ml-card-big{
        img{
          width: 100px;
        }
        .content-card{
          padding: 0;
        }
      }
      .ml-boxe{
        margin-bottom: 20px;
        cursor: pointer;
        border: 1px solid transparent;
        @include for-desktop{
          margin-bottom: 0;
        }
        &:hover{
          border: 1px solid $secundary_1;
        }
      }
    }
    &.carousel-home{
      .or-carousel-gallery{
        img{
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    &.img-cards{
      .ml-card-big{
        img{
          width: 90px;
        }
        .content-card{
          padding: 0;
        }
      }
    }
    &.gallery-img-rounded{
      .col-md-3{
        text-align: center;
        img{
          width: 120px;
        }
        small{
          display: block;
          color: $neutral_2;
        }
      }
    }
  }
}
