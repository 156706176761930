.ml-list-option{
  padding: 0;
  .header-list{
    padding-top: 36px;
    padding-bottom: 12px;
    border-bottom: 1px solid transparentize($neutral_2, .8);
    @include for-mobile{
      padding-top: 24px;
    }
    h4{
      padding: 0 36px;
      @include for-mobile{
        padding: 0 24px;
      }
    }
  }
  ul{
    padding: 36px;
    padding-top: 0;
    list-style: none;
    margin: 0;
    @include for-mobile{
      padding: 24px;
      padding-top: 0;
    }
    li{
      display: flex;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid transparentize($neutral_2, .8);
      @include for-mobile{
        padding: 12px 0;
      }
      &:last-child{
        padding-bottom: 0;
        border: none;
      }
      .at-input-checkbox{
        margin-right: 24px;
        @include for-mobile{
          margin-right: 12px;
        }
      }
      p{
        font-size: $font_18x;
        span{
          color: $primary_1;
        }
      }
    }
  }
}
