%accordion-image{
  .ml-accordion{
    .at-containershadow-tertiary{
      display: inline-block;
      vertical-align: top;
      width: 50%;
      @include for-mobile{
        width: 100%;
        display: block;
      }
      .accordion-item{
        .item-title{
          padding: 14px 24px;
          .at-font-h4{
            font-size: $font_18x;
          }
        }
        .item-body{
          max-height: 110px;
          overflow-y: scroll;
          overflow-x: hidden;
          @include for-mobile{
            max-height: initial;
          }
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $secundary_1;
            border-radius: 10px;
          }
          img{
            display: none;
            @include for-mobile{
              display: block;
            }
          }
          .buttons-right{
            display: block;
            text-align: right;
          }
        }
      }
      .accordion-item:nth-child(1){
        .item-body{
          display: block;
        }
        .at-divider{
          border: 1px solid $secundary_1;
        }
      }
    }
    .image-container{
      display: inline-block;
      vertical-align: top;
      width: 49%;
      @include for-mobile{
        display: none;
      }
      img{
        display: none;
        &.visible-item{
          display: block;
        }
      }
    }
  }
}
