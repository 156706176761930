$icomoon-font-family: "tigo-icons-pack1" !default;
$icomoon-font-path: "fonts" !default;

$brand-pt-tigo-money-full-path1: "\e900";
$brand-pt-tigo-money-full-path2: "\e901";
$brand-pt-tigo-money-full-path3: "\e902";
$brand-pt-tigo-money-full-path4: "\e903";
$brand-pt-tigo-music-full-path1: "\e904";
$brand-pt-tigo-music-full-path2: "\e905";
$brand-pt-tigo-music-full-path3: "\e906";
$brand-pt-tigo-music-full-path4: "\e907";
$brand-pt-tigo-music-full-path5: "\e908";
$brand-pt-tigo-onetv-full-path1: "\e909";
$brand-pt-tigo-onetv-full-path2: "\e90a";
$brand-pt-tigo-onetv-full-path3: "\e90b";
$brand-pt-tigo-onetv-full-path4: "\e90c";
$brand-pt-tigo-onetv-full-path5: "\e90d";
$brand-pt-tigo-onetv-full-path6: "\e90e";
$brand-pt-tigo-sports-full-path1: "\e90f";
$brand-pt-tigo-sports-full-path2: "\e910";
$brand-pt-tigo-sports-full-path3: "\e911";
$brand-pt-tigo-sports-full-path4: "\e912";
$logo-gt-amazon-primevideo: "\e913";
$logo-gt-android: "\e914";
$logo-gt-facebook-logo-outline: "\e915";
$logo-gt-facebook-logo: "\e916";
$brand-gt-go-contenedor-logo-path1: "\e917";
$brand-gt-go-contenedor-logo-path2: "\e918";
$logo-gt-instagram-logo-outline: "\e91a";
$logo-gt-Instagram-logo: "\e91b";
$logo-gt-ios: "\e91c";
$brand-gt-mi-tigo-contenedor-path1: "\e91d";
$brand-gt-mi-tigo-contenedor-path2: "\e91e";
$brand-gt-mi-tigo-full-path1: "\e91f";
$brand-gt-mi-tigo-full-path2: "\e920";
$brand-gt-mi-tigo: "\e921";
$logo-gt-office365: "\e922";
$brand-gt-tigo-business-contenedor-logo-path1: "\e923";
$brand-gt-tigo-business-contenedor-logo-path2: "\e924";
$brand-gt-tigo-business-logo: "\e925";
$brand-gt-tigo-contenedor-logo-path1: "\e926";
$brand-gt-tigo-contenedor-logo-path2: "\e927";
$brand-gt-tigo-logo: "\e928";
$brand-gt-tigo-money-full-path1: "\e929";
$brand-gt-tigo-money-full-path2: "\e92a";
$brand-gt-tigo-money: "\e92b";
$brand-gt-tigo-music-full-path1: "\e92c";
$brand-gt-tigo-music-full-path2: "\e92d";
$brand-gt-tigo-music: "\e92e";
$brand-gt-tigo-onetv-full-path1: "\e92f";
$brand-gt-tigo-onetv-full-path2: "\e930";
$brand-gt-tigo-onetv: "\e931";
$brand-gt-tigo-play-contenedor-path1: "\e932";
$brand-gt-tigo-play-contenedor-path2: "\e933";
$brand-gt-tigo-play-full-path1: "\e934";
$brand-gt-tigo-play-full-path2: "\e935";
$brand-gt-tigo-play: "\e936";
$brand-gt-tigo-shop-contenedor-path1: "\e937";
$brand-gt-tigo-shop-contenedor-path2: "\e938";
$brand-gt-tigo-shop-full-path1: "\e939";
$brand-gt-tigo-shop-full-path2: "\e93a";
$brand-gt-tigo-shop: "\e93b";
$brand-gt-tigo-sports-full-path1: "\e93c";
$brand-gt-tigo-sports-full-path2: "\e93d";
$brand-gt-tigo-sports-full-path3: "\e93e";
$brand-gt-tigo-sports: "\e93f";
$logo-nt-android: "\e940";
$logo-nt-facebook-logo-outline-path1: "\e941";
$logo-nt-facebook-logo-outline-path2: "\e942";
$logo-nt-facebook-logo: "\e943";
$logo-nt-instagram-logo-outline-path1: "\e944";
$logo-nt-instagram-logo-outline-path2: "\e945";
$logo-nt-Instagram-logo: "\e946";
$logo-nt-ios: "\e947";
$brand-nt-mi-tigo-path1: "\e948";
$brand-nt-mi-tigo-path2: "\e949";
$brand-nt-tigo-logo: "\e94a";
$brand-nt-tigo-money-path1: "\e94b";
$brand-nt-tigo-money-path2: "\e94c";
$brand-nt-tigo-music-path1: "\e94d";
$brand-nt-tigo-music-path2: "\e94e";
$brand-nt-tigo-onetv-path1: "\e94f";
$brand-nt-tigo-onetv-path2: "\e950";
$brand-nt-tigo-play-path1: "\e951";
$brand-nt-tigo-play-path2: "\e952";
$brand-nt-tigo-shop-path1: "\e953";
$brand-nt-tigo-shop-path2: "\e954";
$brand-nt-tigo-sports-path1: "\e955";
$brand-nt-tigo-sports-path2: "\e956";
$logo-nt-twitter-logo-outline-path1: "\e957";
$logo-nt-twitter-logo-outline-path2: "\e958";
$logo-nt-twitter-logo: "\e959";
$logo-nt-youtube-logo-outline-path1: "\e95a";
$logo-nt-youtube-logo-outline-path2: "\e95b";
$logo-nt-youtube-logo: "\e95c";
$logo-pt-amazon-primevideo-path1: "\e95d";
$logo-pt-amazon-primevideo-path2: "\e95e";
$logo-pt-android: "\e95f";
$logo-pt-facebook-logo-outline-path1: "\e960";
$logo-pt-facebook-logo-outline-path2: "\e961";
$logo-pt-facebook-logo: "\e962";
$brand-pt-go-contenedor-logo-path1: "\e963";
$brand-pt-go-contenedor-logo-path2: "\e964";
$logo-pt-instagram-logo-outline-path1: "\e965";
$logo-pt-instagram-logo-outline-path2: "\e966";
$logo-pt-Instagram-logo: "\e967";
$logo-pt-ios: "\e968";
$brand-pt-mi-tigo-contenedor-path1: "\e969";
$brand-pt-mi-tigo-contenedor-path2: "\e96a";
$brand-pt-mi-tigo-full-path1: "\e96b";
$brand-pt-mi-tigo-full-path2: "\e96c";
$brand-pt-mi-tigo-full-path3: "\e96d";
$brand-pt-mi-tigo-path1: "\e96e";
$brand-pt-mi-tigo-path2: "\e96f";
$logo-pt-office365-path1: "\e970";
$logo-pt-office365-path2: "\e971";
$brand-pt-tigo-business-contenedor-logo-path1: "\e972";
$brand-pt-tigo-business-contenedor-logo-path2: "\e973";
$brand-pt-tigo-business-contenedor-logo-path3: "\e974";
$brand-pt-tigo-business-logo-path1: "\e975";
$brand-pt-tigo-business-logo-path2: "\e976";
$brand-pt-tigo-contenedor-logo-path1: "\e977";
$brand-pt-tigo-contenedor-logo-path2: "\e978";
$brand-pt-tigo-logo: "\e979";
$brand-pt-tigo-money-path1: "\e97a";
$brand-pt-tigo-money-path2: "\e97b";
$brand-pt-tigo-music-path1: "\e97c";
$brand-pt-tigo-music-path2: "\e97d";
$brand-pt-tigo-onetv-path1: "\e97e";
$brand-pt-tigo-onetv-path2: "\e97f";
$brand-pt-tigo-play-contenedor-path1: "\e980";
$brand-pt-tigo-play-contenedor-path2: "\e981";
$brand-pt-tigo-play-full-path1: "\e982";
$brand-pt-tigo-play-full-path2: "\e983";
$brand-pt-tigo-play-full-path3: "\e984";
$brand-pt-tigo-play-path1: "\e985";
$brand-pt-tigo-play-path2: "\e986";
$brand-pt-tigo-shop-contenedor-path1: "\e987";
$brand-pt-tigo-shop-contenedor-path2: "\e988";
$brand-pt-tigo-shop-full-path1: "\e989";
$brand-pt-tigo-shop-full-path2: "\e98a";
$brand-pt-tigo-shop-full-path3: "\e98b";
$brand-pt-tigo-shop-path1: "\e98c";
$brand-pt-tigo-shop-path2: "\e98d";
$brand-pt-tigo-sports-path1: "\e98e";
$brand-pt-tigo-sports-path2: "\e98f";
$logo-pt-twitter-logo-outline-path1: "\e990";
$logo-pt-twitter-logo-outline-path2: "\e991";
$logo-pt-twitter-logo: "\e992";
$logo-pt-youtube-logo-outline-path1: "\e993";
$logo-pt-youtube-logo-outline-path2: "\e994";
$logo-pt-youtube-logo: "\e995";
$gt-4g-large: "\e996";
$gt-4g: "\e997";
$gt-accougt-status: "\e998";
$gt-add-balance: "\e999";
$gt-add-card: "\e99a";
$gt-add-channels: "\e99b";
$gt-add-user: "\e99c";
$gt-add: "\e99d";
$gt-advanced-solutions-outline: "\e99e";
$gt-advanced-solutions-path1: "\e99f";
$gt-advanced-solutions-path2: "\e9a0";
$gt-agreement: "\e9a1";
$gt-analog-tv: "\e9a2";
$gt-apn: "\e9a3";
$gt-app-premium: "\e9a4";
$gt-app-store: "\e9a5";
$gt-apps-full: "\e9a6";
$gt-apps-small: "\e9a7";
$gt-apps: "\e9a8";
$gt-at: "\e9a9";
$gt-balance-large: "\e9aa";
$gt-balance: "\e9ab";
$gt-banktransfer: "\e9ac";
$gt-bigdata: "\e9ad";
$gt-billing: "\e9ae";
$gt-billpayment_1: "\e9af";
$gt-billpayment: "\e9b0";
$gt-broadband-wifimodem: "\e9b1";
$gt-building: "\e9b2";
$gt-bundle: "\e9b3";
$gt-calendar-money: "\e9b4";
$gt-calendar-timer: "\e9b5";
$gt-calendar: "\e9b6";
$gt-call-rerouting: "\e9b7";
$gt-call: "\e9b8";
$gt-calls-large: "\e9b9";
$gt-calls: "\e9ba";
$gt-cash: "\e9bb";
$gt-check-hexagon: "\e9bc";
$gt-check: "\e9bd";
$gt-clear: "\e9be";
$gt-clock: "\e9bf";
$gt-closed-wifi: "\e9c0";
$gt-cloud-solutions-outline: "\e9c1";
$gt-cloud-solutions-path1: "\e9c2";
$gt-cloud-solutions-path2: "\e9c3";
$gt-cloud: "\e9c4";
$gt-cloudbackup: "\e9c5";
$gt-cloudfirewall: "\e9c6";
$gt-code: "\e9c7";
$gt-complaints: "\e9c8";
$gt-conference-call: "\e9c9";
$gt-connectivity-01: "\e9ca";
$gt-connectivity-solutions-outline: "\e9cb";
$gt-connectivity-solutions-path1: "\e9cc";
$gt-connectivity-solutions-path2: "\e9cd";
$gt-connectivity: "\e9ce";
$gt-contac-backup: "\e9cf";
$gt-contac-list: "\e9d0";
$gt-contact-backup: "\e9d1";
$gt-contact-phone: "\e9d2";
$gt-convergence-tvinternet-path1: "\e9d3";
$gt-convergence-tvinternet-path2: "\e9d4";
$gt-convergence: "\e9d5";
$gt-corporativenoc: "\e9d6";
$gt-Credentialbadge: "\e9d7";
$gt-customer-service: "\e9d8";
$gt-datacenters-colocation: "\e9d9";
$gt-dedicatedinternet: "\e9da";
$gt-delivery: "\e9db";
$gt-dial: "\e9dc";
$gt-discougt-alternate: "\e9dd";
$gt-discount: "\e9de";
$gt-dislike: "\e9df";
$gt-dmzmodem: "\e9e0";
$gt-doublearrow-left-path1: "\e9e1";
$gt-doublearrow-left-path2: "\e9e2";
$gt-doublearrow-right-path1: "\e9e3";
$gt-doublearrow-right-path2: "\e9e4";
$gt-download: "\e9e5";
$gt-edit: "\e9e6";
$gt-error-circle: "\e9e7";
$gt-error: "\e9e8";
$gt-expand-more: "\e9e9";
$gt-family-plan: "\e9ea";
$gt-faq: "\e9eb";
$gt-file-interactive: "\e9ec";
$gt-file-list: "\e9ed";
$gt-file-listok: "\e9ee";
$gt-file-refresh: "\e9ef";
$gt-file-time: "\e9f0";
$gt-file: "\e9f1";
$gt-filedhcp: "\e9f2";
$gt-firewall: "\e9f3";
$gt-fixed-line: "\e9f4";
$gt-friends: "\e9f5";
$gt-geolocation-small: "\e9f6";
$gt-geolocation: "\e9f7";
$gt-googleapps: "\e9f8";
$gt-guestnetwork: "\e9f9";
$gt-hand-up: "\e9fa";
$gt-help-outline: "\e9fb";
$gt-home: "\e9fc";
$gt-hpdcloud: "\e9fd";
$gt-idea-light: "\e9fe";
$gt-imei: "\e9ff";
$gt-incoming-call: "\ea00";
$gt-incoming-outgoing-call-B: "\ea01";
$gt-incoming-outgoing-call: "\ea02";
$gt-info-full: "\ea03";
$gt-info: "\ea04";
$gt-internet-connection: "\ea05";
$gt-internet-outline: "\ea06";
$gt-internet-security: "\ea07";
$gt-internet-path1: "\ea08";
$gt-internet-path2: "\ea09";
$gt-invite: "\ea0a";
$gt-ip-calling: "\ea0b";
$gt-key-money: "\ea0c";
$gt-key-number: "\ea0d";
$gt-landline: "\ea0e";
$gt-lend-me: "\ea0f";
$gt-less: "\ea10";
$gt-line-800: "\ea11";
$gt-linkdata: "\ea12";
$gt-list: "\ea13";
$gt-local-remitances: "\ea14";
$gt-location-ruler: "\ea15";
$gt-long-distance-calls: "\ea16";
$gt-m2m-machinetomachine: "\ea17";
$gt-mail-server: "\ea18";
$gt-menu-more: "\ea19";
$gt-menu: "\ea1a";
$gt-message-read: "\ea1b";
$gt-mobile-balance01: "\ea1c";
$gt-mobile-balance02: "\ea1d";
$gt-mobile-data-large: "\ea1e";
$gt-mobile-data: "\ea1f";
$gt-mobile-extrabalance: "\ea20";
$gt-mobile-outline: "\ea21";
$gt-mobile-phone: "\ea22";
$gt-mobile-promotionalbalance: "\ea23";
$gt-mobile-wifi: "\ea24";
$gt-mobile-path1: "\ea25";
$gt-mobile-path2: "\ea26";
$gt-mobileinternet: "\ea27";
$gt-more-calls: "\ea28";
$gt-more: "\ea29";
$gt-multipledevices: "\ea2a";
$gt-natmodem: "\ea2b";
$gt-networkmonitoring: "\ea2c";
$gt-notebook-calendar: "\ea2d";
$gt-notebook-connectivity: "\ea2e";
$gt-notebook-ip: "\ea2f";
$gt-notebook-lanconnectivity: "\ea30";
$gt-notebook-security: "\ea31";
$gt-notebook-share: "\ea32";
$gt-notebook: "\ea33";
$gt-notification: "\ea34";
$gt-ok: "\ea35";
$gt-online-billing: "\ea36";
$gt-open-security: "\ea37";
$gt-open-wifi: "\ea38";
$gt-order: "\ea39";
$gt-package-delivery: "\ea3a";
$gt-padlock: "\ea3b";
$gt-paquetigos: "\ea3c";
$gt-parentalcontrol: "\ea3d";
$gt-password-lock: "\ea3e";
$gt-passwordok-lockconfirmed: "\ea3f";
$gt-paused-call: "\ea40";
$gt-pay-merch: "\ea41";
$gt-payment: "\ea42";
$gt-pdf: "\ea43";
$gt-phone-history: "\ea44";
$gt-phone-settings: "\ea45";
$gt-picture: "\ea46";
$gt-pin-code: "\ea47";
$gt-placement: "\ea48";
$gt-plan-tvinternet-telephony: "\ea49";
$gt-plan-tvinternet: "\ea4a";
$gt-play-pause: "\ea4b";
$gt-pluscall: "\ea4c";
$gt-plusinternet: "\ea4d";
$gt-police: "\ea4e";
$gt-pos-posnet: "\ea4f";
$gt-premium-plan: "\ea50";
$gt-prioritydevice: "\ea51";
$gt-privatenetwork: "\ea52";
$gt-profilenotebook-user: "\ea53";
$gt-ptt-presstospeak: "\ea54";
$gt-qosmodem: "\ea55";
$gt-rec: "\ea56";
$gt-receive-banktranfer: "\ea57";
$gt-red: "\ea58";
$gt-registry: "\ea59";
$gt-report: "\ea5a";
$gt-roaming-large: "\ea5b";
$gt-roaming: "\ea5c";
$gt-row-bottom: "\ea5d";
$gt-row-left: "\ea5e";
$gt-row-rigth: "\ea5f";
$gt-row-top: "\ea60";
$gt-saas: "\ea61";
$gt-safetoschool: "\ea62";
$gt-sd: "\ea63";
$gt-search-contact: "\ea64";
$gt-search: "\ea65";
$gt-secure-pay: "\ea66";
$gt-security-call: "\ea67";
$gt-security-list: "\ea68";
$gt-security-pending: "\ea69";
$gt-security-telephony: "\ea6a";
$gt-security-wifi: "\ea6b";
$gt-security: "\ea6c";
$gt-send-banktransfer: "\ea6d";
$gt-send-top-up: "\ea6e";
$gt-server-checkok: "\ea6f";
$gt-server-list: "\ea70";
$gt-server-preferences: "\ea71";
$gt-server-settings: "\ea72";
$gt-server-user: "\ea73";
$gt-server: "\ea74";
$gt-servers: "\ea75";
$gt-service-diagnostic: "\ea76";
$gt-services: "\ea77";
$gt-settings: "\ea78";
$gt-shaking-hands: "\ea79";
$gt-shopping-cart-2: "\ea7a";
$gt-shopping-cart: "\ea7b";
$gt-signal-location: "\ea7c";
$gt-signal: "\ea7d";
$gt-sms-large: "\ea7e";
$gt-sms: "\ea7f";
$gt-special-services: "\ea80";
$gt-specs: "\ea81";
$gt-speedcalculator-timer: "\ea82";
$gt-star-circle: "\ea83";
$gt-star: "\ea84";
$gt-statistics: "\ea85";
$gt-store: "\ea86";
$gt-subscriptions: "\ea87";
$gt-target-server: "\ea88";
$gt-television-full-path1: "\ea89";
$gt-television-full-path2: "\ea8a";
$gt-television-outline: "\ea8b";
$gt-television: "\ea8c";
$gt-ticket-GO: "\ea8d";
$gt-ticket-money: "\ea8e";
$gt-ticketinformation: "\ea8f";
$gt-tigo-backup: "\ea90";
$gt-tigo-online: "\ea91";
$gt-timer: "\ea92";
$gt-top-up: "\ea93";
$gt-triple-play: "\ea94";
$gt-tv-3d: "\ea95";
$gt-tv-cable: "\ea96";
$gt-tv-decoder: "\ea97";
$gt-tv-digital: "\ea98";
$gt-tv-favoriteticket: "\ea99";
$gt-tv-guide: "\ea9a";
$gt-tv-hd: "\ea9b";
$gt-tv-list: "\ea9c";
$gt-tv-live: "\ea9d";
$gt-tv-movies: "\ea9e";
$gt-tv-multiroom: "\ea9f";
$gt-tv-recommender: "\eaa0";
$gt-tv-remotecontrol: "\eaa1";
$gt-tv-satellite: "\eaa2";
$gt-tv-sofa: "\eaa3";
$gt-tv-ticketvideo: "\eaa4";
$gt-tv-tigoplay: "\eaa5";
$gt-tv-videochannels: "\eaa6";
$gt-tv-vod: "\eaa7";
$gt-twitter-logo-outline: "\eaa8";
$gt-twitter-logo: "\eaa9";
$gt-unlimited-calls: "\eaaa";
$gt-unlimitedshippingpackages: "\eaab";
$gt-user-chat: "\eaac";
$gt-user-exit: "\eaad";
$gt-user-invalid: "\eaae";
$gt-user-logout: "\eaaf";
$gt-user-profile: "\eab0";
$gt-user-protection: "\eab1";
$gt-user-question: "\eab2";
$gt-user-valid: "\eab3";
$gt-user: "\eab4";
$gt-users: "\eab5";
$gt-velocity-speed: "\eab6";
$gt-video-surveillance: "\eab7";
$gt-virtual-servers: "\eab8";
$gt-voicemail: "\eab9";
$gt-volume: "\eaba";
$gt-waiting-call: "\eabb";
$gt-web-asset: "\eabc";
$gt-web-hosting: "\eabd";
$gt-widgets: "\eabe";
$gt-wifi-highsignal: "\eabf";
$gt-wifi-lowsignal: "\eac0";
$gt-wifi-mediumsignal: "\eac1";
$gt-world-server: "\eac2";
$gt-xls: "\eac3";
$gt-youtube-logo-outline: "\eac4";
$gt-youtube-logo: "\eac5";
$nt-4g-large: "\eac6";
$nt-4g-path1: "\eac7";
$nt-4g-path2: "\eac8";
$nt-account-status-path1: "\eac9";
$nt-account-status-path2: "\eaca";
$nt-add-balance-path1: "\eacb";
$nt-add-balance-path2: "\eacc";
$nt-add-card-path1: "\eacd";
$nt-add-card-path2: "\eace";
$nt-add-channels-path1: "\eacf";
$nt-add-channels-path2: "\ead0";
$nt-add-user-path1: "\ead1";
$nt-add-user-path2: "\ead2";
$nt-add: "\ead3";
$nt-advanced-solutions-outline-path1: "\ead4";
$nt-advanced-solutions-outline-path2: "\ead5";
$nt-advanced-solutions-path1: "\ead6";
$nt-advanced-solutions-path2: "\ead7";
$nt-agreement-path1: "\ead8";
$nt-agreement-path2: "\ead9";
$nt-analog-tv-path1: "\eada";
$nt-analog-tv-path2: "\eadb";
$nt-apn-path1: "\eadc";
$nt-apn-path2: "\eadd";
$nt-app-premium-path1: "\eade";
$nt-app-premium-path2: "\eadf";
$nt-app-store-path1: "\eae0";
$nt-app-store-path2: "\eae1";
$nt-apps-full-path1: "\eae2";
$nt-apps-full-path2: "\eae3";
$nt-apps-small: "\eae4";
$nt-apps: "\eae5";
$nt-at: "\eae6";
$nt-balance-large: "\eae7";
$nt-balance-path1: "\eae8";
$nt-balance-path2: "\eae9";
$nt-banktransfer-path1: "\eaea";
$nt-banktransfer-path2: "\eaeb";
$nt-bigdata-path1: "\eaec";
$nt-bigdata-path2: "\eaed";
$nt-billing-path1: "\eaee";
$nt-billing-path2: "\eaef";
$nt-billpayment_1-path1: "\eaf0";
$nt-billpayment_1-path2: "\eaf1";
$nt-billpayment-path1: "\eaf2";
$nt-billpayment-path2: "\eaf3";
$nt-broadband-wifimodem-path1: "\eaf4";
$nt-broadband-wifimodem-path2: "\eaf5";
$nt-building-path1: "\eaf6";
$nt-building-path2: "\eaf7";
$nt-bundle-path1: "\eaf8";
$nt-bundle-path2: "\eaf9";
$nt-calendar-money-path1: "\eafa";
$nt-calendar-money-path2: "\eafb";
$nt-calendar-timer-path1: "\eafc";
$nt-calendar-timer-path2: "\eafd";
$nt-calendar-path1: "\eafe";
$nt-calendar-path2: "\eaff";
$nt-call-rerouting-path1: "\eb00";
$nt-call-rerouting-path2: "\eb01";
$nt-call: "\eb02";
$nt-calls-large: "\eb03";
$nt-calls-path1: "\eb04";
$nt-calls-path2: "\eb05";
$nt-cash-path1: "\eb06";
$nt-cash-path2: "\eb07";
$nt-check-hexagon-path1: "\eb08";
$nt-check-hexagon-path2: "\eb09";
$nt-check: "\eb0a";
$nt-clear: "\eb0b";
$nt-clock-path1: "\eb0c";
$nt-clock-path2: "\eb0d";
$nt-closed-wifi-path1: "\eb0e";
$nt-closed-wifi-path2: "\eb0f";
$nt-cloud-solutions-outline-path1: "\eb10";
$nt-cloud-solutions-outline-path2: "\eb11";
$nt-cloud-solutions-path1: "\eb12";
$nt-cloud-solutions-path2: "\eb13";
$nt-cloud: "\eb14";
$nt-cloudbackup-path1: "\eb15";
$nt-cloudbackup-path2: "\eb16";
$nt-cloudfirewall-path1: "\eb17";
$nt-cloudfirewall-path2: "\eb18";
$nt-code: "\eb19";
$nt-complaints-path1: "\eb1a";
$nt-complaints-path2: "\eb1b";
$nt-conference-call-path1: "\eb1c";
$nt-conference-call-path2: "\eb1d";
$nt-connectivity-01-path1: "\eb1e";
$nt-connectivity-01-path2: "\eb1f";
$nt-connectivity-solutions-outline-path1: "\eb20";
$nt-connectivity-solutions-outline-path2: "\eb21";
$nt-connectivity-solutions-path1: "\eb22";
$nt-connectivity-solutions-path2: "\eb23";
$nt-connectivity-path1: "\eb24";
$nt-connectivity-path2: "\eb25";
$nt-contac-backup-path1: "\eb26";
$nt-contac-backup-path2: "\eb27";
$nt-contac-list-path1: "\eb28";
$nt-contac-list-path2: "\eb29";
$nt-contact-backup-path1: "\eb2a";
$nt-contact-backup-path2: "\eb2b";
$nt-contact-phone-path1: "\eb2c";
$nt-contact-phone-path2: "\eb2d";
$nt-convergence-tvinternet-path1: "\eb2e";
$nt-convergence-tvinternet-path2: "\eb2f";
$nt-convergence-tvinternet-path3: "\eb30";
$nt-convergence-path1: "\eb31";
$nt-convergence-path2: "\eb32";
$nt-corporativenoc-path1: "\eb33";
$nt-corporativenoc-path2: "\eb34";
$nt-Credentialbadge-path1: "\eb35";
$nt-Credentialbadge-path2: "\eb36";
$nt-customer-service-path1: "\eb37";
$nt-customer-service-path2: "\eb38";
$nt-datacenters-colocation-path1: "\eb39";
$nt-datacenters-colocation-path2: "\eb3a";
$nt-dedicatedinternet-path1: "\eb3b";
$nt-dedicatedinternet-path2: "\eb3c";
$nt-delivery-path1: "\eb3d";
$nt-delivery-path2: "\eb3e";
$nt-dial: "\eb3f";
$nt-discount-alternate-path1: "\eb40";
$nt-discount-alternate-path2: "\eb41";
$nt-discount-path1: "\eb42";
$nt-discount-path2: "\eb43";
$nt-dislike-path1: "\eb44";
$nt-dislike-path2: "\eb45";
$nt-dmzmodem-path1: "\eb46";
$nt-dmzmodem-path2: "\eb47";
$nt-download-path1: "\eb48";
$nt-download-path2: "\eb49";
$nt-edit-path1: "\eb4a";
$nt-edit-path2: "\eb4b";
$nt-error: "\eb4c";
$nt-expand-more: "\eb4d";
$nt-family-plan-path1: "\eb4e";
$nt-family-plan-path2: "\eb4f";
$nt-faq: "\eb50";
$nt-file-interactive-path1: "\eb51";
$nt-file-interactive-path2: "\eb52";
$nt-file-list-path1: "\eb53";
$nt-file-list-path2: "\eb54";
$nt-file-listok-path1: "\eb55";
$nt-file-listok-path2: "\eb56";
$nt-file-refresh-path1: "\eb57";
$nt-file-refresh-path2: "\eb58";
$nt-file-time-path1: "\eb59";
$nt-file-time-path2: "\eb5a";
$nt-file-path1: "\eb5b";
$nt-file-path2: "\eb5c";
$nt-filedhcp-path1: "\eb5d";
$nt-filedhcp-path2: "\eb5e";
$nt-firewall-path1: "\eb5f";
$nt-firewall-path2: "\eb60";
$nt-fixed-line-path1: "\eb61";
$nt-fixed-line-path2: "\eb62";
$nt-friends-path1: "\eb63";
$nt-friends-path2: "\eb64";
$nt-geolocation-small-path1: "\eb65";
$nt-geolocation-small-path2: "\eb66";
$nt-geolocation-path1: "\eb67";
$nt-geolocation-path2: "\eb68";
$nt-go-path1: "\eb69";
$nt-go-path2: "\eb6a";
$nt-googleapps-path1: "\eb6b";
$nt-googleapps-path2: "\eb6c";
$nt-guestnetwork-path1: "\eb6d";
$nt-guestnetwork-path2: "\eb6e";
$nt-hand-up-path1: "\eb6f";
$nt-hand-up-path2: "\eb70";
$nt-help-outline-path1: "\eb71";
$nt-help-outline-path2: "\eb72";
$nt-home: "\eb73";
$nt-hpdcloud-path1: "\eb74";
$nt-hpdcloud-path2: "\eb75";
$nt-idea-light-path1: "\eb76";
$nt-idea-light-path2: "\eb77";
$nt-imei-path1: "\eb78";
$nt-imei-path2: "\eb79";
$nt-incoming-call-path1: "\eb7a";
$nt-incoming-call-path2: "\eb7b";
$nt-incoming-outgoing-call-B-path1: "\eb7c";
$nt-incoming-outgoing-call-B-path2: "\eb7d";
$nt-incoming-outgoing-call-path1: "\eb7e";
$nt-incoming-outgoing-call-path2: "\eb7f";
$nt-info-full-path1: "\eb80";
$nt-info-full-path2: "\eb81";
$nt-info: "\eb82";
$nt-internet-connection: "\eb83";
$nt-internet-outline-path1: "\eb84";
$nt-internet-outline-path2: "\eb85";
$nt-internet-security-path1: "\eb86";
$nt-internet-security-path2: "\eb87";
$nt-internet-path1: "\eb88";
$nt-internet-path2: "\eb89";
$nt-invite: "\eb8a";
$nt-ip-calling-path1: "\eb8b";
$nt-ip-calling-path2: "\eb8c";
$nt-key-money-path1: "\eb8d";
$nt-key-money-path2: "\eb8e";
$nt-key-number-path1: "\eb8f";
$nt-key-number-path2: "\eb90";
$nt-landline-path1: "\eb91";
$nt-landline-path2: "\eb92";
$nt-lend-me-path1: "\eb93";
$nt-lend-me-path2: "\eb94";
$nt-less: "\eb95";
$nt-line-800-path1: "\eb96";
$nt-line-800-path2: "\eb97";
$nt-linkdata-path1: "\eb98";
$nt-linkdata-path2: "\eb99";
$nt-list-path1: "\eb9a";
$nt-list-path2: "\eb9b";
$nt-local-remitances-path1: "\eb9c";
$nt-local-remitances-path2: "\eb9d";
$nt-location-ruler-path1: "\eb9e";
$nt-location-ruler-path2: "\eb9f";
$nt-long-distance-calls-path1: "\eba0";
$nt-long-distance-calls-path2: "\eba1";
$nt-m2m-machinetomachine-path1: "\eba2";
$nt-m2m-machinetomachine-path2: "\eba3";
$nt-mail-server-path1: "\eba4";
$nt-mail-server-path2: "\eba5";
$nt-menu-more: "\eba6";
$nt-menu: "\eba7";
$nt-message-read-path1: "\eba8";
$nt-message-read-path2: "\eba9";
$nt-mobile-balance01: "\ebaa";
$nt-mobile-balance02: "\ebab";
$nt-mobile-data-large: "\ebac";
$nt-mobile-data-path1: "\ebad";
$nt-mobile-data-path2: "\ebae";
$nt-mobile-extrabalance-path1: "\ebaf";
$nt-mobile-extrabalance-path2: "\ebb0";
$nt-mobile-outline-path1: "\ebb1";
$nt-mobile-outline-path2: "\ebb2";
$nt-mobile-phone: "\ebb3";
$nt-mobile-promotionalbalance-path1: "\ebb4";
$nt-mobile-promotionalbalance-path2: "\ebb5";
$nt-mobile-wifi-path1: "\ebb6";
$nt-mobile-wifi-path2: "\ebb7";
$nt-mobile-path1: "\ebb8";
$nt-mobile-path2: "\ebb9";
$nt-mobileinternet-path1: "\ebba";
$nt-mobileinternet-path2: "\ebbb";
$nt-more-calls: "\ebbc";
$nt-more: "\ebbd";
$nt-multipledevices-path1: "\ebbe";
$nt-multipledevices-path2: "\ebbf";
$nt-multipledevices-path3: "\ebc0";
$nt-natmodem-path1: "\ebc1";
$nt-natmodem-path2: "\ebc2";
$nt-networkmonitoring-path1: "\ebc3";
$nt-networkmonitoring-path2: "\ebc4";
$nt-notebook-calendar-path1: "\ebc5";
$nt-notebook-calendar-path2: "\ebc6";
$nt-notebook-connectivity-path1: "\ebc7";
$nt-notebook-connectivity-path2: "\ebc8";
$nt-notebook-ip-path1: "\ebc9";
$nt-notebook-ip-path2: "\ebca";
$nt-notebook-lanconnectivity-path1: "\ebcb";
$nt-notebook-lanconnectivity-path2: "\ebcc";
$nt-notebook-security-path1: "\ebcd";
$nt-notebook-security-path2: "\ebce";
$nt-notebook-share-path1: "\ebcf";
$nt-notebook-share-path2: "\ebd0";
$nt-notebook-path1: "\ebd1";
$nt-notebook-path2: "\ebd2";
$nt-notification: "\ebd3";
$nt-office365-path1: "\ebd4";
$nt-office365-path2: "\ebd5";
$nt-ok-path1: "\ebd6";
$nt-ok-path2: "\ebd7";
$nt-online-billing-path1: "\ebd8";
$nt-online-billing-path2: "\ebd9";
$nt-open-security-path1: "\ebda";
$nt-open-security-path2: "\ebdb";
$nt-open-wifi-path1: "\ebdc";
$nt-open-wifi-path2: "\ebdd";
$nt-order-path1: "\ebde";
$nt-order-path2: "\ebdf";
$nt-package-delivery-path1: "\ebe0";
$nt-package-delivery-path2: "\ebe1";
$nt-padlock-path1: "\ebe2";
$nt-padlock-path2: "\ebe3";
$nt-paquetigos-path1: "\ebe4";
$nt-paquetigos-path2: "\ebe5";
$nt-parentalcontrol-path1: "\ebe6";
$nt-parentalcontrol-path2: "\ebe7";
$nt-password-lock-path1: "\ebe8";
$nt-password-lock-path2: "\ebe9";
$nt-passwordok-lockconfirmed-path1: "\ebea";
$nt-passwordok-lockconfirmed-path2: "\ebeb";
$nt-paused-call-path1: "\ebec";
$nt-paused-call-path2: "\ebed";
$nt-pay-merch-path1: "\ebee";
$nt-pay-merch-path2: "\ebef";
$nt-payment-path1: "\ebf0";
$nt-payment-path2: "\ebf1";
$nt-pdf-path1: "\ebf2";
$nt-pdf-path2: "\ebf3";
$nt-phone-history-path1: "\ebf4";
$nt-phone-history-path2: "\ebf5";
$nt-phone-settings-path1: "\ebf6";
$nt-phone-settings-path2: "\ebf7";
$nt-picture-path1: "\ebf8";
$nt-picture-path2: "\ebf9";
$nt-pin-code: "\ebfa";
$nt-placement-path1: "\ebfb";
$nt-placement-path2: "\ebfc";
$nt-plan-tvinternet-telephony-path1: "\ebfd";
$nt-plan-tvinternet-telephony-path2: "\ebfe";
$nt-plan-tvinternet-path1: "\ebff";
$nt-plan-tvinternet-path2: "\ec00";
$nt-play-pause-path1: "\ec01";
$nt-play-pause-path2: "\ec02";
$nt-pluscall-path1: "\ec03";
$nt-pluscall-path2: "\ec04";
$nt-plusinternet: "\ec05";
$nt-police-path1: "\ec06";
$nt-police-path2: "\ec07";
$nt-pos-posnet-path1: "\ec08";
$nt-pos-posnet-path2: "\ec09";
$nt-premium-plan-path1: "\ec0a";
$nt-premium-plan-path2: "\ec0b";
$nt-prioritydevice-path1: "\ec0c";
$nt-prioritydevice-path2: "\ec0d";
$nt-privatenetwork-path1: "\ec0e";
$nt-privatenetwork-path2: "\ec0f";
$nt-profilenotebook-user-path1: "\ec10";
$nt-profilenotebook-user-path2: "\ec11";
$nt-ptt-presstospeak-path1: "\ec12";
$nt-ptt-presstospeak-path2: "\ec13";
$nt-qosmodem-path1: "\ec14";
$nt-qosmodem-path2: "\ec15";
$nt-rec-path1: "\ec16";
$nt-rec-path2: "\ec17";
$nt-receive-banktranfer-path1: "\ec18";
$nt-receive-banktranfer-path2: "\ec19";
$nt-red-path1: "\ec1a";
$nt-red-path2: "\ec1b";
$nt-registry-path1: "\ec1c";
$nt-registry-path2: "\ec1d";
$nt-report-path1: "\ec1e";
$nt-report-path2: "\ec1f";
$nt-roaming-large: "\ec20";
$nt-roaming-path1: "\ec21";
$nt-roaming-path2: "\ec22";
$nt-row-bottom: "\ec23";
$nt-row-left: "\ec24";
$nt-row-rigth: "\ec25";
$nt-row-top: "\ec26";
$nt-saas-path1: "\ec27";
$nt-saas-path2: "\ec28";
$nt-safetoschool-path1: "\ec29";
$nt-safetoschool-path2: "\ec2a";
$nt-sd-path1: "\ec2b";
$nt-sd-path2: "\ec2c";
$nt-search-contact-path1: "\ec2d";
$nt-search-contact-path2: "\ec2e";
$nt-search: "\ec2f";
$nt-secure-pay: "\ec30";
$nt-security-call-path1: "\ec31";
$nt-security-call-path2: "\ec32";
$nt-security-list-path1: "\ec33";
$nt-security-list-path2: "\ec34";
$nt-security-pending-path1: "\ec35";
$nt-security-pending-path2: "\ec36";
$nt-security-telephony-path1: "\ec37";
$nt-security-telephony-path2: "\ec38";
$nt-security-wifi-path1: "\ec39";
$nt-security-wifi-path2: "\ec3a";
$nt-security-path1: "\ec3b";
$nt-security-path2: "\ec3c";
$nt-send-banktransfer-path1: "\ec3d";
$nt-send-banktransfer-path2: "\ec3e";
$nt-send-top-up-path1: "\ec3f";
$nt-send-top-up-path2: "\ec40";
$nt-server-checkok-path1: "\ec41";
$nt-server-checkok-path2: "\ec42";
$nt-server-list-path1: "\ec43";
$nt-server-list-path2: "\ec44";
$nt-server-preferences-path1: "\ec45";
$nt-server-preferences-path2: "\ec46";
$nt-server-settings-path1: "\ec47";
$nt-server-settings-path2: "\ec48";
$nt-server-user-path1: "\ec49";
$nt-server-user-path2: "\ec4a";
$nt-server-path1: "\ec4b";
$nt-server-path2: "\ec4c";
$nt-servers-path1: "\ec4d";
$nt-servers-path2: "\ec4e";
$nt-service-diagnostic-path1: "\ec4f";
$nt-service-diagnostic-path2: "\ec50";
$nt-services-path1: "\ec51";
$nt-services-path2: "\ec52";
$nt-settings: "\ec53";
$nt-shaking-hands-path1: "\ec54";
$nt-shaking-hands-path2: "\ec55";
$nt-shopping-cart-2: "\ec56";
$nt-shopping-cart-path1: "\ec57";
$nt-shopping-cart-path2: "\ec58";
$nt-signal-location-path1: "\ec59";
$nt-signal-location-path2: "\ec5a";
$nt-signal-path1: "\ec5b";
$nt-signal-path2: "\ec5c";
$nt-sms-large: "\ec5d";
$nt-sms-path1: "\ec5e";
$nt-sms-path2: "\ec5f";
$nt-special-services-path1: "\ec60";
$nt-special-services-path2: "\ec61";
$nt-specs-path1: "\ec62";
$nt-specs-path2: "\ec63";
$nt-speedcalculator-timer-path1: "\ec64";
$nt-speedcalculator-timer-path2: "\ec65";
$nt-star-circle-path1: "\ec66";
$nt-star-circle-path2: "\ec67";
$nt-star: "\ec68";
$nt-statistics-path1: "\ec69";
$nt-statistics-path2: "\ec6a";
$nt-store-path1: "\ec6b";
$nt-store-path2: "\ec6c";
$nt-subscriptions-path1: "\ec6d";
$nt-subscriptions-path2: "\ec6e";
$nt-target-server-path1: "\ec6f";
$nt-target-server-path2: "\ec70";
$nt-television-full-path1: "\ec71";
$nt-television-full-path2: "\ec72";
$nt-television-outline-path1: "\ec73";
$nt-television-outline-path2: "\ec74";
$nt-television: "\ec75";
$nt-ticket-GO-path1: "\ec76";
$nt-ticket-GO-path2: "\ec77";
$nt-ticket-money-path1: "\ec78";
$nt-ticket-money-path2: "\ec79";
$nt-ticketinformation-path1: "\ec7a";
$nt-ticketinformation-path2: "\ec7b";
$nt-tigo-backup-path1: "\ec7c";
$nt-tigo-backup-path2: "\ec7d";
$nt-tigo-online-path1: "\ec7e";
$nt-tigo-online-path2: "\ec7f";
$nt-timer-path1: "\ec80";
$nt-timer-path2: "\ec81";
$nt-top-up-path1: "\ec82";
$nt-top-up-path2: "\ec83";
$nt-triple-play-path1: "\ec84";
$nt-triple-play-path2: "\ec85";
$nt-tv-3d-path1: "\ec86";
$nt-tv-3d-path2: "\ec87";
$nt-tv-cable: "\ec88";
$nt-tv-decoder-path1: "\ec89";
$nt-tv-decoder-path2: "\ec8a";
$nt-tv-digital-path1: "\ec8b";
$nt-tv-digital-path2: "\ec8c";
$nt-tv-favoriteticket-path1: "\ec8d";
$nt-tv-favoriteticket-path2: "\ec8e";
$nt-tv-guide-path1: "\ec8f";
$nt-tv-guide-path2: "\ec90";
$nt-tv-hd-path1: "\ec91";
$nt-tv-hd-path2: "\ec92";
$nt-tv-list-path1: "\ec93";
$nt-tv-list-path2: "\ec94";
$nt-tv-live-path1: "\ec95";
$nt-tv-live-path2: "\ec96";
$nt-tv-movies: "\ec97";
$nt-tv-multiroom-path1: "\ec98";
$nt-tv-multiroom-path2: "\ec99";
$nt-tv-recommender-path1: "\ec9a";
$nt-tv-recommender-path2: "\ec9b";
$nt-tv-remotecontrol-path1: "\ec9c";
$nt-tv-remotecontrol-path2: "\ec9d";
$nt-tv-satellite-path1: "\ec9e";
$nt-tv-satellite-path2: "\ec9f";
$nt-tv-sofa-path1: "\eca0";
$nt-tv-sofa-path2: "\eca1";
$nt-tv-ticketvideo-path1: "\eca2";
$nt-tv-ticketvideo-path2: "\eca3";
$nt-tv-tigoplay-path1: "\eca4";
$nt-tv-tigoplay-path2: "\eca5";
$nt-tv-videochannels-path1: "\eca6";
$nt-tv-videochannels-path2: "\eca7";
$nt-tv-vod-path1: "\eca8";
$nt-tv-vod-path2: "\eca9";
$nt-unlimited-calls-path1: "\ecaa";
$nt-unlimited-calls-path2: "\ecab";
$nt-unlimitedshippingpackages-path1: "\ecac";
$nt-unlimitedshippingpackages-path2: "\ecad";
$nt-user-chat-path1: "\ecae";
$nt-user-chat-path2: "\ecaf";
$nt-user-exit-path1: "\ecb0";
$nt-user-exit-path2: "\ecb1";
$nt-user-invalid-path1: "\ecb2";
$nt-user-invalid-path2: "\ecb3";
$nt-user-logout-path1: "\ecb4";
$nt-user-logout-path2: "\ecb5";
$nt-user-profile: "\ecb6";
$nt-user-protection-path1: "\ecb7";
$nt-user-protection-path2: "\ecb8";
$nt-user-question-path1: "\ecb9";
$nt-user-question-path2: "\ecba";
$nt-user-valid-path1: "\ecbb";
$nt-user-valid-path2: "\ecbc";
$nt-user: "\ecbd";
$nt-users-path1: "\ecbe";
$nt-users-path2: "\ecbf";
$nt-velocity-speed-path1: "\ecc0";
$nt-velocity-speed-path2: "\ecc1";
$nt-video-surveillance-path1: "\ecc2";
$nt-video-surveillance-path2: "\ecc3";
$nt-virtual-servers-path1: "\ecc4";
$nt-virtual-servers-path2: "\ecc5";
$nt-voicemail-path1: "\ecc6";
$nt-voicemail-path2: "\ecc7";
$nt-volume-path1: "\ecc8";
$nt-volume-path2: "\ecc9";
$nt-waiting-call-path1: "\ecca";
$nt-waiting-call-path2: "\eccb";
$nt-web-asset-path1: "\eccc";
$nt-web-asset-path2: "\eccd";
$nt-web-hosting-path1: "\ecce";
$nt-web-hosting-path2: "\eccf";
$nt-widgets-path1: "\ecd0";
$nt-widgets-path2: "\ecd1";
$nt-wifi-highsignal: "\ecd2";
$nt-wifi-lowsignal-path1: "\ecd3";
$nt-wifi-lowsignal-path2: "\ecd4";
$nt-wifi-mediumsignal-path1: "\ecd5";
$nt-wifi-mediumsignal-path2: "\ecd6";
$nt-world-server-path1: "\ecd7";
$nt-world-server-path2: "\ecd8";
$nt-xls-path1: "\ecd9";
$nt-xls-path2: "\ecda";
$pt-4g-large: "\ecdb";
$pt-4g-path1: "\ecdc";
$pt-4g-path2: "\ecdd";
$pt-account-status-path1: "\ecde";
$pt-account-status-path2: "\ecdf";
$pt-add-balance-path1: "\ece0";
$pt-add-balance-path2: "\ece1";
$pt-add-card-path1: "\ece2";
$pt-add-card-path2: "\ece3";
$pt-add-channels-path1: "\ece4";
$pt-add-channels-path2: "\ece5";
$pt-add-user-path1: "\ece6";
$pt-add-user-path2: "\ece7";
$pt-add: "\ece8";
$pt-advanced-solutions-outline-path1: "\ece9";
$pt-advanced-solutions-outline-path2: "\ecea";
$pt-advanced-solutions-path1: "\eceb";
$pt-advanced-solutions-path2: "\ecec";
$pt-agreement-path1: "\eced";
$pt-agreement-path2: "\ecee";
$pt-analog-tv-path1: "\ecef";
$pt-analog-tv-path2: "\ecf0";
$pt-apn-path1: "\ecf1";
$pt-apn-path2: "\ecf2";
$pt-app-premium-path1: "\ecf3";
$pt-app-premium-path2: "\ecf4";
$pt-app-store-path1: "\ecf5";
$pt-app-store-path2: "\ecf6";
$pt-apps-full-path1: "\ecf7";
$pt-apps-full-path2: "\ecf8";
$pt-apps-small: "\ecf9";
$pt-apps: "\ecfa";
$pt-at: "\ecfb";
$pt-balance-large: "\ecfc";
$pt-balance-path1: "\ecfd";
$pt-balance-path2: "\ecfe";
$pt-banktransfer-path1: "\ecff";
$pt-banktransfer-path2: "\ed00";
$pt-bigdata-path1: "\ed01";
$pt-bigdata-path2: "\ed02";
$pt-billing-path1: "\ed03";
$pt-billing-path2: "\ed04";
$pt-billpayment_1-path1: "\ed05";
$pt-billpayment_1-path2: "\ed06";
$pt-billpayment-path1: "\ed07";
$pt-billpayment-path2: "\ed08";
$pt-broadband-wifimodem-path1: "\ed09";
$pt-broadband-wifimodem-path2: "\ed0a";
$pt-building-path1: "\ed0b";
$pt-building-path2: "\ed0c";
$pt-bundle-path1: "\ed0d";
$pt-bundle-path2: "\ed0e";
$pt-calendar-money-path1: "\ed0f";
$pt-calendar-money-path2: "\ed10";
$pt-calendar-timer-path1: "\ed11";
$pt-calendar-timer-path2: "\ed12";
$pt-calendar-path1: "\ed13";
$pt-calendar-path2: "\ed14";
$pt-call-rerouting-path1: "\ed15";
$pt-call-rerouting-path2: "\ed16";
$pt-call: "\ed17";
$pt-calls-large: "\ed18";
$pt-calls-path1: "\ed19";
$pt-calls-path2: "\ed1a";
$pt-cash-path1: "\ed1b";
$pt-cash-path2: "\ed1c";
$pt-check-hexagon-path1: "\ed1d";
$pt-check-hexagon-path2: "\ed1e";
$pt-check: "\ed1f";
$pt-clear: "\ed20";
$pt-clock-path1: "\ed21";
$pt-clock-path2: "\ed22";
$pt-closed-wifi-path1: "\ed23";
$pt-closed-wifi-path2: "\ed24";
$pt-cloud-solutions-outline-path1: "\ed25";
$pt-cloud-solutions-outline-path2: "\ed26";
$pt-cloud-solutions-path1: "\ed27";
$pt-cloud-solutions-path2: "\ed28";
$pt-cloud: "\ed29";
$pt-cloudbackup-path1: "\ed2a";
$pt-cloudbackup-path2: "\ed2b";
$pt-cloudfirewall-path1: "\ed2c";
$pt-cloudfirewall-path2: "\ed2d";
$pt-code: "\ed2e";
$pt-complaints-path1: "\ed2f";
$pt-complaints-path2: "\ed30";
$pt-conference-call-path1: "\ed31";
$pt-conference-call-path2: "\ed32";
$pt-connectivity-01-path1: "\ed33";
$pt-connectivity-01-path2: "\ed34";
$pt-connectivity-solutions-outline-path1: "\ed35";
$pt-connectivity-solutions-outline-path2: "\ed36";
$pt-connectivity-solutions-path1: "\ed37";
$pt-connectivity-solutions-path2: "\ed38";
$pt-connectivity-path1: "\ed39";
$pt-connectivity-path2: "\ed3a";
$pt-contac-backup-path1: "\ed3b";
$pt-contac-backup-path2: "\ed3c";
$pt-contac-list-path1: "\ed3d";
$pt-contac-list-path2: "\ed3e";
$pt-contact-backup-path1: "\ed3f";
$pt-contact-backup-path2: "\ed40";
$pt-contact-phone-path1: "\ed41";
$pt-contact-phone-path2: "\ed42";
$pt-convergence-tvinternet-path1: "\ed43";
$pt-convergence-tvinternet-path2: "\ed44";
$pt-convergence-tvinternet-path3: "\ed45";
$pt-convergence-path1: "\ed46";
$pt-convergence-path2: "\ed47";
$pt-corporativenoc-path1: "\ed48";
$pt-corporativenoc-path2: "\ed49";
$pt-Credentialbadge-path1: "\ed4a";
$pt-Credentialbadge-path2: "\ed4b";
$pt-customer-service-path1: "\ed4c";
$pt-customer-service-path2: "\ed4d";
$pt-datacenters-colocation-path1: "\ed4e";
$pt-datacenters-colocation-path2: "\ed4f";
$pt-dedicatedinternet-path1: "\ed50";
$pt-dedicatedinternet-path2: "\ed51";
$pt-delivery-path1: "\ed52";
$pt-delivery-path2: "\ed53";
$pt-dial: "\ed54";
$pt-discount-alternate-path1: "\ed55";
$pt-discount-alternate-path2: "\ed56";
$pt-discount-path1: "\ed57";
$pt-discount-path2: "\ed58";
$pt-dislike-path1: "\ed59";
$pt-dislike-path2: "\ed5a";
$pt-dmzmodem-path1: "\ed5b";
$pt-dmzmodem-path2: "\ed5c";
$pt-doublearrow-left-path1: "\ed5d";
$pt-doublearrow-left-path2: "\ed5e";
$pt-doublearrow-right-path1: "\ed5f";
$pt-doublearrow-right-path2: "\ed60";
$pt-download-path1: "\ed61";
$pt-download-path2: "\ed62";
$pt-edit-path1: "\ed63";
$pt-edit-path2: "\ed64";
$pt-error-circle: "\ed65";
$pt-error: "\ed66";
$pt-expand-more: "\ed67";
$pt-family-plan-path1: "\ed68";
$pt-family-plan-path2: "\ed69";
$pt-faq: "\ed6a";
$pt-file-interactive-path1: "\ed6b";
$pt-file-interactive-path2: "\ed6c";
$pt-file-list-path1: "\ed6d";
$pt-file-list-path2: "\ed6e";
$pt-file-listok-path1: "\ed6f";
$pt-file-listok-path2: "\ed70";
$pt-file-refresh-path1: "\ed71";
$pt-file-refresh-path2: "\ed72";
$pt-file-time-path1: "\ed73";
$pt-file-time-path2: "\ed74";
$pt-file-path1: "\ed75";
$pt-file-path2: "\ed76";
$pt-filedhcp-path1: "\ed77";
$pt-filedhcp-path2: "\ed78";
$pt-firewall-path1: "\ed79";
$pt-firewall-path2: "\ed7a";
$pt-fixed-line-path1: "\ed7b";
$pt-fixed-line-path2: "\ed7c";
$pt-friends-path1: "\ed7d";
$pt-friends-path2: "\ed7e";
$pt-geolocation-small-path1: "\ed7f";
$pt-geolocation-small-path2: "\ed80";
$pt-geolocation-path1: "\ed81";
$pt-geolocation-path2: "\ed82";
$pt-go-path1: "\ed83";
$pt-go-path2: "\ed84";
$pt-googleapps-path1: "\ed85";
$pt-googleapps-path2: "\ed86";
$pt-guestnetwork-path1: "\ed87";
$pt-guestnetwork-path2: "\ed88";
$pt-hand-up-path1: "\ed89";
$pt-hand-up-path2: "\ed8a";
$pt-help-outline-path1: "\ed8b";
$pt-help-outline-path2: "\ed8c";
$pt-home: "\ed8d";
$pt-hpdcloud-path1: "\ed8e";
$pt-hpdcloud-path2: "\ed8f";
$pt-idea-light-path1: "\ed90";
$pt-idea-light-path2: "\ed91";
$pt-imei-path1: "\ed92";
$pt-imei-path2: "\ed93";
$pt-incoming-call-path1: "\ed94";
$pt-incoming-call-path2: "\ed95";
$pt-incoming-outgoing-call-B-path1: "\ed96";
$pt-incoming-outgoing-call-B-path2: "\ed97";
$pt-incoming-outgoing-call-path1: "\ed98";
$pt-incoming-outgoing-call-path2: "\ed99";
$pt-info-full-path1: "\ed9a";
$pt-info-full-path2: "\ed9b";
$pt-info: "\ed9c";
$pt-internet-connection: "\ed9d";
$pt-internet-outline-path1: "\ed9e";
$pt-internet-outline-path2: "\ed9f";
$pt-internet-security-path1: "\eda0";
$pt-internet-security-path2: "\eda1";
$pt-internet-path1: "\eda2";
$pt-internet-path2: "\eda3";
$pt-invite: "\eda4";
$pt-ip-calling-path1: "\eda5";
$pt-ip-calling-path2: "\eda6";
$pt-key-money-path1: "\eda7";
$pt-key-money-path2: "\eda8";
$pt-key-number-path1: "\eda9";
$pt-key-number-path2: "\edaa";
$pt-landline-path1: "\edab";
$pt-landline-path2: "\edac";
$pt-lend-me-path1: "\edad";
$pt-lend-me-path2: "\edae";
$pt-less: "\edaf";
$pt-line-800-path1: "\edb0";
$pt-line-800-path2: "\edb1";
$pt-linkdata-path1: "\edb2";
$pt-linkdata-path2: "\edb3";
$pt-list-path1: "\edb4";
$pt-list-path2: "\edb5";
$pt-local-remitances-path1: "\edb6";
$pt-local-remitances-path2: "\edb7";
$pt-location-ruler-path1: "\edb8";
$pt-location-ruler-path2: "\edb9";
$pt-long-distance-calls-path1: "\edba";
$pt-long-distance-calls-path2: "\edbb";
$pt-m2m-machinetomachine-path1: "\edbc";
$pt-m2m-machinetomachine-path2: "\edbd";
$pt-mail-server-path1: "\edbe";
$pt-mail-server-path2: "\edbf";
$pt-menu-more: "\edc0";
$pt-menu: "\edc1";
$pt-message-read-path1: "\edc2";
$pt-message-read-path2: "\edc3";
$pt-mobile-balance01: "\edc4";
$pt-mobile-balance02: "\edc5";
$pt-mobile-data-large: "\edc6";
$pt-mobile-data-path1: "\edc7";
$pt-mobile-data-path2: "\edc8";
$pt-mobile-extrabalance-path1: "\edc9";
$pt-mobile-extrabalance-path2: "\edca";
$pt-mobile-outline-path1: "\edcb";
$pt-mobile-outline-path2: "\edcc";
$pt-mobile-phone: "\edcd";
$pt-mobile-promotionalbalance-path1: "\edce";
$pt-mobile-promotionalbalance-path2: "\edcf";
$pt-mobile-wifi-path1: "\edd0";
$pt-mobile-wifi-path2: "\edd1";
$pt-mobile-path1: "\edd2";
$pt-mobile-path2: "\edd3";
$pt-mobileinternet-path1: "\edd4";
$pt-mobileinternet-path2: "\edd5";
$pt-more-calls: "\edd6";
$pt-more: "\edd7";
$pt-multipledevices-path1: "\edd8";
$pt-multipledevices-path2: "\edd9";
$pt-multipledevices-path3: "\edda";
$pt-natmodem-path1: "\eddb";
$pt-natmodem-path2: "\eddc";
$pt-networkmonitoring-path1: "\eddd";
$pt-networkmonitoring-path2: "\edde";
$pt-notebook-calendar-path1: "\eddf";
$pt-notebook-calendar-path2: "\ede0";
$pt-notebook-connectivity-path1: "\ede1";
$pt-notebook-connectivity-path2: "\ede2";
$pt-notebook-ip-path1: "\ede3";
$pt-notebook-ip-path2: "\ede4";
$pt-notebook-lanconnectivity-path1: "\ede5";
$pt-notebook-lanconnectivity-path2: "\ede6";
$pt-notebook-security-path1: "\ede7";
$pt-notebook-security-path2: "\ede8";
$pt-notebook-share-path1: "\ede9";
$pt-notebook-share-path2: "\edea";
$pt-notebook-path1: "\edeb";
$pt-notebook-path2: "\edec";
$pt-notification: "\eded";
$pt-ok-path1: "\edee";
$pt-ok-path2: "\edef";
$pt-online-billing-path1: "\edf0";
$pt-online-billing-path2: "\edf1";
$pt-open-security-path1: "\edf2";
$pt-open-security-path2: "\edf3";
$pt-open-wifi-path1: "\edf4";
$pt-open-wifi-path2: "\edf5";
$pt-order-path1: "\edf6";
$pt-order-path2: "\edf7";
$pt-package-delivery-path1: "\edf8";
$pt-package-delivery-path2: "\edf9";
$pt-padlock-path1: "\edfa";
$pt-padlock-path2: "\edfb";
$pt-paquetigos-path1: "\edfc";
$pt-paquetigos-path2: "\edfd";
$pt-parentalcontrol-path1: "\edfe";
$pt-parentalcontrol-path2: "\edff";
$pt-password-lock-path1: "\ee00";
$pt-password-lock-path2: "\ee01";
$pt-passwordok-lockconfirmed-path1: "\ee02";
$pt-passwordok-lockconfirmed-path2: "\ee03";
$pt-paused-call-path1: "\ee04";
$pt-paused-call-path2: "\ee05";
$pt-pay-merch-path1: "\ee06";
$pt-pay-merch-path2: "\ee07";
$pt-payment-path1: "\ee08";
$pt-payment-path2: "\ee09";
$pt-pdf-path1: "\ee0a";
$pt-pdf-path2: "\ee0b";
$pt-phone-history-path1: "\ee0c";
$pt-phone-history-path2: "\ee0d";
$pt-phone-settings-path1: "\ee0e";
$pt-phone-settings-path2: "\ee0f";
$pt-picture-path1: "\ee10";
$pt-picture-path2: "\ee11";
$pt-pin-code: "\ee12";
$pt-placement-path1: "\ee13";
$pt-placement-path2: "\ee14";
$pt-plan-tvinternet-telephony-path1: "\ee15";
$pt-plan-tvinternet-telephony-path2: "\ee16";
$pt-plan-tvinternet-path1: "\ee17";
$pt-plan-tvinternet-path2: "\ee18";
$pt-play-pause-path1: "\ee19";
$pt-play-pause-path2: "\ee1a";
$pt-pluscall-path1: "\ee1b";
$pt-pluscall-path2: "\ee1c";
$pt-plusinternet: "\ee1d";
$pt-police-path1: "\ee1e";
$pt-police-path2: "\ee1f";
$pt-pos-posnet-path1: "\ee20";
$pt-pos-posnet-path2: "\ee21";
$pt-premium-plan-path1: "\ee22";
$pt-premium-plan-path2: "\ee23";
$pt-prioritydevice-path1: "\ee24";
$pt-prioritydevice-path2: "\ee25";
$pt-privatenetwork-path1: "\ee26";
$pt-privatenetwork-path2: "\ee27";
$pt-profilenotebook-user-path1: "\ee28";
$pt-profilenotebook-user-path2: "\ee29";
$pt-ptt-presstospeak-path1: "\ee2a";
$pt-ptt-presstospeak-path2: "\ee2b";
$pt-qosmodem-path1: "\ee2c";
$pt-qosmodem-path2: "\ee2d";
$pt-rec-path1: "\ee2e";
$pt-rec-path2: "\ee2f";
$pt-receive-banktranfer-path1: "\ee30";
$pt-receive-banktranfer-path2: "\ee31";
$pt-red-path1: "\ee32";
$pt-red-path2: "\ee33";
$pt-registry-path1: "\ee34";
$pt-registry-path2: "\ee35";
$pt-report-path1: "\ee36";
$pt-report-path2: "\ee37";
$pt-roaming-large: "\ee38";
$pt-roaming-path1: "\ee39";
$pt-roaming-path2: "\ee3a";
$pt-row-bottom: "\ee3b";
$pt-row-left: "\ee3c";
$pt-row-rigth: "\ee3d";
$pt-row-top: "\ee3e";
$pt-saas-path1: "\ee3f";
$pt-saas-path2: "\ee40";
$pt-safetoschool-path1: "\ee41";
$pt-safetoschool-path2: "\ee42";
$pt-sd-path1: "\ee43";
$pt-sd-path2: "\ee44";
$pt-search-contact-path1: "\ee45";
$pt-search-contact-path2: "\ee46";
$pt-search: "\ee47";
$pt-secure-pay: "\ee48";
$pt-security-call-path1: "\ee49";
$pt-security-call-path2: "\ee4a";
$pt-security-list-path1: "\ee4b";
$pt-security-list-path2: "\ee4c";
$pt-security-pending-path1: "\ee4d";
$pt-security-pending-path2: "\ee4e";
$pt-security-telephony-path1: "\ee4f";
$pt-security-telephony-path2: "\ee50";
$pt-security-wifi-path1: "\ee51";
$pt-security-wifi-path2: "\ee52";
$pt-security-path1: "\ee53";
$pt-security-path2: "\ee54";
$pt-send-banktransfer-path1: "\ee55";
$pt-send-banktransfer-path2: "\ee56";
$pt-send-top-up-path1: "\ee57";
$pt-send-top-up-path2: "\ee58";
$pt-server-checkok-path1: "\ee59";
$pt-server-checkok-path2: "\ee5a";
$pt-server-list-path1: "\ee5b";
$pt-server-list-path2: "\ee5c";
$pt-server-preferences-path1: "\ee5d";
$pt-server-preferences-path2: "\ee5e";
$pt-server-settings-path1: "\ee5f";
$pt-server-settings-path2: "\ee60";
$pt-server-user-path1: "\ee61";
$pt-server-user-path2: "\ee62";
$pt-server-path1: "\ee63";
$pt-server-path2: "\ee64";
$pt-servers-path1: "\ee65";
$pt-servers-path2: "\ee66";
$pt-service-diagnostic-path1: "\ee67";
$pt-service-diagnostic-path2: "\ee68";
$pt-services-path1: "\ee69";
$pt-services-path2: "\ee6a";
$pt-settings: "\ee6b";
$pt-shaking-hands-path1: "\ee6c";
$pt-shaking-hands-path2: "\ee6d";
$pt-shopping-cart-2: "\ee6e";
$pt-shopping-cart-path1: "\ee6f";
$pt-shopping-cart-path2: "\ee70";
$pt-signal-location-path1: "\ee71";
$pt-signal-location-path2: "\ee72";
$pt-signal-path1: "\ee73";
$pt-signal-path2: "\ee74";
$pt-sms-large: "\ee75";
$pt-sms-path1: "\ee76";
$pt-sms-path2: "\ee77";
$pt-special-services-path1: "\ee78";
$pt-special-services-path2: "\ee79";
$pt-specs-path1: "\ee7a";
$pt-specs-path2: "\ee7b";
$pt-speedcalculator-timer-path1: "\ee7c";
$pt-speedcalculator-timer-path2: "\ee7d";
$pt-star-circle-path1: "\ee7e";
$pt-star-circle-path2: "\ee7f";
$pt-star: "\ee80";
$pt-statistics-path1: "\ee81";
$pt-statistics-path2: "\ee82";
$pt-store-path1: "\ee83";
$pt-store-path2: "\ee84";
$pt-subscriptions-path1: "\ee85";
$pt-subscriptions-path2: "\ee86";
$pt-target-server-path1: "\ee87";
$pt-target-server-path2: "\ee88";
$pt-television-full-path1: "\ee89";
$pt-television-full-path2: "\ee8a";
$pt-television-outline-path1: "\ee8b";
$pt-television-outline-path2: "\ee8c";
$pt-television: "\ee8d";
$pt-ticket-GO-path1: "\ee8e";
$pt-ticket-GO-path2: "\ee8f";
$pt-ticket-money-path1: "\ee90";
$pt-ticket-money-path2: "\ee91";
$pt-ticketinformation-path1: "\ee92";
$pt-ticketinformation-path2: "\ee93";
$pt-tigo-backup-path1: "\ee94";
$pt-tigo-backup-path2: "\ee95";
$pt-tigo-online-path1: "\ee96";
$pt-tigo-online-path2: "\ee97";
$pt-timer-path1: "\ee98";
$pt-timer-path2: "\ee99";
$pt-top-up-path1: "\ee9a";
$pt-top-up-path2: "\ee9b";
$pt-triple-play-path1: "\ee9c";
$pt-triple-play-path2: "\ee9d";
$pt-tv-3d-path1: "\ee9e";
$pt-tv-3d-path2: "\ee9f";
$pt-tv-cable: "\eea0";
$pt-tv-decoder-path1: "\eea1";
$pt-tv-decoder-path2: "\eea2";
$pt-tv-digital-path1: "\eea3";
$pt-tv-digital-path2: "\eea4";
$pt-tv-favoriteticket-path1: "\eea5";
$pt-tv-favoriteticket-path2: "\eea6";
$pt-tv-guide-path1: "\eea7";
$pt-tv-guide-path2: "\eea8";
$pt-tv-hd-path1: "\eea9";
$pt-tv-hd-path2: "\eeaa";
$pt-tv-list-path1: "\eeab";
$pt-tv-list-path2: "\eeac";
$pt-tv-live-path1: "\eead";
$pt-tv-live-path2: "\eeae";
$pt-tv-movies: "\eeaf";
$pt-tv-multiroom-path1: "\eeb0";
$pt-tv-multiroom-path2: "\eeb1";
$pt-tv-recommender-path1: "\eeb2";
$pt-tv-recommender-path2: "\eeb3";
$pt-tv-remotecontrol-path1: "\eeb4";
$pt-tv-remotecontrol-path2: "\eeb5";
$pt-tv-satellite-path1: "\eeb6";
$pt-tv-satellite-path2: "\eeb7";
$pt-tv-sofa-path1: "\eeb8";
$pt-tv-sofa-path2: "\eeb9";
$pt-tv-ticketvideo-path1: "\eeba";
$pt-tv-ticketvideo-path2: "\eebb";
$pt-tv-tigoplay-path1: "\eebc";
$pt-tv-tigoplay-path2: "\eebd";
$pt-tv-videochannels-path1: "\eebe";
$pt-tv-videochannels-path2: "\eebf";
$pt-tv-vod-path1: "\eec0";
$pt-tv-vod-path2: "\eec1";
$pt-unlimited-calls-path1: "\eec2";
$pt-unlimited-calls-path2: "\eec3";
$pt-unlimitedshippingpackages-path1: "\eec4";
$pt-unlimitedshippingpackages-path2: "\eec5";
$pt-user-chat-path1: "\eec6";
$pt-user-chat-path2: "\eec7";
$pt-user-exit-path1: "\eec8";
$pt-user-exit-path2: "\eec9";
$pt-user-invalid-path1: "\eeca";
$pt-user-invalid-path2: "\eecb";
$pt-user-logout-path1: "\eecc";
$pt-user-logout-path2: "\eecd";
$pt-user-profile: "\eece";
$pt-user-protection-path1: "\eecf";
$pt-user-protection-path2: "\eed0";
$pt-user-question-path1: "\eed1";
$pt-user-question-path2: "\eed2";
$pt-user-valid-path1: "\eed3";
$pt-user-valid-path2: "\eed4";
$pt-user: "\eed5";
$pt-users-path1: "\eed6";
$pt-users-path2: "\eed7";
$pt-velocity-speed-path1: "\eed8";
$pt-velocity-speed-path2: "\eed9";
$pt-video-surveillance-path1: "\eeda";
$pt-video-surveillance-path2: "\eedb";
$pt-virtual-servers-path1: "\eedc";
$pt-virtual-servers-path2: "\eedd";
$pt-voicemail-path1: "\eede";
$pt-voicemail-path2: "\eedf";
$pt-volume-path1: "\eee0";
$pt-volume-path2: "\eee1";
$pt-waiting-call-path1: "\eee2";
$pt-waiting-call-path2: "\eee3";
$pt-web-asset-path1: "\eee4";
$pt-web-asset-path2: "\eee5";
$pt-web-hosting-path1: "\eee6";
$pt-web-hosting-path2: "\eee7";
$pt-widgets-path1: "\eee8";
$pt-widgets-path2: "\eee9";
$pt-wifi-highsignal: "\eeea";
$pt-wifi-lowsignal-path1: "\eeeb";
$pt-wifi-lowsignal-path2: "\eeec";
$pt-wifi-mediumsignal-path1: "\eeed";
$pt-wifi-mediumsignal-path2: "\eeee";
$pt-world-server-path1: "\eeef";
$pt-world-server-path2: "\eef0";
$pt-xls-path1: "\eef1";
$pt-xls-path2: "\eef2";

