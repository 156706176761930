[class^="ic-"],
[class*=" ic-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icons-tigoune;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ic-4g:before {
    content: "\e900";
    color: #7f9bbd;
}

.ic-admin-capacidades:before {
    content: "\e901";
    color: #7f9bbd;
}

.ic-alarma:before {
    content: "\e902";
    color: #7f9bbd;
}

.ic-alerta-bg-fill:before {
    content: "\e903";
    color: #7f9bbd;
}

.ic-apn:before {
    content: "\e904";
    color: #7f9bbd;
}

.ic-apps:before {
    content: "\e905";
    color: #7f9bbd;
}

.ic-atp:before {
    content: "\e906";
    color: #7f9bbd;
}

.ic-audio:before {
    content: "\e907";
    color: #7f9bbd;
}

.ic-autogestion:before {
    content: "\e908";
    color: #7f9bbd;
}

.ic-ayuda:before {
    content: "\e909";
    color: #7f9bbd;
}

.ic-balance:before {
    content: "\e90a";
    color: #7f9bbd;
}

.ic-balanceo-carga:before {
    content: "\e90b";
    color: #7f9bbd;
}

.ic-bloquear:before {
    content: "\e90c";
    color: #7f9bbd;
}

.ic-borrar:before {
    content: "\e90d";
    color: #7f9bbd;
}

.ic-buscar:before {
    content: "\e90e";
    color: #7f9bbd;
}

.ic-calendario:before {
    content: "\e90f";
    color: #7f9bbd;
}

.ic-call-dispersion:before {
    content: "\e910";
    color: #7f9bbd;
}

.ic-cambiar-clave:before {
    content: "\e911";
    color: #7f9bbd;
}

.ic-cambio-rutas:before {
    content: "\e912";
    color: #7f9bbd;
}

.ic-carrito:before {
    content: "\e913";
    color: #7f9bbd;
}

.ic-cerrar:before {
    content: "\e914";
    color: #7f9bbd;
}

.ic-cerrar-bg-fill:before {
    content: "\e915";
    color: #7f9bbd;
}

.ic-chat:before {
    content: "\e916";
    color: #7f9bbd;
}

.ic-check-user:before {
    content: "\e917";
    color: #7f9bbd;
}

.ic-chulo:before {
    content: "\e918";
    color: #7f9bbd;
}

.ic-chulo-fill:before {
    content: "\e919";
    color: #7f9bbd;
}

.ic-cliente-pregunta:before {
    content: "\e91a";
    color: #7f9bbd;
}

.ic-cobertura:before {
    content: "\e91b";
    color: #7f9bbd;
}

.ic-codigo:before {
    content: "\e91c";
    color: #7f9bbd;
}

.ic-compartir-saldo:before {
    content: "\e91d";
    color: #7f9bbd;
}

.ic-compra-paquete:before {
    content: "\e91e";
    color: #7f9bbd;
}

.ic-conectividad:before {
    content: "\e91f";
    color: #7f9bbd;
}

.ic-confi-dhcp:before {
    content: "\e920";
    color: #7f9bbd;
}

.ic-config-anuncios:before {
    content: "\e921";
    color: #7f9bbd;
}

.ic-configuracion-ivr:before {
    content: "\e922";
    color: #7f9bbd;
}

.ic-configuracion-agente:before {
    content: "\e923";
    color: #7f9bbd;
}

.ic-configurar:before {
    content: "\e924";
    color: #7f9bbd;
}

.ic-consulta:before {
    content: "\e925";
    color: #7f9bbd;
}

.ic-consulte-servicios:before {
    content: "\e926";
    color: #7f9bbd;
}

.ic-contrato-movil:before {
    content: "\e927";
    color: #7f9bbd;
}

.ic-contrato-politicas:before {
    content: "\e928";
    color: #7f9bbd;
}

.ic-control-remoto:before {
    content: "\e929";
    color: #7f9bbd;
}

.ic-cupon:before {
    content: "\e92a";
    color: #7f9bbd;
}

.ic-data-center:before {
    content: "\e92b";
    color: #7f9bbd;
}

.ic-deco:before {
    content: "\e92c";
    color: #7f9bbd;
}

.ic-derecha:before {
    content: "\e92d";
    color: #7f9bbd;
}

.ic-desbloquear:before {
    content: "\e92e";
    color: #7f9bbd;
}

.ic-descargar:before {
    content: "\e92f";
    color: #7f9bbd;
}

.ic-dispositivos:before {
    content: "\e930";
    color: #7f9bbd;
}

.ic-dmz:before {
    content: "\e931";
    color: #7f9bbd;
}

.ic-dns:before {
    content: "\e932";
    color: #7f9bbd;
}

.ic-documento:before {
    content: "\e933";
    color: #7f9bbd;
}

.ic-ejecucion-scripts:before {
    content: "\e934";
    color: #7f9bbd;
}

.ic-empresas:before {
    content: "\e935";
    color: #7f9bbd;
}

.ic-enrutamiento:before {
    content: "\e936";
    color: #7f9bbd;
}

.ic-entrar:before {
    content: "\e937";
    color: #7f9bbd;
}

.ic-entrega:before {
    content: "\e938";
    color: #7f9bbd;
}

.ic-equipo:before {
    content: "\e939";
    color: #7f9bbd;
}

.ic-especiales:before {
    content: "\e93a";
    color: #7f9bbd;
}

.ic-espera:before {
    content: "\e93b";
    color: #7f9bbd;
}

.ic-estado-cuenta:before {
    content: "\e93c";
    color: #7f9bbd;
}

.ic-etica:before {
    content: "\e93d";
    color: #7f9bbd;
}

.ic-expandir-mas:before {
    content: "\e93e";
    color: #7f9bbd;
}

.ic-experto:before {
    content: "\e93f";
    color: #7f9bbd;
}

.ic-extensiones:before {
    content: "\e940";
    color: #7f9bbd;
}

.ic-f5:before {
    content: "\e941";
    color: #7f9bbd;
}

.ic-facebook:before {
    content: "\e942";
    color: #7f9bbd;
}

.ic-facilidades:before {
    content: "\e943";
    color: #7f9bbd;
}

.ic-factura-tigo:before {
    content: "\e944";
    color: #7f9bbd;
}

.ic-factura-tigoune:before {
    content: "\e945";
    color: #7f9bbd;
}

.ic-fija-comunidad:before {
    content: "\e946";
    color: #7f9bbd;
}

.ic-fijo-movil:before {
    content: "\e947";
    color: #7f9bbd;
}

.ic-filtro:before {
    content: "\e948";
    color: #7f9bbd;
}

.ic-firewall:before {
    content: "\e949";
    color: #7f9bbd;
}

.ic-flecha-arriba:before {
    content: "\e94a";
    color: #7f9bbd;
}

.ic-formulario-user:before {
    content: "\e94b";
    color: #7f9bbd;
}

.ic-forwarding:before {
    content: "\e94c";
    color: #7f9bbd;
}

.ic-gestion-documental:before {
    content: "\e94d";
    color: #7f9bbd;
}

.ic-guia-canales:before {
    content: "\e94e";
    color: #7f9bbd;
}

.ic-histo-llamadas:before {
    content: "\e94f";
    color: #7f9bbd;
}

.ic-historial:before {
    content: "\e950";
    color: #7f9bbd;
}

.ic-hosting:before {
    content: "\e951";
    color: #7f9bbd;
}

.ic-hunt:before {
    content: "\e952";
    color: #7f9bbd;
}

.ic-imei:before {
    content: "\e953";
    color: #7f9bbd;
}

.ic-info-financiera:before {
    content: "\e954";
    color: #7f9bbd;
}

.ic-informes-desempeno:before {
    content: "\e955";
    color: #7f9bbd;
}

.ic-ingreso-falla:before {
    content: "\e956";
    color: #7f9bbd;
}

.ic-ingreso-programado:before {
    content: "\e957";
    color: #7f9bbd;
}

.ic-inicio:before {
    content: "\e958";
    color: #7f9bbd;
}

.ic-interactiva-tv:before {
    content: "\e959";
    color: #7f9bbd;
}

.ic-intercambiar:before {
    content: "\e95a";
    color: #7f9bbd;
}

.ic-interconeccion:before {
    content: "\e95b";
    color: #7f9bbd;
}

.ic-internet:before {
    content: "\e95c";
    color: #7f9bbd;
}

.ic-ip:before {
    content: "\e95d";
    color: #7f9bbd;
}

.ic-izquierda:before {
    content: "\e95e";
    color: #7f9bbd;
}

.ic-larga-distancia:before {
    content: "\e95f";
    color: #7f9bbd;
}

.ic-liders:before {
    content: "\e960";
    color: #7f9bbd;
}

.ic-lista:before {
    content: "\e961";
    color: #7f9bbd;
}

.ic-lista-precios:before {
    content: "\e962";
    color: #7f9bbd;
}

.ic-live-tv:before {
    content: "\e963";
    color: #7f9bbd;
}

.ic-llamada-charla:before {
    content: "\e964";
    color: #7f9bbd;
}

.ic-llamada-internacional:before {
    content: "\e965";
    color: #7f9bbd;
}

.ic-llamadas:before {
    content: "\e966";
    color: #7f9bbd;
}

.ic-llamar:before {
    content: "\e967";
    color: #7f9bbd;
}

.ic-lot:before {
    content: "\e968";
    color: #7f9bbd;
}

.ic-mail:before {
    content: "\e969";
    color: #7f9bbd;
}

.ic-mail-hosting:before {
    content: "\e96a";
    color: #7f9bbd;
}

.ic-mas-vertical:before {
    content: "\e96b";
    color: #7f9bbd;
}

.ic-medidor-velocidad:before {
    content: "\e96c";
    color: #7f9bbd;
}

.ic-menu:before {
    content: "\e96d";
    color: #7f9bbd;
}

.ic-mifi:before {
    content: "\e96e";
    color: #7f9bbd;
}

.ic-modi-equipos:before {
    content: "\e96f";
    color: #7f9bbd;
}

.ic-modi-monitoreo:before {
    content: "\e970";
    color: #7f9bbd;
}

.ic-monitoreo-canal:before {
    content: "\e971";
    color: #7f9bbd;
}

.ic-mora-factura:before {
    content: "\e972";
    color: #7f9bbd;
}

.ic-mostrar:before {
    content: "\e973";
    color: #7f9bbd;
}

.ic-movil:before {
    content: "\e974";
    color: #7f9bbd;
}

.ic-movil-desbloqueo:before {
    content: "\e975";
    color: #7f9bbd;
}

.ic-mtu:before {
    content: "\e976";
    color: #7f9bbd;
}

.ic-nat:before {
    content: "\e977";
    color: #7f9bbd;
}

.ic-nombre-ssid:before {
    content: "\e978";
    color: #7f9bbd;
}

.ic-noticias:before {
    content: "\e979";
    color: #7f9bbd;
}

.ic-notificacion:before {
    content: "\e97a";
    color: #7f9bbd;
}

.ic-nube:before {
    content: "\e97b";
    color: #7f9bbd;
}

.ic-nube-irradia:before {
    content: "\e97c";
    color: #7f9bbd;
}

.ic-nueva-sim:before {
    content: "\e97d";
    color: #7f9bbd;
}

.ic-ocultar:before {
    content: "\e97e";
    color: #7f9bbd;
}

.ic-office-365:before {
    content: "\e97f";
    color: #7f9bbd;
}

.ic-organigrama:before {
    content: "\e980";
    color: #7f9bbd;
}

.ic-pago-programado:before {
    content: "\e981";
    color: #7f9bbd;
}

.ic-paquetes:before {
    content: "\e982";
    color: #7f9bbd;
}

.ic-pdf:before {
    content: "\e983";
    color: #7f9bbd;
}

.ic-peligro-bg:before {
    content: "\e984";
    color: #7f9bbd;
}

.ic-peligro-lin:before {
    content: "\e985";
    color: #7f9bbd;
}

.ic-permisosbd:before {
    content: "\e986";
    color: #7f9bbd;
}

.ic-permisos-llamadas:before {
    content: "\e987";
    color: #7f9bbd;
}

.ic-permisos-llamar:before {
    content: "\e988";
    color: #7f9bbd;
}

.ic-pines:before {
    content: "\e989";
    color: #7f9bbd;
}

.ic-police:before {
    content: "\e98a";
    color: #7f9bbd;
}

.ic-politica-publica:before {
    content: "\e98b";
    color: #7f9bbd;
}

.ic-politicas:before {
    content: "\e98c";
    color: #7f9bbd;
}

.ic-portabilidad:before {
    content: "\e98d";
    color: #7f9bbd;
}

.ic-port-forward:before {
    content: "\e98e";
    color: #7f9bbd;
}

.ic-potencia-consumo:before {
    content: "\e98f";
    color: #7f9bbd;
}

.ic-preguntas-sic:before {
    content: "\e990";
    color: #7f9bbd;
}

.ic-premium:before {
    content: "\e991";
    color: #7f9bbd;
}

.ic-productos:before {
    content: "\e992";
    color: #7f9bbd;
}

.ic-progreso:before {
    content: "\e993";
    color: #7f9bbd;
}

.ic-proteccion-usuario:before {
    content: "\e994";
    color: #7f9bbd;
}

.ic-punto:before {
    content: "\e995";
    color: #7f9bbd;
}

.ic-puntos-de-pago:before {
    content: "\e996";
    color: #7f9bbd;
}

.ic-qos:before {
    content: "\e997";
    color: #7f9bbd;
}

.ic-queja-reclamo:before {
    content: "\e998";
    color: #7f9bbd;
}

.ic-quiero:before {
    content: "\e999";
    color: #7f9bbd;
}

.ic-recarga:before {
    content: "\e99a";
    color: #7f9bbd;
}

.ic-recibo:before {
    content: "\e99b";
    color: #7f9bbd;
}

.ic-red-inteligente:before {
    content: "\e99c";
    color: #7f9bbd;
}

.ic-referente-pago:before {
    content: "\e99d";
    color: #7f9bbd;
}

.ic-reglas-waf:before {
    content: "\e99e";
    color: #7f9bbd;
}

.ic-regresar:before {
    content: "\e99f";
    color: #7f9bbd;
}

.ic-remoto-lot:before {
    content: "\e9a0";
    color: #7f9bbd;
}

.ic-repisar-contrasenas:before {
    content: "\e9a1";
    color: #7f9bbd;
}

.ic-respaldo-restaurar:before {
    content: "\e9a2";
    color: #7f9bbd;
}

.ic-salir:before {
    content: "\e9a3";
    color: #7f9bbd;
}

.ic-seguridad-check:before {
    content: "\e9a4";
    color: #7f9bbd;
}

.ic-selector-abajo:before {
    content: "\e9a5";
    color: #7f9bbd;
}

.ic-selector-arriba:before {
    content: "\e9a6";
    color: #7f9bbd;
}

.ic-servicios:before {
    content: "\e9a7";
    color: #7f9bbd;
}

.ic-servicios-especiales:before {
    content: "\e9a8";
    color: #7f9bbd;
}

.ic-sim-card:before {
    content: "\e9a9";
    color: #7f9bbd;
}

.ic-site-vpn:before {
    content: "\e9aa";
    color: #7f9bbd;
}

.ic-skill-campana:before {
    content: "\e9ab";
    color: #7f9bbd;
}

.ic-sms:before {
    content: "\e9ac";
    color: #7f9bbd;
}

.ic-sofa:before {
    content: "\e9ad";
    color: #7f9bbd;
}

.ic-softphone:before {
    content: "\e9ae";
    color: #7f9bbd;
}

.ic-solicitud:before {
    content: "\e9af";
    color: #7f9bbd;
}

.ic-solicitudes-cambios:before {
    content: "\e9b0";
    color: #7f9bbd;
}

.ic-soporte:before {
    content: "\e9b1";
    color: #7f9bbd;
}

.ic-soporte-tecnico:before {
    content: "\e9b2";
    color: #7f9bbd;
}

.ic-tablas-bd:before {
    content: "\e9b3";
    color: #7f9bbd;
}

.ic-tarjeta:before {
    content: "\e9b4";
    color: #7f9bbd;
}

.ic-tarjeta-cvv:before {
    content: "\e9b5";
    color: #7f9bbd;
}

.ic-tele-fija-segura:before {
    content: "\e9b6";
    color: #7f9bbd;
}

.ic-telefonia-codigo:before {
    content: "\e9b7";
    color: #7f9bbd;
}

.ic-tendencia:before {
    content: "\e9b8";
    color: #7f9bbd;
}

.ic-tiempo:before {
    content: "\e9b9";
    color: #7f9bbd;
}

.ic-tigo:before {
    content: "\e9ba";
    color: #7f9bbd;
}

.ic-tigo-play:before {
    content: "\e9bb";
    color: #7f9bbd;
}

.ic-tigoune:before {
    content: "\e9bc";
    color: #7f9bbd;
}

.ic-tips:before {
    content: "\e9bd";
    color: #7f9bbd;
}

.ic-trabajaconosotros:before {
    content: "\e9be";
    color: #7f9bbd;
}

.ic-transfer-llamada:before {
    content: "\e9bf";
    color: #7f9bbd;
}

.ic-tv:before {
    content: "\e9c0";
    color: #7f9bbd;
}

.ic-tv-estrenos:before {
    content: "\e9c1";
    color: #7f9bbd;
}

.ic-tv-hd-sd:before {
    content: "\e9c2";
    color: #7f9bbd;
}

.ic-tv-vod:before {
    content: "\e9c3";
    color: #7f9bbd;
}

.ic-twitter:before {
    content: "\e9c4";
    color: #7f9bbd;
}

.ic-usuario:before {
    content: "\e9c5";
    color: #7f9bbd;
}

.ic-usuario-datos:before {
    content: "\e9c6";
    color: #7f9bbd;
}

.ic-usuario-negocios:before {
    content: "\e9c7";
    color: #7f9bbd;
}

.ic-usuarios:before {
    content: "\e9c8";
    color: #7f9bbd;
}

.ic-verifica-contra:before {
    content: "\e9c9";
    color: #7f9bbd;
}

.ic-vigilancia:before {
    content: "\e9ca";
    color: #7f9bbd;
}

.ic-web-hosting:before {
    content: "\e9cb";
    color: #7f9bbd;
}

.ic-wifi:before {
    content: "\e9cc";
    color: #7f9bbd;
}

.ic-wifi-contrasena:before {
    content: "\e9cd";
    color: #7f9bbd;
}

.ic-youtube:before {
    content: "\e9ce";
    color: #7f9bbd;
}
