.at-font-h1{
  @extend %typography;
  font-size: $font_24x;
  font-weight: normal;
}
.at-font-h2{
  @extend %typography;
  font-size: $font_17x;
  font-weight: normal;
}
.at-font-h3{
  @extend %typography;
  font-size: $font_25x;
  font-weight: normal;
}
.at-font-h4{
  @extend %typography;
  font-size: $font_8x;
  font-weight: normal;
}
.at-font-h5{
  @extend %typography;
  font-size: $font_12x;
  font-weight: normal;
}
@include for-mobile{
  .at-font-h1{
    font-size: $font_25x;
  }
  .at-font-h2{
    font-size: $font_8x;
  }
  .at-font-h3{
    font-size: $font_14x;
  }
  .at-font-h4{
    font-size: $font_4x;
  }
  .at-font-h5{
    font-size: $font_12x;
  }
}
