.or-wecallyou{
  .ml-popup-form{
    .popup-content{
      padding: 24px;
      max-width: 450px;
      top: 12%;
      .ic-cerrar{
        &:before{
          color: transparentize($neutral_2, .5);
        }
      }
      .first-step{
        padding-top: 24px;
      }
      .second-step{
        display: none;
        padding-top: 24px;
        .content-popup-title{
          height: auto;
          display: block;
          text-align: center;
          .ic-chulo-fill{
            &:before{
              font-size: 3rem;
              color: #37c846;
            }
          }
          .at-font-p{
            padding: 0 12px;
          }
        }
        .content-popup-body{
          text-align: center;
          margin-bottom: 30px;
          h4{
            font-weight: 600;
          }
          .qualification{
            display: flex;
            justify-content: center;
          }
          .rate {
            position: relative;
            .at-font-small-legal{
              &:first-child{
                position: absolute;
                left: 0;
                top: 60%;
                margin-left: 10px;
              }
              &:last-child{
                position: absolute;
                right: 0;
                top: 60%;
              }
            }
          }
          .rate:not( :checked ) > input {
            position:absolute;
            top:-9999px;
          }
          .rate:not( :checked ) > label {
            float:right;
            width: 24px;
            white-space:nowrap;
            cursor:pointer;
            font-size: 29px;
            color: transparentize($neutral_2, .5);
            margin: 0 12px;
            padding: 0;
          }
          .rate:not( :checked ) > label:before {
            content: '★';
            position: initial;
            width: auto;
            height: auto;
            border: none;
            background: none;
          }
          .rate > input:checked ~ label {
            color: #2896ff;
          }
          .rate:not( :checked ) > label:hover,
          .rate:not( :checked ) > label:hover ~ label {
            color: #52a9fd;
          }
          .rate > input:checked + label:hover,
          .rate > input:checked + label:hover ~ label,
          .rate > input:checked ~ label:hover,
          .rate > input:checked ~ label:hover ~ label,
          .rate > label:hover ~ input:checked ~ label {
            color: #207ed8;
          }
        }
      }
      .content-popup-header{
        .content-popup-title{
          margin-bottom: 20px;
          svg{
            margin-right: 12px;
          }
          img{
            width: 60px;
            margin-right: 12px;
          }
          .at-font-h5{
            font-weight: 600;
            padding: 12px 0;
            text-transform: initial;
            letter-spacing: initial;
          }
        }
      }
      .content-popup-body{
        .at-font-p{
          margin-bottom: 15px;
        }
        div{
          padding-right: 5px;
          padding-left: 5px;
          label{
            width: 100%;
            margin: 10px 0;
            select, input{
              min-width: unset;
              width: 100%;
            }
          }
        }
        .at-font-small-legal{
          font-size: $font_2x;
          margin: 10px 0;
          display: inline-block;
          float: none;
          .at-link{
            font-weight: 600;
            display: inline;
          }
        }
        .at-selectlabel{
          span{
            font-family: sans-serif;
          }
        }
      }
    }
  }
}
@include for-mobile{
  .or-wecallyou{
    .ml-popup-form{
      .popup-content{
        .buttons-right{
          text-align: right;
        }
      }
    }
  }
}
