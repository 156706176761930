%own-dots {
  position: static;
  margin-top: $size_1x * 3;
}
%own-dots-button-active-span {
  background-color: $secundary_1;
}
%own-dots-button-span {
  width: 20px;
  height: 20px;
  background-color: transparentize($neutral_2, .5);
}
