.ml-hero{
  .content {
    position: relative;
    img {
      width: 100%;
      height:auto;
    }
    .content-info {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      &.left-orientation {
        left: 0;
        transform: translate(20%, -50%);
      }
      &.center-orientation {
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.right-orientation {
        right: 0;
        transform: translate(-20%, -50%);
      }
    }
  }
}
