%content-card{
  display: inline-block;
  vertical-align: middle;
  position: relative;
  @include for-mobile{
    padding: 0 12px;
    box-sizing: border-box;
  }
  .title-card{
    @include for-mobile{
      height: 44px;
      display: flex;
      align-items: center;
    }
  }
  .at-font-em{
    @include for-mobile{
      margin-top: 28px;
    }
  }
  .at-font-p{
    @include for-mobile{
      margin-top: 16px;
    }
  }
}
%buttons{
  position: relative;
  width: 100%;
  @include for-mobile {
    position: initial;
    margin-top: 1rem;
  }
}
