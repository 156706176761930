%accordion{
  .accordion-item{
    &.open{
      height: initial;
    }
    .item-title{
      cursor: pointer;
      position: relative;
      padding: 13px 12px;
      box-sizing: border-box;
      height: 48px;
      display: flex;
      align-items: center;
      .at-font-h4{
        font-size: $font_18x;
      }
      &:after{
        font-family: "icoTigoUne";
        content: "\e93e";
        color: $secundary_1;
        font-size: 24px;
        position: absolute;
        right: 12px;
        top: 12px;
      }
      i{
        margin-right: 12px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        &:before{
          color: $primary_3;
          font-size: 24px;
          position: relative;
        }
      }
    }
    .item-body{
      display: none;
      padding: 0 12px;
      box-sizing: border-box;
      @include for-mobile{
        padding: 0 12px;
      }
      *{
        margin-bottom: 24px;
        @include for-mobile{
          margin-bottom: 12px;
        }
      }
      img{
        @include for-mobile{
          display: block;
          width: 100%;
          margin-bottom: 24px;
        }
      }
      .at-font-p{
        font-size: $font_2x;
        @include for-mobile{
          font-size: $font_2x;
        }
      }
    }
    &.open{
      .item-title{
        &:after{
          content: "\e94a";
        }
      }
      .item-body{
        display: block;
      }
    }
  }
  .at-divider{
    margin: 0;
  }
}
