.ml-table{
  .at-containershadow-primary{
    @include for-mobile{
      width: 100%;
    }
    @extend %table;
    .header-table{
      font-family: $r-semibold;
      border-bottom: 2px solid $primary_1;
      padding-bottom: 6px;
      background-color: $neutral_1;
      @include for-mobile{
        display: inline-block;
        border-bottom: none;
        border-right: 2px solid $primary_1;
        position: absolute;
        width: 120px;
        top: 0;
        left: 0;
        z-index: 1;
        margin: 24px;
        padding-bottom: 0;
        //height: 100%;
      }
      ul{
        padding: 0;
        display: flex;
        margin: 0;
        @include for-mobile{
          flex-direction: column;
        }
        li{
          list-style: none;
          color: $primary_1;
          width: 33.3%;
          padding-right: 24px;
          @include for-mobile{
            padding: 12px 24px 12px 0;
            width: 100%;
          }
          &:nth-child( 1 ){
            padding-left: 12px;
            @include for-mobile{
              padding-left: 0;
            }
          }
          i{
            font-size: $font_14x;
            vertical-align: middle;
          }
          .at-font-p{
            display: inline-block;
          }
        }
        .at-divider{
          margin: 0;
          display: none;
          @include for-mobile{
            display: block;
          }
        }
      }
    }
    .body-table{
      @include for-mobile{
        width: auto;
        overflow: auto;
        white-space: nowrap;
        padding-left: 130px;
      }
      .row-table{
        font-family: $r-regular;
        @include for-mobile{
          display: inline-block;
        }
        ul{
          padding: 0;
          display: flex;
          margin: 0;
          @include for-mobile{
            flex-direction: column;
          }
          li{
            list-style: none;
            color: $primary_1;
            padding: 12px 24px 12px 0;
            width: 33.3%;
            &:nth-child( 1 ){
              padding-left: 12px;
              @include for-mobile{
                padding-left: 0;
              }
            }
            i{
              font-size: $font_14x;
              vertical-align: middle;
            }
            .at-font-p{
              display: inline-block;
            }
          }
          .at-divider{
            margin: 0;
            display: none;
            @include for-mobile{
              display: block;
            }
          }
        }
      }
      .at-divider{
        margin: 0;
        @include for-mobile{
          display: none;
        }
      }
      .mCustomScrollBox{
        height: auto;
        .mCSB_container{
          margin-bottom: 0;
        }
        .mCSB_scrollTools{
          z-index: 0;
          .mCSB_dragger_bar{
            background-color: $neutral_2;
            height: 4px;
            margin: 10px 0;
          }
          .mCSB_draggerRail{
            background-color: transparentize($neutral_2, .8);
            height: 4px;
            margin: 10px 0;
          }
        }
      }
    }
    .table-responsive{
      display: block;
      width: 100%;
      overflow-x: auto;
    }
    table{
      border-collapse: collapse;
      @include for-mobile{
        width: 100%;
        border-spacing: 0;
        display: block;
        position: relative;
      }
      thead{
        font-family: $r-semibold;
        border-bottom: 2px solid $primary_1;
        padding-bottom: 6px;
        @include for-mobile{
          display: block;
          float: left;
          border-bottom: none;
          border-right: 2px solid $primary_1;
          padding-bottom: 0;
        }
        tr{
          background: transparent;
          border-bottom: none;
          th{
            color: $primary_1;
            text-align: left;
            padding: 0 24px 6px 0;
            background: transparent;
            border: none;
            font-size: $font_3x;
            @include for-mobile{
              padding: 12px;
              display: block;
              padding-left: 0;
              border-bottom: 1px solid transparentize($neutral_2, .6);
            }
            &:nth-child( 1 ){
              padding-left: 12px;
              @include for-mobile{
                padding-left: 0;
              }
            }
            i{
              vertical-align: middle;
              &:before{
                font-size: $font_14x;
                @include for-mobile{
                  font-size: $font_12x;
                }
              }
            }
          }
        }
      }
      tbody {
        @include for-mobile{
          display: block;
          width: auto;
          position: relative;
          overflow-x: auto;
          white-space: nowrap;
          padding-left: 10px;
        }
        tr {
          border-bottom: 1px solid transparentize($neutral_2, .6);
          font-family: $r-regular;
          background: transparent;
          @include for-mobile{
            border: none;
            display: inline-block;
            vertical-align: top;
          }
          td{
            color: $neutral_2;
            padding: 12px 24px 12px 0;
            border: none;
            font-size: $font_3x;
            //width: 33.3%;
            @include for-mobile{
              display: block;
              vertical-align: top;
              border-bottom: 1px solid transparentize($neutral_2, .6);
            }
            &:nth-child( 1 ){
              padding-left: 12px;
              @include for-mobile{
                padding-left: 0;
              }
            }
            i{
              vertical-align: middle;
              &:before{
                font-size: $font_14x;
                @include for-mobile{
                  font-size: $font_12x;
                }
              }
            }
          }
        }
        .mCustomScrollBox{
          height: auto;
          .mCSB_container{
            margin-bottom: 0;
          }
          .mCSB_scrollTools{
            z-index: 0;
            .mCSB_dragger_bar{
              background-color: $neutral_2;
              height: 4px;
              margin: 10px 0;
            }
            .mCSB_draggerRail{
              background-color: transparentize($neutral_2, .8);
              height: 4px;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
