.at-button-secondary{
  @extend %button;
  border-color: $secundary_1;
  padding:0 18px;
  &:hover{
    border-color: $secundary_3;
  }
  &:disabled{
    border-color:transparentize($secundary_1, .7);
  }
  &:focus{
    border-color: $secundary_2;
  }
}
