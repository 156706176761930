.form-wrapper{
  .at-input-select{
    border-bottom: $border_2 transparentize($neutral_2, .1);
  }
}
.at-input-select{
  @extend %input;
  option{
    color: $neutral_2;
    padding: {
      top: 1rem;
      bottom: 1rem;
    }
    outline: none;
  }
}
.at-selectlabel{
  @extend %label-animate;
  &:before{
    position: absolute;
    right: 12px;
    top: 13px;
    font-family: $icons-tigoune;
    font-size: $font_14x;
    font-weight: bold;
    color: transparentize($secundary_1, .5);
    pointer-events: none;
  }
  span{
    line-height: 15px;
  }
}
