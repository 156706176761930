.form-wrapper{
  .at-button-loader{
    @extend %button;
    background-color: $secundary_1;
    color:$neutral_1;
    padding:0 18px;
    &:hover{
      color: $neutral_1;
      background-color:$secundary_3;
    }
    &:disabled{
      background-color: transparentize($secundary_1, .7);
      color: $neutral_1;
    }
    &:focus{
      background-color:$secundary_2;
      color: $neutral_1;
    }
  }
}
.at-button-loader{
  padding: 5px 18px;
  @extend %button;
  background-color: $secundary_1;
  color:$neutral_1;
  &:hover{
    color: $neutral_1;
    background-color:$secundary_3;
    .at-small-circular-progress-indicator{
      border-top: 3px solid rgba(255,255,255,.5);
    }
  }
  &:disabled{
    background-color: transparentize($secundary_1, .7);
    color: $neutral_1;
  }
  &:focus{
    background-color:$secundary_2;
    color: $neutral_1;
  }
  .at-small-circular-progress-indicator{
    width: 20px;
    height: 20px;
    background: transparent;
    border-top: 3px solid rgba(255,255,255,.5);
  }
}