.at-button-iconcolor{
  @extend %button;
  border: $border_1 $secundary_1;
  color: $secundary_1;
  display: flex;
  float: left;
  align-items: center;
  justify-content: center;
  padding:0 18px;
  i{
    &:before{
      color: $secundary_1;
    }
    &:hover, &:focus, &:disabled{
      &:before{
        color: $neutral_1;
      }
    }
  }
  &[class*='ic-']{
    &:before{
      color: $secundary_1;
    }
    &:hover, &:focus, &:disabled{
      &:before{
        color: $neutral_1;
      }
    }
  }
  span{
    color: $secundary_1;
  }
  &:hover{
    color: $neutral_1;
    background-color:$secundary_3;
    border: $border_1 transparent;
    i{
      &:before{
        color: $neutral_1;
      }
    }
    span{
      color: $neutral_1;
    }
  }
  &:disabled{
    background-color: transparentize($secundary_1, .7);
    border: $border_1 transparent;
    color: $neutral_1;
    i{
      &:before{
        color: $neutral_1;
      }
    }
    span{
      color: $neutral_1;
    }
  }
  &:focus{
    background-color:$secundary_2;
    border: $border_1 transparent;
    color: $neutral_1;
    i{
      &:before{
        color: $neutral_1;
      }
    }
    span{
      color: $neutral_1;
    }
  }
}
