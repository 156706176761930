%general-interest{
  margin: 0 6px;
  .at-containershadow-primary{
    //width: 370px;
    height: auto;
    margin-bottom: 20px;
    padding: 0;
    // @include for-mobile{
    //   width: 300px;
    // }
  }
  .content-info{
    padding: 24px;
    .content-button{
      text-align: right;
      margin-top: 24px;
      .at-button-tertiary {
        padding-right: 0;
      }
    }
    h5 {
      margin-bottom: 12px;
      text-transform: none;
      letter-spacing: inherit;
    }
    .at-button-tertiary {
      font-size: $font_3x;
    }
  }
}
