.pg-landing-plans{
  @extend %landing;
  section{
    margin: 50px 0;
    &.plans{
      .at-font-h2{
        margin: 20px 0;
        margin-bottom: 50px;
      }
    }
    &.plans-interest{
      .at-font-h3{
        margin: 20px 0;
      }
    }
  }
}
