.or-carousel-gallery{
  @extend %carousel;
  .item{
    padding: 0;
    width: 220px;
    position: relative;
    @include for-desktop {
      width: auto;
    }
    .info{
      display: none;
      background-color: rgba(0, 0, 0, .6);
      position: absolute;
      width: 100%;
      bottom: 0;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      box-sizing: border-box;
      .at-font-p{
        margin: 0;
        color: $neutral_1;
        font-size: $font_3x;
      }
    }
    &:hover{
      cursor: pointer;
      .info{
        display: flex;
      }
    }
  }
}