%carousel{
  width: 97%;
  margin: 0 auto;
  .at-containershadow-secondary, .item{
  	max-width: none;
    border: none;
    margin-right: 10px;
  	&:hover{
  	 border: $border_1 $secundary_1; 
  	}
  }
  .owl-carousel{
    .owl-nav {
      width: 100%;
      position: absolute;
      top: 40%;
      left: 0;
      opacity: 1;
      button{
        background: $neutral_1;
        border-radius: 100%;
        height: 36px;
        width: 36px;
        margin: 0 24px;
        outline: none;
        box-shadow: $shadow_1;
        margin: 0;
        &:before{
          font-family: $icons-tigoune;
          font-size: 36px;
          font-weight: bold;
          color: $secundary_1;
        }
        span{
          display: none;
        }
        &.owl-prev{
          float: left;
          background: $neutral_1;
          position: relative;
          left: -15px;
          &:before{
            content: "\e95e";
          }
          &:hover{
            background: $neutral_1;
          }
        }
        &.owl-next{
          float: right;
          background: $neutral_1;
          right: -15px;
          position: relative;
          &:before{
            content: "\e92d";
          }
          &:hover{
            background: $neutral_1;
          }
        }
      }
      @include for-mobile {
        display: none;
      }
      &.disabled{
        display: block;
        @include for-mobile {
          display: none;
        }
      }
    }
  }
}
