.or-carousel-team{
  @extend %carousel;
  .owl-stage-outer{
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .at-containershadow-secondary{
    padding: 20px;
    border: 1px solid #dcdcdc;
    box-shadow: none;
    @include for-mobile {
      width: 310px;
    }
    img{
      width: 150px;
      margin: 0 auto;
    }
    .at-font-h5{
      color: $primary_1;
      text-align: center;
      margin-top: 10px;
    }
    .at-font-p{
      color: $neutral_2;
      text-align: center;
    }
    .social{
      text-align: center;
      margin-top: 20px;
      .at-link{
        display: inline-block;
      }
    }
  }
}
