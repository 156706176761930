.ml-basic-header{
  @extend %basic-header;
  &.at-containershadow-secondary{
    border-radius: 0;
    max-width: 100%;
  }
  .navbar-brand{
    display: inline-block;
    img{
      width: 51px;
    }
  }
}