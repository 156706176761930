%or-general-interest{
  .content-header{
    text-align: center;
    padding: 12px 0 36px;
  }
  .content-body{
    max-width: $grid;
    margin: 0 auto;
    @include for-mobile{
      overflow-x: scroll;
    }
    .contenido{
      @include for-mobile{
        width: 830px;
      }
    }
  }
  .in-mobile-slider {
    .owl-dots {
      margin-bottom: 12px;
      margin-top: 0;
    }
    &.owl-carousel{
      .owl-nav {
        width: 100%;
        position: absolute;
        top: 48%;
        left: 0;
        opacity: 0;
        button{
          background: transparentize($primary_1, .6);
          border-radius: 100%;
          height: 36px;
          width: 36px;
          margin: 0 24px;
          outline: none;
          &:before{
            font-family: $icons-tigoune;
            font-size: 36px;
            font-weight: bold;
            color: $neutral_1;
          }
          span{
            display: none;
          }
          &.owl-prev{
            float: left;
            background: transparentize($primary_1, .6);
            &:before{
              content: "\e95e";
            }
            &:hover{
              background: transparentize($neutral_2, .5);;
            }
          }
          &.owl-next{
            float: right;
            background: transparentize($primary_1, .6);
            &:before{
              content: "\e92d";
            }
            &:hover{
              background: transparentize($neutral_2, .5);;
            }
          }
        }
        @include for-mobile {
          display: none;
        }
      }
      .owl-dots{
        //position: absolute;
        bottom: 1%;
        left: 0;
        width: 100%;
        text-align: center;
        button{
          &.owl-dot{
            padding: 8px;
            margin: 0 2px;
            outline: none;
            span{
              //background: transparentize($primary_1, .6);
              width: 8px;
              height: 8px;
              float: left;
              border-radius: 100%;
            }
            &.active{
              span{
                //background: $neutral_4;
                width: 8px;
                height: 8px;
                box-shadow: none;
              }
            }
          }
        }
      }
      &:hover{
        .owl-nav {
          opacity: 1;
        }
      }
    }
  }
}
