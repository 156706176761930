.ml-popup {
  @extend %fallball-popup;
  .popup-content {
    @extend %content-popup;
    text-align: left;
    .at-font-h4{
      font-size: $font_12x;
    }
    .at-font-p{
      color: $neutral_2;
      padding-bottom: $size_1x * 2;
    }
    .at-button-secondary{
      margin: 0 $size_1x;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .ic-cerrar {
    @extend %ic-iconos-popup;
  }
}
