.ml-notification-alert{
  background: $utility_2;
  @extend %notification;
  .content-buttons{
    text-align: right;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include for-mobile{
      padding-right: 12px;
      margin: 12px 0;
    }
    button:nth-child( 2 ){
      margin-left: 12px;
    }
    .btn-principal{
      background: $neutral_1;
      color: $utility_2;
      border: 1px solid $utility_2;
    }
    .btn-secondary{
      background: transparent;
      color: $neutral_1;
      border: 1px solid $neutral_1;
    }
  }
  .content-icon{
    text-align: right;
    i{
      font-size: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
      &:before{
        color: #fff;
      }
    }
  }
}
