.ml-boxe{
  .at-containershadow-primary{
    max-width: none;
    width: auto;
    display: inline-flex;
    align-items: center;
    padding: 20px;
    @include for-mobile{
      width: 100%;
      //margin: 0 6px;
    }
  }
  .content-img{
    display: inline-block;
    vertical-align: middle;
    padding: 0 24px 0 0;
    box-sizing: content-box;
    img{
      max-width: 80px;
      @include for-mobile{
        max-width: 60px;
      }
    }
  }
  .content-card {
    display: inline-block;
    vertical-align: middle;
    //padding-right: 18px;
    .content-info{
      display: inline-block;
      vertical-align: middle;
      color: #565656;
      font-family: "robotoregular";
      font-size: 16px;
      @include for-mobile{
        float: none;
        margin: 0;
      }
      p{
        margin-top: 12px;
      }
      .at-font-h4{
        font-size: $font_4x;
        @include for-mobile{
          font-size: $font_18x;
        }
      }
    }
  }
}
