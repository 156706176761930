.ml-card-servicios{
  .at-containershadow-primary{
    padding: 0;
    width: fit-content;
    display: flex;
    @include for-mobile{
      display: block;
      //padding: 12px 6px;
      box-sizing: border-box;
    }
  }
  img{
    float: left;
    width: 400px;
    @include for-mobile {
      float: none;
      margin-right: 0;
      max-width: 100%;
      max-height: initial;
      width: 100%;
    }
  }
  .content-card {
    @extend %content-card;
    padding: 24px;
    display: block;
    .title-card{
      margin-bottom: 12px;
    }
    .at-element-list{
      margin-top: 24px;
      @include for-mobile {
        padding-bottom: 0;
      }
      li{
        display: table;
        margin: .5rem 0;
        span {
          display:table-cell;
          vertical-align: middle;
        }
      }
    }
    .buttons-left{
      button{
        margin-left: 6px;
      }
      @extend %buttons;
      text-align: left;
      @include for-mobile {
        position: initial;
        float: none;
      }
    }
    .buttons-center{
      button{
        margin-left: 6px;
      }
      @extend %buttons;
      text-align: center;
      @include for-mobile {
        position: initial;
        float: none;
      }
    }
    .buttons-right{
      margin-top: 24px;
      button{
        margin-left: 6px;
      }
      @extend %buttons;
      right: 1rem;
      text-align: right;
      @include for-mobile {
        position: initial;
        float: none;
      }
    }
  }
}
