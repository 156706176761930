.price-tables {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}
.or-price-table{
  margin-bottom: 15px;
  width: 100%;
  @media (min-width: 769px){
    width: 310px;
    margin: 20px 10px 0;
  }
  @media (min-width: 1400px){
    width: 330px;
  }
  @extend %price-table;
  .at-containershadow-primary{
    border-radius: .25rem;
    padding: 0;
    .pricing{
      padding: 20px;
      text-align: center;
      pointer-events: none;
      @media (max-width: 769px){
        pointer-events:inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 15px;
      }
      .name {
        text-transform: uppercase;
        font-size: 1.10rem;
        @media (max-width: 769px){
          width: 70%;
          text-align: left;
        }
        @media (max-width: 460px){
          width: 50%;
          font-size: 1.05rem;
        }
      }
      .value {
        ul {
          padding: 0;
          margin: 0;
          @media (max-width: 769px){
            text-align: right;
          }
          li {
            list-style: none;
          }
          .cargo-mobile{
            font-size: $font_2x;
            text-transform: uppercase;
            @media (min-width: 769px){
              display: none;
            }
          }
          .precio{
            font-size: $font_7x;
            color: $primary_1;
            font-family: $r-semibold;
            @media (max-width: 769px){
              font-size: $font_6x;
            }
            @media (max-width: 460px){
              font-size: 2rem;
            }
          }
          .cargo{
            font-size: $font_2x;
            text-transform: uppercase;
            @media (max-width: 769px){
              display: none;
            }
          }
          .iva{
            font-size: $font_2x;
          }
        }
      }
      .more{
        font-size: 30px;
        @media (min-width: 769px){
          display: none;
        }
      }
    }
    .content_info {
      display: block;
      @media (max-width: 769px){
        display: none;
      }
      .cta {
        text-align: center;
        padding-bottom: 20px;
      }
      .data-img, .data, .data-legal{
        border-top: 1px solid #d6d6d6;
        font-size: $font_3x;
        ul{
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          li{
            list-style: none;
            padding: 15px;
          }
          .img{
            width: 35%;
            padding-right: 0;
            img {
              width: 100%;
            }
          }
        }
      }
      .data-legal{
        ul{
          justify-content: center;
          li{
            font-size: $font_2x;
            padding: 8px 15px;
          }
        }
      }
    }
  }
}
.is-recomended{
  @media (max-width: 769px) {
    order: -1;
  }
  .recomended{
    @media (max-width: 769px){
      position: inherit;
      top: 0;
    }
    position: absolute;
    top: -20px;
    right: 0;
    left: 0;
    text-align: center;
    background-color: #3164DB;
    padding: 5px;
    color: #fff;
    text-transform: uppercase;
    border-radius: .25rem .25rem 0 0;
    font-size: $font_2x;
    font-family: $r-semibold;
  }
}
