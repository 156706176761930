.or-navbar-selfcare{
  max-width: none;
  border-radius: 0;
  @extend %navbar;
  &.menu-fixed{
    @extend %menu-fixed;
  }
  .background-navbar{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    left: 0;
    top: 0;
    z-index: 99;
    display: none;
  }
  .navbar-brand{
    @extend %navbar-brand;
    display: flex;
    align-items: center;
    img {
      width: 51px;
    }
    .dropdown-menu-button{
      .ic-burguer{
        margin-top: -3px;
        &:before{
          padding: 0;
        }
      }
      .ic-cerrar{
        padding-top: 5px;
      }
    }
  }
  .ml-primary-navigation{
    @extend %navbar-navigation;
    width: auto;
    .header-navigation-mobile{
      display: none;
      background: $primary_1;
      box-sizing: border-box;
      height: 60px;
      color: $neutral_1;
      padding: 0 24px;
      .at-font-h4{
        color: $neutral_1;
      }
    }
    .at-element-list{
      .at-link{
        text-transform: uppercase;
      }
    }
    .footer-navigation-mobile{
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      li{
        border: none;
      }
    }
  }
  .navbar-collapse{
    @extend %navbar-collapse;
    float: right;
    margin: 6px 0;
    flex-grow: 1;
    .at-element-list{
      float: right;
      display: flex;
      align-items: center;
      .ml-search-basic{
        position: absolute;
        max-width: 400px;
        right: 4%;
        .at-input-label{
          padding: 0;
          &:after{
            display: none;
          }
          button{
            padding: 0;
            background: none;
            border: none;
            margin: 0;
            &:before{
              font-size: 1.85rem;
            }
          }
        }
      }
    }
    .dropdown{
      .at-link{
        font-size: $font_3x;
        &:hover{
          svg{
            g{
              stroke: $secundary_1;
              circle{
                stroke: $secundary_1;
              }
            }
          }
        }
        i{
          float: right;
          margin: 0;
          border-radius: 100%;
          //padding: 9.5px;
          &:before{
            color: $neutral_1;
            font-size: $font_14x;
            margin: 0 auto;
          }
          &:hover{
            &:before{
              color: $secundary_1;
            }
          }
          &.ic-expandir-mas{
            margin-left: $size_1x;
            &:before{
              font-size: $font_14x;
              color: $secundary_1;
            }
          }
          .full{
            @extend %full-icon;
          }
        }
      }
      .at-card-medium-emphasis{
        margin-top: 6px;
      }
      &.user{
        margin: 0 24px;
        margin-left: 50px;
        cursor: pointer;
        .at-link{
          font-size: $font_18x;
        }
        .at-containershadow-secondary{
          padding-bottom: 0;
          .at-link{
            padding: 12px 0;
          }
        }
        &.is-active{
          .at-link{
            i{
              transform: rotate(180deg);
            }
          }
        }
      }
      &.noti{
        margin: 0 12px;
        .at-containershadow-secondary{
          padding: 0;
          //width: 300px;
          .sub-header{
            //background: transparentize($primary_1, .5);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 24px;
            .at-font-h5{
              text-transform: none;
              letter-spacing: initial;
            }
            span{
              color: $secundary_1;
              font-size: $font_2x;
            }
          }
          .at-element-list{
            flex-direction: column;
            align-items: center;
            padding: 0 24px;
            float: none;
            li{
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 12px 0;
              .at-link{
                padding: 0;
                font-size: $font_2x;
                color: $primary_1;
              }
              i{
                margin-right: 0;
                margin-left: 12px;
                cursor: pointer;
                &:before{
                  color: $secundary_1;
                }
              }
            }
          }
          .sub-footer{
            padding: 12px 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            .at-link{
              color: $secundary_1;
              padding: 0;
              font-size: $font_2x;
            }
            i{
              margin: 0;
              transform: rotate(180deg);
              margin-left: 12px;
              &:before{
                color: $secundary_1;
              }
            }
          }
        }
      }
      &.help, &.shop{
        margin: 0 12px;
      }
    }
  }
  @include for-tablet{
    padding: 0 $size_1x;
    .navbar-brand{
      .at-logo-image{
        margin: $size_1x;
      }
    }
    .ml-primary-navigation{
      //display: none;
      background: $neutral_1;
      position: fixed;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      box-shadow: $shadow_1;
      z-index: 999;
      transition: .5s all ease;
      white-space: nowrap;
      overflow: hidden;
      .header-navigation-mobile{
        display: flex;
        align-items: center;
      }
      .footer-navigation-mobile{
        display: block;
        li{
          border: none;
        }
      }
      .dropdown{
        text-align: center;
        border-right: .5px solid transparentize($neutral_2, .7);
        &:last-child {
          border-right: none;
        }
        [class^="ic-"] {
          display: block;
          float: none;
          margin: 0;
          &:before{
            font-size: $font_11x;
            color: $primary_1;
          }
        }
        .at-link{
          color: $neutral_2;
          font-size: $font_18x;
          padding: $size_1x;
          display: block;
          line-height: 1.5rem;
          // &:hover, &:active, &:focus{
          //   background: transparentize($primary_1, .9);
          //   font-weight: bold;
          //   font-size: $font_19x;
          //   color: $primary_1;
          // }
          // &:after, &:before{
          //   content: none;
          // }
        }
        .at-card-medium-emphasis {
          top: 0;
          z-index: 10;
          text-align: left;
          padding: 0;
          max-width: 100%;
          .sub-header{
            display: block;
            .back {
              background: transparentize($primary_1, .9);
              padding: $size_1x 0;
              font-weight: bold;
              text-transform: uppercase;
              line-height: 1.2rem;
              [class^="ic-"]{
                float: left;
                margin-top: -7px;
                margin-right: 6px;
              }
            }
          }
          .at-font-em{
            color: transparentize($primary_1, .6);
            font-size: $font_3x;
            padding: $size_1x * 2;
            padding-bottom: $size_1x;
            text-transform: uppercase;
          }
          .at-link{
            display: block;
            font-size: $font_19x;
            padding-left: $size_1x * 3;
            &:nth-child( 2n ){
              border-bottom: .5px solid transparentize($neutral_2, .7);
            }
            &:hover{
              font-weight: normal;
            }
          }
        }
      }
      .at-element-list{
        padding-top: 16px;
        white-space: nowrap;
        li{
          display: block;
          padding: 20px 24px;
          border-bottom: 1px solid transparentize($neutral_2, .8);
          .at-link{
            color: $neutral_2;
            display: block;
            &:hover{
              background: transparent;
            }
            &:focus{
              background: transparent;
            }
          }
          i{
            display: inline-block;
          }
        }
      }
    }
    .navbar-collapse{
      .at-element-list{
        .dropdown{
          &.user{
            display: none;
          }
        }
        .ml-search-basic{
          right: 0;
          .at-input-label{
            padding: 15px 20px;
          }
        }
      }
    }
  }
  @include for-mobile{
    .ml-primary-navigation{
      .at-element-list{
        display: flex;
        overflow: hidden;
        white-space: nowrap;
        flex-direction: column;
        .dropdown{
          border-bottom: .5px solid transparentize($neutral_2, .7);
          &:nth-child( 3n ){
            border-right: none;
          }
        }
      }
    }
    .navbar-collapse{
      .ml-basic-search{
        button{
          padding: 0 $size_1x;
        }
      }
    }
  }
  @include for-mobile-s{
    .ml-basic-search{
      width: 56%;
    }
  }
  @include for-mobile-landscape{
    .ml-primary-navigation{
      .at-element-list{
        white-space: nowrap;
        overflow-x: scroll;
        display: block;
        .dropdown{
          display: inline-block;
          overflow: hidden;
          width: 28%;
          float: none;
          vertical-align: top;
          &:nth-child( 3n ){
            border-right: .5px solid transparentize($neutral_2, .7);
          }
        }
      }
    }
  }
}
