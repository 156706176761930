.ml-general-icon-circular {
  @extend %mlgic;
  .at-image-avatar {
    max-width: 180px;
    max-height: 180px;
    @extend %mlgic-at-avatar-image;
    &, .owl-carousel & { @extend %mlgic-owl-carousel; }
    ~ .at-font-h5 {
      @extend %mlgic-at-font-h4;
    }
  }
}