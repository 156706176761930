.pg-corporate{
  @extend %corporate;
  section{
    margin: 100px 0;
    &.services{
      .ml-card-servicios{
        .at-containershadow-tertiary{
          margin: 0 auto;
        }
      }
    }
    &.circular-slider{
      .ml-general-icon-circular{
        .at-button-primary{
          margin-top: 20px;
        }
      }
    }
  }
}
