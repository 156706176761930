.ml-popup-advertence {
  @extend %fallball-popup;
  .popup-content {
    @extend %content-popup;
    padding-left: $size_1x * 6.83;
    text-align: left;
    &:before{
      content: "\e985";
      font-family: $icons-tigoune;
      position: absolute;
      left: 33px;
      top: 33px;
      font-size: $font_6x;
      color: $product_1;
    }
    .at-font-p{
      padding: $size_1x 0;
    }
    .buttons-right{
      @extend %buttons;
    }
    .at-button-secondary{
      margin: 0 12px;
    }
    .at-font-h4{
      font-size: $font_12x;
    }
  }
  .ic-cerrar {
    @extend %ic-iconos-popup;
  }
}
