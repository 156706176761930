.ml-popup-form {
  @extend %fallball-popup;
  .popup-content {
    @extend %content-popup;
    text-align: left;
    .at-font-h4{
      padding: $size_1x 0;
      font-size: $font_12x;
    }
    .at-input-label, .at-input-textfield{
      width: 100%;
    }
    .buttons-right{
      @extend %buttons;
      button{
        margin: 0 $size_1x;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .at-button-secondary, .at-button-primary{
      margin: 0 2px;
    }
  }
  .ic-cerrar {
    @extend %ic-iconos-popup;
  }
}
@include for-mobile{
  .ml-popup-form{
    .popup-content{
      .buttons-right{
        text-align: center;
      }
    }
  }
}
