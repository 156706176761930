.at-button-icon{
  @extend %button;
  background-color: $secundary_1;
  color: $neutral_1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0 18px;
  width: 100%;
  &:hover{
    color: $neutral_1;
    background-color:$secundary_3;
  }
  &:disabled{
    background-color: transparentize($secundary_1, .7);
    color: $neutral_1;
  }
  &:focus{
    background-color:$secundary_2;
    color: $neutral_1;
  }
  &[class*='ic-']:before{
    color: $neutral_1;
  }
}
