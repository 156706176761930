.ml-login {
  .at-containershadow-primary{
    @extend %content;
    .at-button-icon {
      max-width: 70%;
      margin: 0 auto;
    }
    .at-font-p {
      text-align: center;
      margin: 30px 0;
      color: $neutral_2;
    }
    .at-button-login {
      float: right;
    }
  }
}
